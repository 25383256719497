import React, {useEffect, useState} from 'react';
import {bindActionCreators, compose} from 'redux';
import * as productsActions from '../../../actions/productsActionCreators';
import {connect} from 'react-redux';
import FiltersIcon from '../../Icons/Filters';
import CloseIcon from '../../Icons/Close';
import {Drawer} from 'antd';
import './style.scss';
import ProductsFilters from '../index';
import {applyProductsFilters, resetProductsFilters} from '../../../actions/productsActionCreators';
import Button from '../../Button';
import {productsCountFrom} from '../../../tools/formatters';
const MobileProductsFilters = ({productsState, productsActions, ...props}) => {
  const
    {possibleProductsCount} = productsState,
    [open, setOpen] = useState(false),
    filtersOn = !!Object.keys(productsState.filters).length,
    handleClose = () => {
      setOpen(false);
    },
    handleReset = () => {
      productsActions.resetProductsFilters();
      setOpen(false);
    },
    handleApplyFilters = () => {
      if (filtersOn && possibleProductsCount > 0) {
        productsActions.applyProductsFilters();
      }
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
      setOpen(false);
    },
    countStr = possibleProductsCount > 0 ? `${possibleProductsCount} ${productsCountFrom(possibleProductsCount)}` : ''

  return (
    <>
      <div className='mobile-filters' onClick={() => setOpen(true)}>
        Фильтры <FiltersIcon />
      </div>
      <Drawer
        className='select-drawer mobile-filters-drawer'
        getContainer='body'
        height='100%'
        placement='bottom'
        onClose={handleClose}
        title={
          <div className='mobile-filters__header'>
            <span>Фильтры</span>
            {filtersOn ? <button onClick={handleReset}>Сбросить всё</button> : null}
          </div>
        }
        footer={
          <Button primary onClick={handleApplyFilters} className='w-full'>{filtersOn && possibleProductsCount > 0 ? `Показать ${countStr}` : 'Закрыть'}</Button>
        }
        closeIcon={<CloseIcon />}
        open={open}
      >
        <ProductsFilters />
      </Drawer>
    </>
  )
};

const mapDispatchToProps = (dispatch) => {
    return {
      productsActions: bindActionCreators(productsActions, dispatch)
    };
  },

  mapStateToProps = ({productsState, railsContext, applicationState}) => {
    return {
      applicationState,
      productsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(MobileProductsFilters);
