import React, {useState, useRef} from 'react';
import './style.scss';
import './mobile.scss';
import {compose} from 'redux';
import {connect} from 'react-redux';
const LargeSwitcher = ({options, value, ...props}) => {
  const
    refs = useRef({}),
    [currentVal, setCurrentVal] = useState(value),
    handleSelect = (val) => (e) => {
      setCurrentVal(val);
      return props.onChange && props.onChange(val);
    },
    optionStyle = props.applicationState.is_mobile ? {maxWidth: `${100 / options.length}%`} : {},
    markerStyles = () => {
      const el = refs.current[currentVal];

      if (!el) return;

      return {
        left: el.offsetLeft,
        width: el.clientWidth + 1
      };
    };
  return (
    <div className='large-switcher'>
      <div className='large-switcher__wrapper'>
        <span className='large-switcher__marker' style={markerStyles()}></span>
        <div className='large-switcher__options'>
          {
            options.map((option, idx) => {
              return (
                <div
                  key={idx}
                  ref={(el) => (refs.current[option.value] = el)}
                  style={optionStyle}
                  className={`large-switcher__option ${currentVal === option.value && markerStyles() ? 'active' : ''}`}
                  onClick={handleSelect(option.value)}
                >
                  <span>{option.label}</span>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({applicationState, cartState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps,
  );

export default compose(withConnect)(LargeSwitcher);
