import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import ROUTES from '../../constants/routes';

const PrivateRoute = ({component, exact = false, path, authenticated}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={props => {
        return (
          authenticated ?
            (
              React.createElement(component, props)
            )
            : (
              <Redirect to={{
                pathname: ROUTES.SESSION_LOGIN,
                state: {from: props.location}
              }}
              />
            )
        );
      }}
    />
  );
};

export default PrivateRoute;
