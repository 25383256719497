import React from 'react';
import {Checkbox} from 'antd';
import './style.scss';

type Props = React.ComponentProps<typeof Checkbox>

const CustomCheckbox = (props: Props): JSX.Element => {
  return (
    <Checkbox
      {...props}
      className={'st-check ' + (props.className || '')}
    >
      {props.children}
    </Checkbox>
  );
};

export default CustomCheckbox;
