import React from 'react';

const PlusMinusIcon = ({mode}) => {
  return mode === '+' ? (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6538 8.84615C11.3777 8.84615 11.1538 8.6223 11.1538 8.34615V5.5C11.1538 5.22386 10.93 5 10.6538 5H9.34615C9.07001 5 8.84615 5.22386 8.84615 5.5L8.84615 8.34615C8.84615 8.6223 8.6223 8.84615 8.34615 8.84615H5.5C5.22386 8.84615 5 9.07001 5 9.34615V10.6538C5 10.93 5.22386 11.1538 5.5 11.1538H8.34615C8.6223 11.1538 8.84615 11.3777 8.84615 11.6538L8.84615 14.5C8.84615 14.7761 9.07001 15 9.34615 15L10.6538 15C10.93 15 11.1538 14.7761 11.1538 14.5L11.1538 11.6538C11.1538 11.3777 11.3777 11.1538 11.6538 11.1538L14.5 11.1538C14.7761 11.1538 15 10.93 15 10.6538L15 9.34615C15 9.07001 14.7761 8.84615 14.5 8.84615L11.6538 8.84615Z" fill="black"/>
      </svg>
  ) : (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 9.34619C6 9.07005 6.22386 8.84619 6.5 8.84619L17.5 8.84619C17.7761 8.84619 18 9.07005 18 9.34619V10.6539C18 10.93 17.7761 11.1539 17.5 11.1539L6.5 11.1539C6.22386 11.1539 6 10.93 6 10.6539V9.34619Z" fill="black" />
    </svg>
  );
}

export default PlusMinusIcon;
