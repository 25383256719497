import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as productsActions from '../../actions/productsActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useUnmount} from 'react-use';
import {extractProductId} from '../../routes/routes';
import ProductShow from '../../components/ProductShow';
import SeoTags from '../../routes/SeoTags';
import {textKeysReplacement} from '../../tools/helpers';

const ProductPage = (props) => {
  const
    [currentPath, setCurrentPath] = useState(null),
    productPath = props.match.params[0],
    currentProduct = props.productsState.currentProduct;

  useEffect(() => {
    if (productPath === currentPath) return;
    props.productsActions.fetchProduct(extractProductId(productPath));
    setCurrentPath(productPath);
  }, [productPath, props.productsActions.fetchProduct]);

  useUnmount(() => {
    props.productsActions.resetCurrentProduct();
  });

  return (
    <Layout
      className='product-page'
      breadcrumbs={[
        ...(currentProduct?.full_rubric ? currentProduct.full_rubric.ancestors.map(rubric => ({
          label: rubric.title,
          path: constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, rubric.joined_path)
        })) : []),
        {
          label: currentProduct?.rubric.title,
          path: constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, currentProduct?.rubric.joined_path)
        }
      ]}
    >
      {
        currentProduct ?
          <>
            <SeoTags
              controller_action='products#show'
              seo_context={{
                title: currentProduct.seo_title || currentProduct.name,
                price: currentProduct.new_price ? currentProduct.new_price_in_format : currentProduct.price_in_format,
                description: currentProduct.seo_description || currentProduct?.description?.substring(0, 200),
                keywords: currentProduct.seo_keywords || '',
              }}
            />

            <ProductShow product={currentProduct} />
          </>
          : null
      }
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      productsActions: bindActionCreators(productsActions, dispatch)
    };
  },

  mapStateToProps = ({productsState, railsContext, applicationState}) => {
    return {
      applicationState,
      productsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(ProductPage));
