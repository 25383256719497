import React from 'react';

const AccountIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3 13.2C15.1746 13.2 17.7904 14.0324 19.7132 15.2058C20.6743 15.7922 21.4945 16.4836 22.0872 17.2338C22.6704 17.972 23.1 18.8556 23.1 19.8C23.1 20.8139 22.6067 21.6133 21.8968 22.1831C21.2242 22.7231 20.3378 23.0803 19.3954 23.3299C17.5024 23.8312 14.9748 24 12.3 24C9.62521 24 7.09759 23.8312 5.20459 23.3299C4.26218 23.0803 3.37589 22.7231 2.70319 22.1831C1.99337 21.6133 1.5 20.8139 1.5 19.8C1.5 18.8556 1.92966 17.972 2.51276 17.2338C3.10546 16.4836 3.92572 15.7922 4.88672 15.2058C6.8096 14.0324 9.42544 13.2 12.3 13.2ZM12.3 0C15.6137 0 18.3 2.6863 18.3 6C18.3 9.3137 15.6137 12 12.3 12C8.9863 12 6.3 9.3137 6.3 6C6.3 2.6863 8.9863 0 12.3 0Z" fill="white"/>
    </svg>
  );
}

export default AccountIcon;
