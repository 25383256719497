import ReactOnRails from 'react-on-rails';

import AppShop from '../appShop/startup/ClientApp';
import shopStore from '../appShop/store/rootStore';

ReactOnRails.register({
  AppShop,
});

ReactOnRails.registerStore({
  shopStore
});
