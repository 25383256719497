import React, {useEffect, useState} from 'react';
import QtyInput from '../QtyInput';
import './style.scss';
import './mobile.scss';
import {roundUnitRate} from '../../../tools/formatters';
import PlusMinusIcon from '../../Icons/PlusMinus';

let updateTimeout: ReturnType<typeof setTimeout>;

const QtyChange = (props) => {
  const
    {handleChange, record} = props,
    isAvailable = props.type === 'available',
    offerQty = isAvailable ? record.available_qty : record.unavailable_qty,
    rate = roundUnitRate(record.orderingRate) || 1,
    minQty = rate,
    maxQty = isAvailable ? (record.qty_max || Infinity) : Infinity,
    [qty, setQty] = useState(offerQty),
    maxReached = maxQty && qty + rate > maxQty,
    minReached = minQty && qty - rate < minQty;

  const
    updateQty = (qty: number) => {
      const
        validQty = qty || 1;

      if (typeof qty === 'number' && qty < minQty) {
        handleChange(minQty / rate);
        setQty(minQty);
        return;
      }

      if (maxQty && qty > maxQty) {
        handleChange(maxQty / rate);
        setQty(maxQty);
        return;
      }

      handleChange(validQty / rate);
      setQty(validQty);
    },

    handleQtyChange = (value: number) => {
      updateQty(Number(value));
    },

    handleAddQty = (amount) => () => {
      clearTimeout(updateTimeout);
      const value = qty + amount;
      setQty(value);
      updateTimeout = setTimeout(() => {
        updateQty(value);
      }, 500);
    };

  useEffect(() => {
    if (offerQty !== qty) {
      setQty(offerQty);
    }
  }, [offerQty]);

  return (
    <div className='qty-change__wrapper'>
      <div className='qty-change'>
        <button
          disabled={minReached}
          onClick={handleAddQty(rate * -1)}
        >
          <PlusMinusIcon mode='-' />
        </button>
        <QtyInput
          disabled={false}
          quantity={roundUnitRate(qty)}
          min={minQty}
          max={isAvailable ? maxQty : Infinity}
          onChange={handleQtyChange}
        />
        <button
          disabled={maxReached}
          onClick={handleAddQty(rate)}
        >
          <PlusMinusIcon mode='+' />
        </button>
      </div>
      {
        maxQty && isAvailable ?
          <div className='flex justify-center mt-2 qty-change__max' style={{fontSize: 13, color: '#8E9AA1'}}>Макс: {maxQty}</div>
          : null
      }
    </div>
  );
};

export default QtyChange;
