import React from 'react';
import {Switch} from 'antd';
import './style.scss';

type Props = React.ComponentProps<typeof Switch>

const CustomSwitch = (props: Props): JSX.Element => {
  return (
    <Switch
      {...props}
      className={'st-switch ' + (props.className || '')}
    />
  );
};

export default CustomSwitch;
