import React from 'react';

const CheckedRoundIcon = ({width = 40, height = 40, ...props}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2138_16438)">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM31.0811 15.3061C31.8979 14.4363 31.8549 13.069 30.9851 12.2522C30.1153 11.4354 28.748 11.4784 27.9312 12.3482L17.2358 23.7378L11.9712 18.8042C11.1005 17.9883 9.73329 18.0327 8.91738 18.9033C8.10147 19.774 8.14585 21.1412 9.0165 21.9571L15.856 28.3666C16.726 29.1819 18.0921 29.1383 18.9083 28.2691L31.0811 15.3061Z" fill="#219653"/>
      </g>
      <defs>
        <clipPath id="clip0_2138_16438">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CheckedRoundIcon;
