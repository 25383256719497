import React from 'react';
import {format} from 'date-fns'
export const productPackSize = (storeProduct): string => {
  const {product} = storeProduct;

  if (!product.measure_unit) { return null; }
  return [product.unit_size, product.measure_unit.title].join(' ');
};

export const productPrices = (storeProduct) => {
  const
    {product} = storeProduct,
    hasPromo = storeProduct.promo,
    price = Number(Number(hasPromo ? storeProduct.promo?.price : storeProduct.price).toFixed(2)),
    oldPrice = Number(Number(hasPromo ? storeProduct.price : 0).toFixed(2)),
    pricePerUnit = (price / product.unit_size) || 0,
    oldPricePerUnit = (oldPrice / product.unit_size) || 0;

  return {
    price,
    oldPrice,
    pricePerUnit,
    oldPricePerUnit
  };
};

export const priceFormat = (price: number): string => {
  const parts = Number(price || 0).toFixed(2).split('.');
  const str = Number(parts[0]).toLocaleString().replace(',', ' ');
  return Number(parts[1]) > 0 ? `${str}.${parts[1]}` : str;
};

export const priceFormatInt = (price: number): string => {
  const parts = Number(price || 0).toFixed(2).split('.');
  return Number(parts[0]).toLocaleString().replace(',', ' ');
};

export const isFloatPrice = (price) => {
  const parts = Number(price || 0).toFixed(2).split('.');
  return Number(parts[1]) > 0;
}

export const countForm = (number, titles) => {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
  }
  return titles[1];
}

export const productsCountFrom = (number) => countForm(number, ['товар', 'товара', 'товаров']);
export const rublesCountFrom = (number) => countForm(number, ['рубль', 'рубля', 'рублей']);
export const categoriesCountFrom = (number) => countForm(number, ['категория', 'категории', 'категорий']);
export const reviewsCountFrom = (number) => countForm(number, ['отзыв', 'отзыва', 'отзывов']);

export const priceWithCurrency = (number) => (<span className='price-with-currency'>{priceFormat(number)}<span>₽</span></span>);
export const unitWithUpperIndex = (unitName) => {
  const
    units = unitName.replace(/\d/g, '').replace('.', ''),
    numbers = unitName.replace(/[^0-9]/g, '');
  return <>{units}<span className='upper-index'>{numbers}</span></>;
}

export const roundUnitRate = (rate) => +(parseFloat(rate).toFixed(2))

export const dateFormat = (date) => format(new Date(date), 'dd.MM.yyyy');
