import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as articlesActions from '../actions/articlesActionCreators';
import {extractPaginationParams} from '../tools/request';

const {API, ACTIONS} = constants;

export const fetchStocksRequest = (params = {}) => RequestTools.ajax({url: API.STOCKS_PATH, params});
export const fetchStocksProductsRequest = (params = {}) => RequestTools.ajax({url: API.RUBRIC_STOCK_OFFERS_PATH, params});
export const fetchStockRequest = (id) => RequestTools.ajax({url: API.STOCK_PATH(id)});
export const fetchArticlesRequest = (params = {}) => RequestTools.ajax({url: API.ARTICLES_PATH, params});
export const fetchArticleRequest = (id) => RequestTools.ajax({url: API.ARTICLE_PATH(id)});
export const fetchEventsRequest = (params = {}) => RequestTools.ajax({url: API.EVENTS_PATH, params});
export const fetchEventRequest = (id) => RequestTools.ajax({url: API.EVENT_PATH(id)});
export function * fetchStocks() {
  try {
    const response = yield call(fetchStocksRequest);

    yield put(articlesActions.fetchStocksSuccess(response.data));
  } catch (e) {
    yield put(articlesActions.fetchStocksFailed());
  }
}

export function * fetchStock(action) {
  try {
    const response = yield call(fetchStockRequest, action.payload.id);

    yield put(articlesActions.fetchStockSuccess(response.data));
    yield put(articlesActions.fetchStockProducts());
  } catch (e) {
    yield put(articlesActions.fetchStockFailed());
  }
}

export function * fetchArticles(action) {
  const
    {articlesState: {paginator: {per_page}}} = yield select(),
    page = action.payload.options?.page || 1;
  try {
    const
      response = yield call(fetchArticlesRequest, {page, per_page}),
      paginator = {
        page,
        ...extractPaginationParams(response)
      };

    yield put(articlesActions.fetchArticlesSuccess(response.data, paginator));
  } catch (e) {
    yield put(articlesActions.fetchArticlesFailed());
  }
}

export function * fetchArticle(action) {
  try {
    const response = yield call(fetchArticleRequest, action.payload.id);

    yield put(articlesActions.fetchArticleSuccess(response.data));
  } catch (e) {
    yield put(articlesActions.fetchArticleFailed());
  }
}

export function * fetchEvents(action) {
  const
    {articlesState: {paginator: {per_page}}} = yield select(),
    page = action.payload.options?.page || 1;
  try {
    const
      response = yield call(fetchEventsRequest, {page, per_page}),
      paginator = {
        page,
        ...extractPaginationParams(response)
      };

    yield put(articlesActions.fetchEventsSuccess(response.data, paginator));
  } catch (e) {
    yield put(articlesActions.fetchEventsFailed());
  }
}

export function * fetchEvent(action) {
  try {
    const response = yield call(fetchEventRequest, action.payload.id);

    yield put(articlesActions.fetchEventSuccess(response.data));
  } catch (e) {
    yield put(articlesActions.fetchEventFailed());
  }
}

export function * fetchStocksProducts(action) {
  try {
    const response = yield call(fetchStocksProductsRequest, {limit: 10});

    yield put(articlesActions.fetchStocksProductsSuccess(response.data));
  } catch (e) {
    yield put(articlesActions.fetchStocksProductsFailed());
  }
}
export function * fetchStockProducts(action) {
  const
    {articlesState: {currentStock}} = yield select();

  try {
    const response = yield call(fetchStocksProductsRequest, {limit: 10, stock_id: currentStock.id});

    yield put(articlesActions.fetchStockProductsSuccess(response.data));
  } catch (e) {
    yield put(articlesActions.fetchStockProductsFailed());
  }
}

function * articlesSaga() {
  yield takeEvery(ACTIONS.FETCH_ARTICLES, fetchArticles);
  yield takeEvery(ACTIONS.FETCH_ARTICLE, fetchArticle);
  yield takeEvery(ACTIONS.FETCH_STOCKS, fetchStocks);
  yield takeEvery(ACTIONS.FETCH_STOCK, fetchStock);
  yield takeEvery(ACTIONS.FETCH_EVENTS, fetchEvents);
  yield takeEvery(ACTIONS.FETCH_EVENT, fetchEvent);
  yield takeEvery(ACTIONS.FETCH_STOCKS_PRODUCTS, fetchStocksProducts);
  yield takeEvery(ACTIONS.FETCH_STOCK_PRODUCTS, fetchStockProducts);
}

export default articlesSaga;
