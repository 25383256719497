import React, {useState} from 'react';
import {bindActionCreators, compose} from 'redux';
import * as productsActions from '../../actions/productsActionCreators';
import {connect} from 'react-redux';
import {Dropdown} from 'antd';
import './style.scss';

const ProductsSort = (props) => {
  const
    currentSort = props.productsState.sort,
    handleSortChange = (name, dest) => () => {
      props.productsActions.changeSort(name, dest);
    },
    sorts = {
      price_desc: {
        title: 'Сначала дороже'
      },
      price_asc: {
        title: 'Сначала дешевые'
      },
      rank_asc: {
        title: 'Сначала популярные',
      },
    },
    items = Object.entries(sorts).map(([key, values], idx) => {
      const [name, dest] = key.split('_');
      return {
        label: <span onClick={handleSortChange(name, dest)}>{values.title}</span>,
        key: idx
      }
    });

  return (
    <div className='products-sort'>
      <Dropdown menu={{items}} trigger={['click']}>
        <div className='products-sort__title'>
          <span className='sort-icon__wrapper'>
            <span className={`sort-icon ${'sort-icon_' + currentSort.dest}`} />
          </span>
          {sorts[[currentSort.name, currentSort.dest].join('_')]?.title}
        </div>
      </Dropdown>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
    return {
      productsActions: bindActionCreators(productsActions, dispatch)
    };
  },

  mapStateToProps = ({productsState, railsContext, applicationState}) => {
    return {
      applicationState,
      productsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(ProductsSort);
