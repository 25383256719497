import React, {useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import BurgerIcon from './burger.svg';
import {Drawer} from 'antd';
import './style.scss';
import MobileSelectCity from '../SelectCity';
import CloseIcon from '../../../Icons/Close';
import MobileMenuRubricsList from '../RubricsList';
import MobileHeaderLinks from '../Links';
import MobileSearch from '../Search';
const BurgerMenu = (props) => {
  const
    [children, setChildren] = useState(null),
    [open, setOpen] = useState(false),
    handleChildrenSelected = (rubric) => {
      if (rubric.children.length) {
        setChildren(rubric.children);
      }
    },
    handleClose = () => {
      setChildren(null);
      setOpen(false);
    };

  return (
    <>
      <button className='burger-menu__toggle' onClick={() => setOpen(true)}>
        <img src={BurgerIcon} alt='menu' />
      </button>
      <Drawer
        className='select-drawer'
        getContainer='body'
        height='100%'
        placement='bottom'
        onClose={handleClose}
        title={<MobileSelectCity />}
        closeIcon={<CloseIcon />}
        open={open}
      >
        <div className='burger-menu-drawer__body'>
          <MobileSearch onClose={handleClose} />
          <MobileMenuRubricsList
            rubrics={children || props.rubricsState.collection}
            onLinkClick={handleClose}
            onChildrenSelected={handleChildrenSelected}
          />
          <MobileHeaderLinks />
        </div>
      </Drawer>
    </>
  )
}

const mapStateToProps = ({applicationState, rubricsState}) => {
    return {
      applicationState,
      rubricsState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(BurgerMenu);
