import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import {isLocalUrl} from '../../tools/helpers';

import './style.scss';
import './mobile.scss';

const IconBlocks = ({blocks, ...props}) => {
  return (
    <ul className="blocks-with-icons-list">
      {blocks.map((block) => {
        return (
          <li key={block.id} className="blocks-with-icons-item">
            <div className="icon-">
              <div className="icon-wrapp-">
                {block.image ? <img alt={block.image.alt || block.title} src={block.image.big_image_url} /> : null}
              </div>
            </div>
            <div className="content-">
              {
                block.url ? (isLocalUrl(block.url) ? <Link to={block.url} className="link-" /> : <a className="link-" href={block.url}></a>) : null
              }
              <div className="title-">{block.title}</div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(IconBlocks);
