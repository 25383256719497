import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as brandsActions from '../../actions/brandsActionCreators';
import * as applicationActions from '../../actions/applicationActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useMount, useUnmount} from 'react-use';
import {extractBrandId} from '../../routes/routes';
import BrandShow from '../../components/BrandShow';
import SeoTags from '../../routes/SeoTags';

const BrandPage = (props) => {
  const
    [currentPath, setCurrentPath] = useState(null),
    brandPath = props.match.params.id,
    currentBrand = props.brandsState.currentBrand;

  useEffect(() => {
    if (brandPath === currentPath) return;
    const brandId = extractBrandId(brandPath);
    props.brandsActions.fetchBrand(brandId);
    setCurrentPath(brandPath);
  }, [brandPath, props.brandsActions.fetchBrand]);

  useMount(() => {
    props.applicationActions.fetchHomePageContent();
  });
  useUnmount(() => {
    props.brandsActions.resetCurrentBrand();
    props.brandsActions.resetBrandRubrics();
    props.brandsActions.resetBrandProducts();
  });

  useMount(() => {
    // props.brandsActions.fetchBrand(extractBrandId(brandPath));
  })

  return (
    <Layout
      className='brands-page'
      breadcrumbs={[
        {
          label: 'Бренды',
          path: constants.ROUTES.REGIONAL_BRANDS_PATH(props.applicationState.current_city.slug)
        },
        {
          label: currentBrand?.title
        }
      ]}
    >
      <SeoTags
        controller_action='brands#show'
        seo_context={{
          title: currentBrand?.seo_title || currentBrand?.title,
          description:  currentBrand?.seo_description || currentBrand?.description?.substring(0, 200),
          keywords:  currentBrand?.seo_keywords || currentBrand?.description?.substring(0, 200),
        }}
      />
      <div className='container'>
        <BrandShow />
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      brandsActions: bindActionCreators(brandsActions, dispatch),
      applicationActions: bindActionCreators(applicationActions, dispatch)
    };
  },

  mapStateToProps = ({brandsState, railsContext, applicationState}) => {
    return {
      applicationState,
      brandsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(BrandPage));
