import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as articlesActions from '../../actions/articlesActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useMount, useUnmount} from 'react-use';
import {extractBrandId} from '../../routes/routes';
import ArticleShow from '../../components/Articles/Show';
import SeoTags from '../../routes/SeoTags';
import {textKeysReplacement} from '../../tools/helpers';

const EventPage = (props) => {
  const
    [currentPath, setCurrentPath] = useState(null),
    eventPath = props.match.params.id,
    currentEvent = props.articlesState.currentEvent;

  useEffect(() => {
    if (eventPath === currentPath) return;
    const eventId = extractBrandId(eventPath);
    props.articlesActions.fetchEvent(eventId);
    setCurrentPath(eventPath);
  }, [eventPath, props.articlesActions.fetchEvent]);

  useUnmount(() => {
    props.articlesActions.resetEvent();
  });

  return (
    <Layout
      className='event-page'
      breadcrumbs={[
        {label: 'Новости', path: constants.ROUTES.REGIONAL_EVENTS_PATH(props.applicationState.current_city.slug)},
        {label: currentEvent?.title}
      ]}
    >
      {
        currentEvent ?
          <SeoTags
            controller_action='events#show'
            seo_context={{
              title: textKeysReplacement(currentEvent.seo_title, props.applicationState.default_seo_context) || currentEvent.title,
              description: textKeysReplacement(currentEvent.seo_description, props.applicationState.default_seo_context),
              keywords: textKeysReplacement(currentEvent.seo_keywords, props.applicationState.default_seo_context),
            }}
          />
          : null
      }

      <div className='container'>
        <ArticleShow article={currentEvent} applicationState={props.applicationState} />
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      articlesActions: bindActionCreators(articlesActions, dispatch)
    };
  },

  mapStateToProps = ({articlesState, railsContext, applicationState}) => {
    return {
      applicationState,
      articlesState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(EventPage));
