import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import './style.scss';
import * as cartActions from '../../../actions/cartActionCreators';
import CartPosition from '../Position';

const CartPositions = ({positions, type, ...props}) => {
  return (
    <div className='cart-positions'>
      {
        positions.map(position =>
          <CartPosition
            key={position.id}
            actions={props.cartActions}
            selected={props.itemsForOrder.includes(position.id)}
            onSelectForOrder={props.onSelectForOrder}
            position={position}
            city_slug={props.applicationState.current_city.slug}
            is_mobile={props.applicationState.is_mobile}
            type={type}
          />)
      }
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      cartActions: bindActionCreators(cartActions, dispatch)
    };
  },

  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(CartPositions);
