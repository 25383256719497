import React from 'react';

const CartIcon = ({color = null}) => {
  return color ? (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M7.16588 20.4C8.16992 20.4 8.98385 21.2059 8.98385 22.2C8.98385 23.1941 8.16992 24 7.16588 24C6.16184 24 5.34791 23.1941 5.34791 22.2C5.34791 21.2059 6.16184 20.4 7.16588 20.4ZM19.2857 20.4C20.2897 20.4 21.1036 21.2059 21.1036 22.2C21.1036 23.1941 20.2897 24 19.2857 24C18.2817 24 17.4677 23.1941 17.4677 22.2C17.4677 21.2059 18.2817 20.4 19.2857 20.4ZM1.71198 0C3.80336 0 5.37549 1.53943 5.52414 3.6H22.0754C23.5877 3.6 24.7307 4.95611 24.4602 6.42932L22.477 17.2294C22.2675 18.3706 21.2636 19.2 20.0921 19.2H6.47641C5.20471 19.2 4.14922 18.2269 4.05861 16.971L3.08784 3.5145C3.03438 2.77349 2.39621 2.4 1.71198 2.4C1.04263 2.4 0.5 1.86274 0.5 1.2C0.5 0.537264 1.04263 0 1.71198 0Z" fill={color} />
      </g>
    </svg>
  ) : (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2414_11962)">
        <path d="M7.16588 20.4C8.16992 20.4 8.98385 21.2059 8.98385 22.2C8.98385 23.1941 8.16992 24 7.16588 24C6.16184 24 5.34791 23.1941 5.34791 22.2C5.34791 21.2059 6.16184 20.4 7.16588 20.4ZM19.2857 20.4C20.2897 20.4 21.1036 21.2059 21.1036 22.2C21.1036 23.1941 20.2897 24 19.2857 24C18.2817 24 17.4677 23.1941 17.4677 22.2C17.4677 21.2059 18.2817 20.4 19.2857 20.4ZM1.71198 0C3.80336 0 5.37549 1.53943 5.52414 3.6H22.0754C23.5877 3.6 24.7307 4.95611 24.4602 6.42932L22.477 17.2294C22.2675 18.3706 21.2636 19.2 20.0921 19.2H6.47641C5.20471 19.2 4.14922 18.2269 4.05861 16.971L3.08784 3.5145C3.03438 2.77349 2.39621 2.4 1.71198 2.4C1.04263 2.4 0.5 1.86274 0.5 1.2C0.5 0.537264 1.04263 0 1.71198 0Z" fill="url(#paint0_linear_2414_11962)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_2414_11962" x1="24.5" y1="12" x2="0.5" y2="12" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9900"/>
          <stop offset="1" stopColor="#FF4B55"/>
        </linearGradient>
        <clipPath id="clip0_2414_11962">
          <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CartIcon;
