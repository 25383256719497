import React, {useEffect, useState} from 'react';
import {bindActionCreators, compose} from 'redux';
import * as rubricsActions from '../../../actions/rubricsActionCreators';
import * as productsActions from '../../../actions/productsActionCreators';
import {connect} from 'react-redux';
import ProductsList from '../../../components/ProductsList';
import Paginator from '../../../components/Paginator';
import {useUnmount} from 'react-use';
import ProductsFilters from '../../../components/ProductsFilters';
import ProductsSort from '../../../components/ProductsSort';
import MobileProductsFilters from '../../../components/ProductsFilters/MobileFilters';
import Button from '../../../components/Button';
import {productsCountFrom} from '../../../tools/formatters';
import {textKeysReplacement, urlParamsToObject} from '../../../tools/helpers';
import {updateSort} from '../../../actions/productsActionCreators';
import SeoTags from '../../../routes/SeoTags';

const ProductRubric = ({rubric, ...props}) => {
  const
    {possibleProductsCount} = props.productsState,
    filtersOn = !!Object.keys(props.productsState.filters).length,
    handleApplyFilters = () => {
      props.productsActions.applyProductsFilters();
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    },
    countStr = possibleProductsCount ? `${possibleProductsCount} ${productsCountFrom(possibleProductsCount)}` : '',

    [currentRubricId, setCurrentRubricId] = useState(null),
    handlePaginatorChanged = (page, perPage) => {
      props.productsActions.paginatorChanged(page, perPage);
    },
    {is_mobile} = props.applicationState;

  useUnmount(() => {
    props.productsActions.resetProducts();
  });

  useEffect(() => {
    if (rubric.id === currentRubricId) return;
    const params:any = urlParamsToObject(window.location.search);

    if (params.page && params.per_page) {
      const
        {page, per_page} = params,
        paginator = {page, per_page};
      delete params.page;
      delete params.per_page;
      props.productsActions.updatePaginator(paginator);
    }

    if (params.sort_name && params.sort_dest) {
      const
        {sort_name, sort_dest} = params,
        sort = {name: sort_name, dest: sort_dest};
      delete params.sort_name;
      delete params.sort_dest;
      props.productsActions.updateSort(sort);
    }


    props.productsActions.setProductsFilters(params);
    props.productsActions.fetchProducts(rubric.id);
    props.productsActions.fetchProductsFilterContext(rubric.id);

    setCurrentRubricId(rubric?.id);
  }, [rubric?.id, props.productsActions.fetchProducts]);

  return (
    <div className='two-cols-container'>
      <SeoTags
        controller_action='products#index'
        seo_context={{
          title: textKeysReplacement(rubric.seo_title, props.applicationState.default_seo_context) || rubric.title,
          products_count: rubric.active_products_count || 0,
          description: textKeysReplacement(rubric.seo_description, props.applicationState.default_seo_context) || rubric?.description?.substring(0, 200),
          keywords: textKeysReplacement(rubric.seo_keywords, props.applicationState.default_seo_context),
        }}
      />

      {
        !is_mobile ?
          <div className='two-cols-container__col two-cols-container__col_left'>
            <ProductsFilters />
            {
              filtersOn && possibleProductsCount ?
                <Button primary onClick={handleApplyFilters}>{`Показать ${countStr}`}</Button>
                : null
            }
          </div>
          : null
      }
      <div className='two-cols-container__col two-cols-container__col_right'>
        <>
          <div className='products-sort-and-filters-wrapper'>
            <ProductsSort />
            {
              is_mobile ?
                <MobileProductsFilters />
                : null
            }
          </div>
          <ProductsList products={props.productsState.collection} showSkeleton={!props.productsState.collection_loaded} />
          <Paginator {...props.productsState.paginator} onChange={handlePaginatorChanged}/>
        </>
      </div>
    </div>
  )
}


const
  mapDispatchToProps = (dispatch) => {
    return {
      rubricsActions: bindActionCreators(rubricsActions, dispatch),
      productsActions: bindActionCreators(productsActions, dispatch),
    };
  },
  mapStateToProps = ({rubricsState, railsContext, applicationState, productsState}) => {
    return {
      applicationState,
      rubricsState,
      productsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(ProductRubric);
