import React from 'react';
import ShareIcon from '../../Icons/Share';

import './style.scss';

const ShareWidget = (props) => {
  return (
    <button className='share-button' {...props}>
      <ShareIcon />
      <span className='share-button__title'>Поделиться</span>
    </button>
  );
}

export default ShareWidget;
