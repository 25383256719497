import React from 'react';
import {Link} from 'react-router-dom';
import constants from '../../../constants';
import {connect} from 'react-redux';
import {compose} from 'redux';
import './style.scss';
import './mobile.scss';
import Paginator from '../../Paginator';
import paginator from '../../Paginator';
const ArticlesList = ({collection = [], linkHelper, idKey = 'id', ...props}) => {
  return (
    <>
      <div className='articles-list'>
        {
          collection.map((article) => {
            return (
              <article
                className='articles-list-item'
                key={article.id}
                style={{backgroundImage: `url(${article.icon?.big_image_url})`}}
              >
                <Link to={linkHelper(props.applicationState.current_city.slug, article[idKey])}>
                  <span className='articles-list-item__content'>
                    <span className='articles-list-item__description'>{article.title}</span>
                    <span className='articles-list-item__subtitle'>
                      {article.start_date_str}
                    </span>
                  </span>
                </Link>
              </article>
            );
          })
        }
      </div>
      {
        props.paginator ?
          <Paginator
            per_page={props.paginator.per_page}
            total={props.paginator.total}
            pages={props.paginator.pages}
            page={props.paginator.page}
            onChange={props.paginator.onChange}
          />
          : null
      }
    </>
  );
}

export const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(ArticlesList);
