import React from 'react';

const DownloadIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2735_10526)">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM21.8997 8.56589C21.8997 7.51698 21.0494 6.66667 20.0005 6.66667C18.9515 6.66667 18.1012 7.51698 18.1012 8.56589L18.1012 17.2224L15.917 15.0382C15.1753 14.2965 13.9728 14.2965 13.2311 15.0382C12.4894 15.7799 12.4894 16.9824 13.2311 17.7241L18.7666 23.2596C19.5083 24.0013 20.7108 24.0013 21.4525 23.2596L26.9881 17.7241C27.7298 16.9824 27.7298 15.7799 26.9881 15.0382C26.2464 14.2965 25.0439 14.2965 24.3022 15.0382L21.8997 17.4407V8.56589ZM10.2326 21.938C11.2815 21.938 12.1318 22.7883 12.1318 23.8372C12.1318 26.3846 14.1968 28.4496 16.7442 28.4496H23.2558C25.8032 28.4496 27.8682 26.3846 27.8682 23.8372C27.8682 22.7883 28.7185 21.938 29.7674 21.938C30.8164 21.938 31.6667 22.7883 31.6667 23.8372C31.6667 28.4824 27.901 32.2481 23.2558 32.2481H16.7442C12.099 32.2481 8.33333 28.4824 8.33333 23.8372C8.33333 22.7883 9.18365 21.938 10.2326 21.938Z" fill="url(#paint0_linear_2735_10526)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_2735_10526" x1="40" y1="20" x2="1.49943e-08" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9900"/>
          <stop offset="1" stopColor="#FF4B55"/>
        </linearGradient>
        <clipPath id="clip0_2735_10526">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DownloadIcon;
