import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';

export const fetchSearchSuggestions = (query) => ({
  type: ACTIONS.FETCH_SEARCH_SUGGESTIONS,
  payload: {query},
  [pendingTask]: begin
});
export const fetchSearchSuggestionsSuccess = (suggestions) => ({
  type: ACTIONS.FETCH_SEARCH_SUGGESTIONS_SUCCESS,
  payload: {suggestions},
  [pendingTask]: end
});

export const fetchSearchSuggestionsFailed = () => ({
  type: ACTIONS.FETCH_SEARCH_SUGGESTIONS_FAILED,
  [pendingTask]: end
});

export const resetSearchSuggestions = () => ({
  type: ACTIONS.RESET_SEARCH_SUGGESTIONS
});
