import ACTIONS from '../constants/actions';

export const initialState = {
  subscribeForm: {},
  questionForm: {},
};

const userRequestsReducer = function(state = initialState, action) {
  const actionHandlers = {
    [ACTIONS.CHANGE_SUBSCRIBE_FORM]() {
      return {
        ...state,
        subscribeForm: {...action.payload.changes}
      };
    },
    [ACTIONS.SEND_SUBSCRIBE_FORM]() {
      return {
        ...state,
        subscribeForm: {process: true}
      };
    },
    [ACTIONS.SEND_SUBSCRIBE_FORM_SUCCESS]() {
      return {
        ...state,
        subscribeForm: {done: true}
      };
    },
    [ACTIONS.SEND_SUBSCRIBE_FORM_FAILED]() {
      return {
        ...state,
        subscribeForm: {errors: action.payload.errors}
      };
    },
    [ACTIONS.SEND_QUESTION_FORM]() {
      return {
        ...state,
        questionForm: {process: true}
      };
    },
    [ACTIONS.CHANGE_QUESTION_FORM]() {
      return {
        ...state,
        questionForm: {...action.payload.changes}
      };
    },
    [ACTIONS.SEND_QUESTION_FORM_SUCCESS]() {
      return {
        ...state,
        questionForm: {done: true}
      };
    },
    [ACTIONS.SEND_QUESTION_FORM_FAILED]() {
      return {
        ...state,
        questionForm: {errors: action.payload.errors}
      };
    },
  };

  if (action.type in actionHandlers) {
    return actionHandlers[action.type]();
  }

  return state;
};

export default userRequestsReducer;
