import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';

export const fetchPopularBrands = () => ({
  type: ACTIONS.FETCH_POPULAR_BRANDS,
});
export const fetchPopularBrandsSuccess = (brands) => ({
  type: ACTIONS.FETCH_POPULAR_BRANDS_SUCCESS,
  payload: {brands}
});
export const fetchPopularBrandsFailed = () => ({
  type: ACTIONS.FETCH_POPULAR_BRANDS_FAILED,
});

export const fetchBrands = () => ({
  type: ACTIONS.FETCH_BRANDS,
});
export const fetchBrandsSuccess = (brands) => ({
  type: ACTIONS.FETCH_BRANDS_SUCCESS,
  payload: {brands}
});
export const fetchBrandsFailed = () => ({
  type: ACTIONS.FETCH_BRANDS_FAILED,
});
export const resetBrands = () => ({
  type: ACTIONS.RESET_BRANDS,
});

export const fetchBrand = (id) => ({
  type: ACTIONS.FETCH_BRAND,
  payload: {id}
});
export const fetchBrandSuccess = (brand) => ({
  type: ACTIONS.FETCH_BRAND_SUCCESS,
  payload: {brand}
});
export const fetchBrandFailed = () => ({
  type: ACTIONS.FETCH_BRAND_FAILED,
});
export const resetCurrentBrand = () => ({
  type: ACTIONS.RESET_CURRENT_BRAND,
});

export const fetchBrandRubrics = () => ({
  type: ACTIONS.FETCH_BRAND_RUBRICS,
});

export const fetchBrandRubricsSuccess = (rubrics) => ({
  type: ACTIONS.FETCH_BRAND_RUBRICS_SUCCESS,
  payload: {rubrics}
});

export const fetchBrandRubricsFailed = () => ({
  type: ACTIONS.FETCH_BRAND_RUBRICS_FAILED
});
export const resetBrandRubrics = () => ({
  type: ACTIONS.RESET_BRAND_RUBRICS
});

export const fetchBrandProducts = (type: 'hits' | 'stocks' | 'latests') => ({
  type: ACTIONS.FETCH_BRAND_PRODUCTS,
  payload: {type}
});

export const fetchBrandProductsSuccess = (type, products) => ({
  type: ACTIONS.FETCH_BRAND_PRODUCTS_SUCCESS,
  payload: {type, products}
});

export const fetchBrandProductsFailed = (type) => ({
  type: ACTIONS.FETCH_BRAND_PRODUCTS_FAILED,
  payload: {type}
});
export const resetBrandProducts = (type) => ({
  type: ACTIONS.RESET_BRAND_PRODUCTS,
  payload: {type}
});
