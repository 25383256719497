import React from 'react';
import './style.scss';
import './mobile.scss';
import ChevronIcon from '../../Icons/Chevron';
import {Link} from 'react-router-dom';

const CommonWidget = ({icon, title, value = null, to = null, className='', ...props}) => {
  const renderContent = () => (
    <>
      <span className='order-sidebar-widget__icon'>
        {icon}
      </span>
      <span className='order-sidebar-widget__info text-600 text-gray-80'>
        <span className='order-sidebar-widget__title'>
          {title}
        </span>
        {
          value ?
            <span className='order-sidebar-widget__value text-gray-60'>
              {value}
            </span>
            : null
        }
      </span>
      {
        props.onClick ?  <ChevronIcon direction='right' /> : null
      }
    </>
  );
  return to ? (
      <Link to={to} className={`order-sidebar-widget ${className}`} {...props}>
        {renderContent()}
      </Link>
    ) : (
      <button className={`order-sidebar-widget ${className}`} {...props}>
        {renderContent()}
      </button>
    );
}
export default CommonWidget;
