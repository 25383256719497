import React from 'react';

const QuestionIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2735_54)">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM16.9748 24.7827C16.9748 25.021 17.1679 25.2141 17.4061 25.2141H21.2329C21.4711 25.2141 21.6642 25.021 21.6642 24.7827C21.6724 23.9539 21.7756 23.2562 21.9735 22.6895C22.1797 22.1228 22.4891 21.6238 22.9015 21.1925C23.3222 20.7612 23.8584 20.3468 24.51 19.9493C25.2854 19.4756 25.9577 18.947 26.5268 18.3635C27.096 17.7714 27.5373 17.0948 27.8508 16.3336C28.1725 15.564 28.3333 14.6844 28.3333 13.6949C28.3333 12.2148 27.9745 10.9504 27.2569 9.90168C26.5392 8.85294 25.5494 8.05371 24.2873 7.50396C23.0252 6.94577 21.5735 6.66667 19.9319 6.66667C18.4389 6.66667 17.0737 6.94154 15.8364 7.49128C14.5991 8.03256 13.6051 8.85717 12.8545 9.96511C12.1967 10.9286 11.8113 12.1195 11.6982 13.5376C11.6715 13.8737 11.9444 14.1516 12.2815 14.1516H16.136C16.459 14.1516 16.7181 13.8943 16.772 13.5758C16.8474 13.131 16.9934 12.7435 17.2098 12.4136C17.5151 11.94 17.9069 11.5847 18.3853 11.3479C18.8637 11.1027 19.371 10.98 19.9072 10.98C20.4599 10.98 20.963 11.0984 21.4167 11.3352C21.8787 11.572 22.2457 11.9103 22.5179 12.3501C22.7902 12.7899 22.9263 13.3016 22.9263 13.8852C22.9263 14.4349 22.8066 14.9339 22.5674 15.3822C22.3282 15.822 22.0065 16.2237 21.6023 16.5874C21.1981 16.9511 20.7403 17.302 20.2289 17.6403C19.5443 18.0801 18.9586 18.5707 18.4719 19.112C17.9852 19.6532 17.614 20.3637 17.3583 21.2433C17.1109 22.1228 16.983 23.3027 16.9748 24.7827ZM17.3088 32.4453C17.8945 33.0373 18.5998 33.3333 19.4246 33.3333C19.9526 33.3333 20.4392 33.198 20.8847 32.9274C21.3301 32.6483 21.6889 32.2804 21.9611 31.8237C22.2416 31.3585 22.386 30.8468 22.3942 30.2886C22.386 29.4598 22.0849 28.7493 21.491 28.1573C20.8971 27.5653 20.2083 27.2693 19.4246 27.2693C18.5998 27.2693 17.8945 27.5653 17.3088 28.1573C16.7314 28.7493 16.4468 29.4598 16.4551 30.2886C16.4468 31.1259 16.7314 31.8448 17.3088 32.4453Z" fill="url(#paint0_linear_2735_54)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_2735_54" x1="40" y1="20" x2="1.49943e-08" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9900"/>
          <stop offset="1" stopColor="#FF4B55"/>
        </linearGradient>
        <clipPath id="clip0_2735_54">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default QuestionIcon;
