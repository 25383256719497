import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';
import constants from '../../app/constants';

export const setCurrentRoute = (route: string) => ({
  type: ACTIONS.SET_CURRENT_ROUTE,
  payload: {route}
});

export const setRedirect = (path: string) => ({
  type: ACTIONS.SET_REDIRECT,
  payload: {path}
});

export const redirect = (path: string) => setRedirect(path);

export const resetRedirect = () => ({
  type: ACTIONS.RESET_REDIRECT,
});

export const setFinalLogo = (logo) => ({
  type: ACTIONS.SET_FINAL_LOGO,
  payload: {logo}
});

export const fetchCities = () => ({
  type: ACTIONS.FETCH_CITIES,
  [pendingTask]: begin
});

export const fetchCitiesSuccess = (cities) => ({
  type: ACTIONS.FETCH_CITIES_SUCCESS,
  payload: {cities},
  [pendingTask]: end
});

export const fetchCitiesFailed = () => ({
  type: ACTIONS.FETCH_CITIES_FAILED,
  [pendingTask]: end
});

export const setCurrentCity = (branchId) => ({
  type: ACTIONS.SET_CURREN_CITY,
  payload: {branchId}
});

export const setCurrentCitySuccess = (city) => ({
  type: ACTIONS.SET_CURREN_CITY_SUCCESS,
  payload: {city}
});

export const setCurrentCityFailed = () => ({
  type: ACTIONS.SET_CURREN_CITY_FAILED,
});


export const fetchHomePageContent = () => ({
  type: ACTIONS.FETCH_HOME_PAGE_CONTENT,
  [pendingTask]: begin
});

export const fetchHomePageContentSuccess = (content) => ({
  type: ACTIONS.FETCH_HOME_PAGE_CONTENT_SUCCESS,
  payload: {content},
  [pendingTask]: end
});

export const fetchHomePageContentFailed = () => ({
  type: ACTIONS.FETCH_HOME_PAGE_CONTENT_FAILED,
  [pendingTask]: end
});


