import React, {useState} from 'react';
import HeaderActions from '../Actions';

import {connect} from 'react-redux';
import {compose} from 'redux';
import BurgerMenu from './BurgerMenu';
import HeaderLogo from '../Logo';

const HeaderMobile = ({applicationState, ...props}) => {
  return (
    <>
      <div className='header__top'>
        <div className='header__top-left-col'>
          <BurgerMenu />
          <HeaderLogo />
        </div>
        <div className='header__top-right-col'>
          <HeaderActions />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(HeaderMobile);

