import constants from '../constants';
import {cloneObject} from '../../app/tools/helpers';
import Offer from '../decorators/offer'
export const
  DEFAULT_SORT = {
    name: 'rank',
    dest: 'asc'
  },
  DEFAULT_PAGINATOR = {
    page: 1,
    per_page: 48
  },
  DEFAULT_FILTERS = {
    without_price: true
  }

export const initialState = {
  collection: [],
  collection_loaded: false,
  currentProduct: null,
  analogueProducts: [],
  interestsProducts: [],
  currentProductReviews: [],
  filters: {...DEFAULT_FILTERS},
  filtersContext: {},
  paginator: cloneObject(DEFAULT_PAGINATOR),
  sort: {...DEFAULT_SORT},
  searchQuery: '',
  searchQueryProducts: [],
  possibleProductsCount: null
};

const
  products = (state = cloneObject(initialState), action) => {
    const actionHandlers = {
      [constants.ACTIONS.FETCH_PRODUCTS_SUCCESS]() {
        return {
          ...state,
          collection: action.payload.products.map(p => (new Offer(p))),
          collection_loaded: true,
          possibleProductsCount: null,
          paginator: {
            ...state.paginator,
            ...action.payload.pagination
          }
        };
      },
      // hide current products before load new
      [constants.ACTIONS.FETCH_RUBRIC]() {
        return {
          ...state,
          paginator: cloneObject(DEFAULT_PAGINATOR),
          filters: {...DEFAULT_FILTERS},
          filtersContext: {}
        };
      },
      [constants.ACTIONS.UPDATE_PRODUCTS_PAGINATION]() {
        return {
          ...state,
          paginator: {
            ...state.paginator,
            ...action.payload.pagination
          }
        };
      },
      [constants.ACTIONS.UPDATE_PRODUCTS_SORT]() {
        return {
          ...state,
          sort: {
            ...state.sort,
            ...action.payload.sort
          }
        };
      },
      [constants.ACTIONS.RESET_PRODUCTS]() {
        return {
          ...state,
          collection: [],
          collection_loaded: false,
          // paginator: cloneObject(DEFAULT_PAGINATOR)
        };
      },

      [constants.ACTIONS.SET_PRODUCTS_FILTER]() {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.payload.filterName]: action.payload.filterValue
          },
          paginator: cloneObject(DEFAULT_PAGINATOR),
        };
      },

      [constants.ACTIONS.SET_PRODUCTS_FILTERS]() {
        return {
          ...state,
          filters: {
            ...state.filters,
            ...action.payload.filters
          },
        };
      },

      [constants.ACTIONS.RESET_PRODUCTS_FILTERS]() {
        return {
          ...state,
          filters: {},
          paginator: cloneObject(DEFAULT_PAGINATOR)
        };
      },

      [constants.ACTIONS.FETCH_PRODUCT_SUCCESS]() {
        return {
          ...state,
          currentProduct: new Offer(action.payload.product)
        };
      },

      [constants.ACTIONS.RESET_CURRENT_PRODUCT]() {
        return {
          ...state,
          currentProduct: null
        };
      },

      [constants.ACTIONS.FETCH_ANALOGUE_PRODUCTS_SUCCESS]() {
        return {
          ...state,
          analogueProducts: action.payload.products.map(p => (new Offer(p))),
        };
      },

      [constants.ACTIONS.RESET_ANALOGUE_PRODUCTS]() {
        return {
          ...state,
          analogueProducts: [],
        };
      },

      [constants.ACTIONS.FETCH_INTERESTING_PRODUCTS_SUCCESS]() {
        return {
          ...state,
          interestsProducts: action.payload.products.map(p => (new Offer(p))),
        };
      },

      [constants.ACTIONS.RESET_INTERESTING_PRODUCTS]() {
        return {
          ...state,
          interestsProducts: [],
        };
      },

      [constants.ACTIONS.FETCH_PRODUCT_REVIEWS_SUCCESS]() {
        return {
          ...state,
          currentProductReviews: action.payload.reviews,
        };
      },

      [constants.ACTIONS.RESET_PRODUCT_REVIEWS]() {
        return {
          ...state,
          currentProductReviews: [],
        };
      },

      [constants.ACTIONS.SET_PRODUCTS_PAGINATION]() {
        return {
          ...state,
          paginator: {
            ...state.paginator,
            ...action.payload.pagination
          }
        };
      },

      [constants.ACTIONS.RESET_PRODUCTS_PAGINATION]() {
        return {
          ...state,
          paginator: cloneObject(DEFAULT_PAGINATOR)
        };
      },

      [constants.ACTIONS.FETCH_PRODUCTS_FILTERS_CONTEXT_SUCCESS]() {
        return {
          ...state,
          filtersContext: action.payload.filters
        };
      },

      [constants.ACTIONS.RESET_PRODUCTS_FILTERS_CONTEXT]() {
        return {
          ...state,
          filtersContext: {}
        };
      },

      [constants.ACTIONS.CHANGE_PRODUCTS_SORT]() {
        return {
          ...state,
          sort: {
            name: action.payload.name,
            dest: action.payload.dest,
          }
        };
      },

      [constants.ACTIONS.RESET_PRODUCTS_SORT]() {
        return {
          ...state,
          sort: DEFAULT_SORT
        };
      },

      [constants.ACTIONS.CHANGE_PRODUCTS_SEARCH_QUERY]() {
        return {
          ...state,
          searchQuery: action.payload.query
        };
      },

      [constants.ACTIONS.RESET_PRODUCTS_SEARCH_QUERY]() {
        return {
          ...state,
          searchQuery: '',
          searchQueryProducts: []
        };
      },

      [constants.ACTIONS.FETCH_SEARCH_QUERY_PRODUCTS_SUCCESS]() {
        return {
          ...state,
          searchQueryProducts: action.payload.products
        };
      },

      [constants.ACTIONS.RESET_SEARCH_QUERY_PRODUCTS]() {
        return {
          ...state,
          searchQueryProducts: [],
          searchQuery: '',
        };
      },
      [constants.ACTIONS.FETCH_PRODUCTS_COUNT_SUCCESS]() {
        return {
          ...state,
          possibleProductsCount: action.payload.total
        };
      },
      [constants.ACTIONS.FETCH_PRODUCT_BRANCH_STATS_SUCCESS]() {
        const offer = state.currentProduct;
        offer.branchStats = action.payload.stats.map(p => (new Offer(p)));
        return {
          ...state,
          currentProduct: offer
        };
      },
    };

    if (action.type in actionHandlers) {
      return actionHandlers[action.type]();
    }

    return state;
  };

export default products;
