import React, {useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import './style.scss';
import ImageBox from '../ImgBox';
import _ from 'lodash';
import {categoriesCountFrom} from '../../tools/formatters';

const MobileChildrenRubricList = ({rubrics, ...props}) => {
  const categoriesLimit = 6;

  if (!rubrics?.length) {
    return null;
  }

  const [expand, setExpand] = useState(categoriesLimit);

  const
    beExpanded = rubrics.length - categoriesLimit,
    canBeExpanded = beExpanded > 0,
    isExpanded = expand === rubrics.length;

  const handleExpand = () => {
    setExpand(rubrics.length);
  }

  const handleCollapse = () => {
    setExpand(categoriesLimit);
  }

  return (
    <>
      <ul className='children-rubrics-list'>
        {
          _.take(rubrics, expand).map((item) => (
            <li className='children-rubrics-list__item' key={item.id}>
              <Link to={constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, item.joined_path)}>
              <span className='children-rubrics-list__item-image'>
                {
                  item.image ? <ImageBox width={48} height={48} url={item.image.small} alt={item.image.alt || item.title} /> : null
                }
              </span>
                <span className='children-rubrics-list__item-title'>
                {item.title}
              </span>

                <span className='children-rubrics-list__item-count'>
                {item.total_products_count || 0}
              </span>
              </Link>
            </li>
          ))
        }
        {
          canBeExpanded ?
            <li className='children-rubrics-list__item' key='all' onClick={isExpanded ? handleCollapse : handleExpand}>
              <button>
                <span className='children-rubrics-list__item-image'>
                  <ImageBox width={48} height={48}>...</ImageBox>
                </span>
                {
                  isExpanded ?
                    <span className='children-rubrics-list__item-title'>
                      Скрыть {expand - categoriesLimit} {categoriesCountFrom(expand - categoriesLimit)}
                    </span>
                    :
                    <span className='children-rubrics-list__item-title'>
                      Ещё {beExpanded} {categoriesCountFrom(beExpanded)}
                    </span>
                }
              </button>
            </li>
            : null
        }

      </ul>
    </>
  );
};
const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(MobileChildrenRubricList);
