import React from 'react';
import Search from '../Search';
import SelectCity from '../../SelectCity';
import HeaderContacts from '../Contacts';
import HeaderActions from '../Actions';

import {connect} from 'react-redux';
import {compose} from 'redux';
import HeaderLinks from '../Links';
import HeaderLogo from '../Logo';

const HeaderDesktop = ({applicationState, ...props}) => {
  return (
    <>
      <div className='header__top'>
        <div className='header__top-left-col'>
          <SelectCity />
          <HeaderContacts />
        </div>
        <div className='header__top-right-col'>
          <HeaderLinks />
        </div>
      </div>
      <div className='header__bottom'>
        <div className='header__col header__col_col1'>
          <HeaderLogo />
        </div>
        <div className='header__col header__col_col2'>
          <Search />
        </div>
        <div className='header__col header__col_col3'>
          <HeaderActions />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(HeaderDesktop);

