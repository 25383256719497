import React from 'react';

const SelectCityIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 5.36988C11 2.40417 8.7614 0 6 0C3.23857 0 1 2.40417 1 5.36988C1 7.20845 1.93302 8.70379 2.89985 9.76538C3.30982 10.2155 3.73951 10.603 4.13531 10.9224C4.53111 11.2418 5.0844 11.6264 5.43633 11.8399C5.78827 12.0534 6.21173 12.0534 6.56367 11.8399C6.56367 11.8399 7.46889 11.2418 7.86468 10.9224C8.26048 10.603 8.69017 10.2155 9.10013 9.76538C10.067 8.70379 11 7.20845 11 5.36988ZM6 7C7.10457 7 8 6.10457 8 5C8 3.89543 7.10457 3 6 3C4.89543 3 4 3.89543 4 5C4 6.10457 4.89543 7 6 7Z" fill="white"/>
    </svg>
  );
}

export default SelectCityIcon;
