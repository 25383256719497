import React from 'react';

const FiltersIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.6665 11.8081C5.6665 11.7233 5.72034 11.6486 5.79831 11.6153C6.60267 11.2723 7.1665 10.4744 7.1665 9.5447C7.1665 8.61503 6.60267 7.81705 5.79831 7.47406C5.72034 7.44082 5.6665 7.36605 5.6665 7.28129L5.6665 3.225C5.6665 2.89363 5.39787 2.625 5.0665 2.625L4.7665 2.625C4.43513 2.625 4.1665 2.89363 4.1665 3.225L4.1665 7.28129C4.1665 7.36605 4.11267 7.44082 4.0347 7.47406C3.23033 7.81705 2.6665 8.61503 2.6665 9.5447C2.6665 10.4744 3.23033 11.2723 4.0347 11.6153C4.11267 11.6486 4.1665 11.7233 4.1665 11.8081L4.1665 12.775C4.1665 13.1064 4.43513 13.375 4.7665 13.375L5.0665 13.375C5.39787 13.375 5.6665 13.1064 5.6665 12.775L5.6665 11.8081ZM4.9165 8.5447C5.46879 8.5447 5.9165 8.99241 5.9165 9.5447C5.9165 10.097 5.46879 10.5447 4.9165 10.5447C4.36422 10.5447 3.9165 10.097 3.9165 9.5447C3.9165 8.99241 4.36422 8.5447 4.9165 8.5447Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0165 13.375C10.6851 13.375 10.4165 13.1064 10.4165 12.775L10.4165 9.18988C10.4165 9.10512 10.3627 9.03036 10.2847 8.99711C9.48034 8.65412 8.9165 7.85614 8.9165 6.92648C8.9165 5.99681 9.48033 5.19883 10.2847 4.85584C10.3627 4.8226 10.4165 4.74783 10.4165 4.66307L10.4165 3.225C10.4165 2.89363 10.6851 2.625 11.0165 2.625L11.4415 2.625C11.7729 2.625 12.0415 2.89363 12.0415 3.225L12.0415 4.72034C12.0415 4.80072 12.09 4.87256 12.1621 4.90817C12.9053 5.2755 13.4165 6.04129 13.4165 6.92648C13.4165 7.81166 12.9053 8.57745 12.1621 8.94478C12.09 8.9804 12.0415 9.05224 12.0415 9.13262L12.0415 12.775C12.0415 13.1064 11.7729 13.375 11.4415 13.375L11.0165 13.375ZM12.1665 6.92648C12.1665 6.37419 11.7188 5.92648 11.1665 5.92648C10.6142 5.92648 10.1665 6.37419 10.1665 6.92648C10.1665 7.47876 10.6142 7.92648 11.1665 7.92648C11.7188 7.92648 12.1665 7.47876 12.1665 6.92648Z" fill="black"/>
    </svg>
  );
}

export default FiltersIcon;
