import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as appActions from '../../actions/applicationActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';

const NotFoundPage = (props) => {

  return (
    <Layout
      className='stock-page'
      breadcrumbs={[
        {label: '404'},
      ]}
    >
      <div className='container'>

      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      appActions: bindActionCreators(appActions, dispatch)
    };
  },

  mapStateToProps = ({railsContext, applicationState}) => {
    return {
      applicationState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(NotFoundPage));
