import React from 'react';
import './style.scss';

const InputWithLabel = ({value = '', onChange, label, disabled=false}) => {
  const handleWrapperClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return false;
    }
  };
  return (
    <label
      className={`input-with-label-wrapper ${value?.length ? 'input-with-label-wrapper_not-empty' : ''} 
      ${disabled ? 'input-with-label-wrapper_disabled' : ''} `}
      onClick={handleWrapperClick}
    >
      <input value={value} onChange={onChange} disabled={disabled} />
      <span>{label}</span>
    </label>
  );
}

export default InputWithLabel;
