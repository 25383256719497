import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import * as cartActions from '../../actions/cartActionCreators';
import {useMount} from 'react-use';
import constants from '../../constants';
import OrderWizard from '../../components/OrderWizard';
import CartMobileSuccessOrder from '../../components/OrderWizard/Mobile/OrderSuccess';
import {resetSuccessOrder} from '../../actions/cartActionCreators';
import SeoTags from '../../routes/SeoTags';

const OrderPage = (props) => {
  const
    {cartState, applicationState} = props,
    {is_mobile} = applicationState;

  useMount(() => {
    props.cartActions.fetchCart();
  });

  return props.cartState.successOrder ?
    (
      <Layout
        className='status-page'
        hideFooter
      >
        <SeoTags controller_action='cart#index' />
        <div className='container'>
          <CartMobileSuccessOrder
            order_id={props.cartState.successOrder.order_id}
            onExit={() => props.cartActions.resetSuccessOrder()} />
        </div>
      </Layout>
    )
    : (
    <Layout
      breadcrumbs={[
        {label: 'Назад в корзину', path: constants.ROUTES.CART}
      ]}
      skipRootBreadcrumb
      className='cart-page'
    >
      <div className='container'>
        <h1>
          Оформление заказа
        </h1>
        <OrderWizard order />
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      cartActions: bindActionCreators(cartActions, dispatch)
    };
  },

  mapStateToProps = ({cartState, railsContext, applicationState}) => {
    return {
      applicationState,
      cartState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(OrderPage));
