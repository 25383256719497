import React from 'react';
import {Drawer} from 'antd';
import './style.scss';
import CloseIcon from '../../../Icons/Close';
import {downloadURI} from '../../../../tools/helpers';
import ReviewsList from '../../../Reviews/List';
const MobileReviewsDialog = (props) => {
  const
    handleCancel = () => {
      props.onClose();
    };

  return (
    <>
      <Drawer
        className='select-drawer'
        getContainer='body'
        height='100%'
        placement='bottom'
        onClose={props.onClose}
        title='Все отзывы'
        closeIcon={<CloseIcon />}
        open={props.open}
      >
        <div className='burger-menu-drawer__body'>
          <div className='mb-12'>
            <ReviewsList reviews={props.reviews} />
          </div>
        </div>
      </Drawer>
    </>
  )
}
export default MobileReviewsDialog;
