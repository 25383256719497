import React from 'react';
import {useUnmount} from 'react-use';
import Button from '../../../Button';
import constants from '../../../../constants';
import CheckedRound from '../../../Icons/CheckedRound';
import './style.scss';

const CartMobileSuccessOrder = ({order_id, onExit, ...props}) => {

  useUnmount(() => {
    onExit && onExit();
  });

  return (
    <div className='status-page__wrapper'>
      <div className='status-page__top'>
        <div className='status-page__icon'>
          <CheckedRound width={60} height={60} />
        </div>
        <div className='status-page__title'>
          Заказ {order_id} принят
        </div>
        <div className='status-page__text'>
          Спасибо, что доверяете нам!
        </div>
      </div>
      <div className='status-page__bottom'>
        <Button
          primary
          className='lg w-full'
          to={constants.ROUTES.HOME}>
          На главную
        </Button>
      </div>
    </div>
  );
};

export default CartMobileSuccessOrder;
