import React, {useState} from 'react';
import {toggleInArray} from '../../../../app/tools/helpers';
import CustomCheckbox from '../../Checkbox';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ButtonCheckbox from '../../ButtonCheckbox';

const CheckboxFilter = ({title, items, filterValues = [], onChange, ...props}) => {
  const
    handleChange = (id) => (e) => {
      const result = toggleInArray([...filterValues], id);
      onChange(result);
    };

  return (
    <div className='products-filter'>
      <div className='products-filter__title'>{title}</div>
      <div className={`products-filter__values ${props.applicationState.is_mobile ? 'button-checkbox__group' : null}`}>
        {items.map(item =>
          <div key={item.value} className='products-filter__values-row'>
            {
              props.applicationState.is_mobile ?
                <ButtonCheckbox
                  checked={filterValues.includes(item.value)}
                  onChange={handleChange(item.value)}
                >
                  {item.title}
                </ButtonCheckbox>
                : <CustomCheckbox
                  checked={filterValues.includes(item.value)}
                  onChange={handleChange(item.value)}
                >
                  {item.title}
                </CustomCheckbox>

            }
          </div>
        )}
      </div>
    </div>
  );
};

const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState,
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(CheckboxFilter);
