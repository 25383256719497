import React from 'react';
import StarIcon from '../../Icons/Star';
import {reviewsCountFrom} from '../../../tools/formatters';
import './style.scss';
import './mobile.scss';

const ReviewsWidget = ({offer, ...props}) => {
  return offer.rating && offer.reviews.length ? (
    <button className='reviews-button'>
      <span className='reviews-button__rating'>
        <StarIcon height={20} width={20} />
        {offer.rating.toFixed(1)}
      </span>
      <span className='reviews-button__link' onClick={props.onClick ? props.onClick : null}>{offer.reviews.length} {reviewsCountFrom(offer.reviews.length)}</span>
    </button>
  ) : null;
}

export default ReviewsWidget;
