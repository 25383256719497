import React from 'react';

const HeartIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.94457 14.7461C12.3126 12.9357 16 9.4576 16 5.36221C16 2.40093 13.9531 0 11.4286 0C10.0611 0 8.83771 0.707812 8 1.82382C7.16228 0.707812 5.93886 0 4.57143 0C2.04686 0 0 2.40093 0 5.36221C0 9.4576 3.68686 12.935 6.05428 14.7461C6.78857 15.3078 7.39657 15.7093 7.728 15.9191C7.89886 16.027 8.10057 16.027 8.27143 15.9191C8.60285 15.7093 9.21028 15.3071 9.94457 14.7461Z" fill="#1536A1"/>
    </svg>
  );
}

export default HeartIcon;
