import {call, put, takeEvery, select} from 'redux-saga/effects';
import {notification} from 'antd';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as userRequestsActions from '../actions/userRequestsActionCreators';

const {API, ACTIONS} = constants;

export const userSubscribeRequest = (params) => RequestTools.ajax({method: 'POST', url: API.USER_SUBSCRIBE_PATH, params: params});
export const userQuestionRequest = (params) => RequestTools.ajax({method: 'POST', url: API.USER_QUESTION_PATH, params: params});

export function * subscribe(action) {
  const {applicationState} = yield select();

  try {
    yield call(userSubscribeRequest, {city_id: applicationState.current_city?.id, ...action.payload.params});

    yield put(userRequestsActions.sendSubscribeFormSuccess());
    yield call(notification.success, {message: '', description: 'Заявка успешно отправлена.'});
  } catch (e) {
    yield put(userRequestsActions.sendSubscribeFormFailed(e.response.data?.errors));
  }
}

export function * question(action) {
  const {applicationState} = yield select();
  try {
    yield call(userQuestionRequest, {city_id: applicationState.current_city?.id, ...action.payload.params});

    yield put(userRequestsActions.sendQuestionFormSuccess());
    yield call(notification.success, {message: '', description: 'Ваш вопрос успешно отправлен.'});
  } catch (e) {
    yield put(userRequestsActions.sendQuestionFormFailed(e.response.data?.errors));
  }
}

function * userRequestsSaga() {
  yield takeEvery(ACTIONS.SEND_SUBSCRIBE_FORM, subscribe);
  yield takeEvery(ACTIONS.SEND_QUESTION_FORM, question);
}

export default userRequestsSaga;
