import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as articlesActions from '../../actions/articlesActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useMount} from 'react-use';
import ArticlesList from '../../components/Articles/List';
import SeoTags from '../../routes/SeoTags';

const ArticlesPage = (props) => {
  const
    collection = props.articlesState.articles,
    paginator = {
      ...props.articlesState.paginator,
      onChange: (page, per_page) => {
        props.articlesActions.fetchArticles({
          page,
          per_page
        });
      }
    };

  useMount(() => {
    props.articlesActions.fetchArticles();
  });

  return (
    <Layout
      className='articles-page'
      breadcrumbs={[
        {label: 'Статьи'}
      ]}
    >
      <SeoTags
        controller_action='articles#index'
      />

      <div className='container'>
        <section className='page-content-section'>
          <ArticlesList
            collection={collection}
            linkHelper={constants.ROUTES.REGIONAL_ARTICLE_PATH}
            idKey='id_with_slug'
            paginator={paginator}
          />
        </section>
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      articlesActions: bindActionCreators(articlesActions, dispatch)
    };
  },

  mapStateToProps = ({articlesState, railsContext, applicationState}) => {
    return {
      applicationState,
      articlesState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(ArticlesPage));
