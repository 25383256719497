import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as rubricsActions from '../actions/rubricsActionCreators';
import {setCookie} from '../tools/helpers';

const {API, ACTIONS} = constants;



export const fetchRubricsTreeRequest = () => RequestTools.ajax({url: API.RUBRICS_TREE_PATH});

export function * setCity(action) {
  const state = yield select();
  
  try {
    setCookie('current_branch', action.payload.branchId, {path :'/', expires: 30});
    setCookie('update_city', true, {path: '/'});
    
    // TODO, write route map for new data fetch after change city
    location.reload();
  } catch (e) {
  }
}

function * citiesSaga() {
  yield takeEvery(ACTIONS.SET_CURREN_CITY, setCity);
}

export default citiesSaga;
