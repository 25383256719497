import React from 'react';
import {Drawer} from 'antd';
import './style.scss';
import CloseIcon from '../../../Icons/Close';
import Button from '../../../Button';
import DocumentsList from '../../../DocumentsList';
import {downloadURI} from '../../../../tools/helpers';
const MobileDocumentsDialog = (props) => {
  const
    handleCancel = () => {
      props.onClose();
    },
    handleDownload = () => {
      props.documents.forEach(doc => downloadURI(doc.path, doc.download_title));
      props.onClose();
    };

  return (
    <>
      <Drawer
        className='select-drawer ant-drawer-bottom-radius'
        getContainer='body'
        height='auto'
        placement='bottom'
        onClose={props.onClose}
        title='Документы'
        closeIcon={<CloseIcon />}
        footer={
          <div className='flex gap-12'>
            <Button className='md clean' onClick={handleDownload}>Скачать всё</Button>
          </div>
        }
        open={props.open}
      >
        <div className='burger-menu-drawer__body'>
          <DocumentsList documents={props.documents} />
        </div>
      </Drawer>
    </>
  )
}
export default MobileDocumentsDialog;
