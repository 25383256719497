import React, {useState} from 'react';
import {Drawer} from 'antd';
import CloseIcon from '../../../Icons/Close';
import {Radio} from 'antd';
import Button from '../../../Button';

const MobilePaymentSelect = ({open, onClose, value, options, onChange}) => {
  const
    [localVal, setLocalVal] = useState(value),
    handleSubmit = () => {
      onChange(localVal);
      onClose();
    },
    handleChange = (event) => {
      setLocalVal(event.target.value);
    };
  return (
    <>
      <Drawer
        className='select-drawer ant-drawer-bottom-radius'
        getContainer='body'
        height='auto'
        placement='bottom'
        onClose={onClose}
        title='Способ оплаты'
        closeIcon={<CloseIcon />}
        footer={<Button onClick={handleSubmit} className='w-full md'>Выбрать</Button>}
        open={open}
      >
        <div className='burger-menu-drawer__body'>
          <Radio.Group onChange={handleChange} value={localVal}>
            {options.map(option => <Radio value={option.value}>{option.label}</Radio>)}
          </Radio.Group>
        </div>
      </Drawer>
    </>

  );
}

export default MobilePaymentSelect;
