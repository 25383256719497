import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';

export const sendSubscribeForm = (params) => ({
  type: ACTIONS.SEND_SUBSCRIBE_FORM,
  payload: {params},
  [pendingTask]: begin
});
export const sendSubscribeFormSuccess = () => ({
  type: ACTIONS.SEND_SUBSCRIBE_FORM_SUCCESS,
  [pendingTask]: end
});
export const sendSubscribeFormFailed = (errors) => ({
  type: ACTIONS.SEND_SUBSCRIBE_FORM_FAILED,
  payload: {errors},
  [pendingTask]: end
});

export const changeSubscribeForm = (changes) => ({
  type: ACTIONS.CHANGE_SUBSCRIBE_FORM,
  payload: {changes}
});

export const sendQuestionForm = (params) => ({
  type: ACTIONS.SEND_QUESTION_FORM,
  payload: {params},
  [pendingTask]: begin
});
export const sendQuestionFormSuccess = () => ({
  type: ACTIONS.SEND_QUESTION_FORM_SUCCESS,
  [pendingTask]: end
});
export const sendQuestionFormFailed = (errors) => ({
  type: ACTIONS.SEND_QUESTION_FORM_FAILED,
  payload: {errors},
  [pendingTask]: end
});
export const changeQuestionForm = (changes) => ({
  type: ACTIONS.CHANGE_QUESTION_FORM,
  payload: {changes}
});
