import React from 'react';
import CheckedRoundIcon from '../../Icons/CheckedRound';
import {connect} from 'react-redux';
import {compose} from 'redux';
import ChevronIcon from '../../Icons/Chevron';

const AvailabilityWidget = ({offer, currentUnit, applicationState, ...props}) => {
  const {current_city} = applicationState;

  return (
    <button className='order-sidebar-widget' {...props}>
      <span className='order-sidebar-widget__icon'>
        <CheckedRoundIcon />
      </span>
      <span className='order-sidebar-widget__info gap-4'>
        <span className={`order-sidebar-widget__title text-600 ${offer.reminder ? 'text-success' : 'text-error'}`}>
          {offer.reminder ? 'В наличии' : 'Под заказ'}
        </span>
        {
          offer.reminder ?
            <span className='order-sidebar-widget__value'>
              <span className='text-600'>{offer.reminderStrInUnits(currentUnit.id)}</span>
              &nbsp;в {current_city.name_preposition || current_city.name}
            </span>
            :null
        }
      </span>
      {
        props.onClick ?  <ChevronIcon direction='right' /> : null
      }
    </button>
  );
}


const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(AvailabilityWidget);
