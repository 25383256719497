import React from 'react';
import './style.scss';
import {Link} from 'react-router-dom';
import constants from '../../../constants';
import {connect} from 'react-redux';
import {compose} from 'redux';

const HeaderLinks = (props) => {
  const
    city = props.applicationState.current_city,
    citySlug = city.slug,
    links = [
      {label: 'Оплата и доставка', path: '/'},
      {label: 'Калькуляторы', path: '/'},
      {label: 'Отзывы', path: constants.ROUTES.REVIEWS_PATH()},
      {label: 'Акции', path: constants.ROUTES.REGIONAL_STOCKS_PATH(citySlug)},
    ];
  return (
    <>
      {
        links.map((link, idx) => <Link key={idx} to={link.path}>{link.label}</Link>)
      }
    </>
  );
};
const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(HeaderLinks);
