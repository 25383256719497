import React from 'react';

const CloseIcon = ({width = 14, height = 14, color = '#000', opacity='0.4'}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.87464 8.92031C6.97227 8.82268 7.13056 8.82268 7.22819 8.92031L11.5182 13.2104C11.7135 13.4056 12.0301 13.4056 12.2254 13.2104L13.3132 12.1225C13.5085 11.9273 13.5085 11.6107 13.3132 11.4154L9.02314 7.12536C8.92551 7.02773 8.92551 6.86944 9.02314 6.77181L13.3132 2.48175C13.5085 2.28649 13.5085 1.9699 13.3132 1.77464L12.2254 0.686798C12.0301 0.491536 11.7135 0.491536 11.5182 0.686798L7.22819 4.97686C7.13056 5.07449 6.97227 5.07449 6.87464 4.97686L2.48192 0.584142C2.28666 0.38888 1.97008 0.38888 1.77482 0.584142L0.686974 1.67199C0.491711 1.86725 0.491711 2.18383 0.686973 2.37909L5.07969 6.77181C5.17732 6.86944 5.17732 7.02773 5.07969 7.12536L0.686975 11.5181C0.491713 11.7133 0.491713 12.0299 0.686975 12.2252L1.77482 13.313C1.97008 13.5083 2.28666 13.5083 2.48193 13.313L6.87464 8.92031Z" fill={color} fillOpacity={opacity} />
    </svg>
  );
};

export default CloseIcon;
