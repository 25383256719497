import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import * as cartActions from '../../actions/cartActionCreators';
import CartPositions from './Positions';
import CartInfo from './CartInfo';
import {toggleInArray} from '../../../app/tools/helpers';
import Checkbox from '../Checkbox';
import CloseIcon from '../Icons/Close';

import './style.scss';
import './mobile.scss';
import Button from '../Button';
import TrashIcon from '../Icons/Trash';

let itemsUpdateTimeout = null;

const Cart = (props) => {
  const
    {cartState, cartActions} = props,
    [itemsForOrder, setItemsForOrder] = useState(cartState.info.itemsForOrder || []),
    handleRemoveSelected = (mode) => () => {
      const ids = (mode === 'available' ? cartState.available_positions : cartState.unavailable_positions).reduce((result, item) => {
        if (itemsForOrder.includes(item.id)) {
          result.push(item.offer.id)
        }
        return result;
      }, [])
      cartActions.removeCartPositions(ids, mode);
    },

    handleRemoveAllUnavailable = () => {
      const ids = cartState.unavailable_positions.reduce((result, item) => {
        result.push(item.offer.id)
        return result;
      }, [])
      cartActions.removeCartPositions(ids, 'unavailable');
    },

    handleToggleSelected = (mode) => (event) => {
      const
        {checked} = event.target,
        collection = (mode === 'available' ? cartState.available_positions : cartState.unavailable_positions);

      if (checked) {
        const ids = collection.map(cartItem => cartItem.id);
        setItemsForOrder(ids);
        cartActions.setCartPositionsForOrder(ids);
      } else {
        setItemsForOrder([]);
        cartActions.setCartPositionsForOrder([]);
      }
    },

    handleSelectForOrder = (id) => {
      const ids = toggleInArray([...itemsForOrder], id);
      setItemsForOrder(ids);
      clearTimeout(itemsUpdateTimeout);

      itemsUpdateTimeout = setTimeout(() => {
        cartActions.setCartPositionsForOrder(ids);
      }, 600);
    };

  useEffect(() => {
    const
      items1key = cartState.info.itemsForOrder.sort().join('_'),
      items2key = itemsForOrder.sort().join('_');
    if (items2key === items1key) { return; }

    setItemsForOrder(cartState.info.itemsForOrder);

  }, [cartState.info.itemsForOrder, setItemsForOrder])

  return (
    <div className='cart-page-content'>
      <div className='cart-page__col cart-page__col_1'>
        {
          cartState.available_positions.length?
            <>
              <div className='cart-page__position-actions'>
                <Checkbox onChange={handleToggleSelected('available')}>Выбрать все</Checkbox>
                <button className='text-button text-gray-60' onClick={handleRemoveSelected('available')}>
                  <CloseIcon opacity='0.2' /> Удалить выбранные
                </button>
              </div>
              <div className='cart-page__section'>
                <CartPositions
                  type='available'
                  onSelectForOrder={handleSelectForOrder}
                  itemsForOrder={itemsForOrder}
                  positions={cartState.available_positions}
                />
              </div>
            </>
            : null
        }
        {
          cartState.unavailable_positions.length?
            <div className='cart-page__section'>
              <h2 className='flex justify-between items-center gap-4'>
                <span>Позиции под заказ</span>
                <Button className='md clean' onClick={handleRemoveAllUnavailable}>
                  <TrashIcon />
                  Удалить все
                </Button>
              </h2>
              <CartPositions
                type='unavailable'
                itemsForOrder={itemsForOrder}
                onSelectForOrder={handleSelectForOrder}
                positions={cartState.unavailable_positions}
              />
            </div>
            : null
        }
      </div>
      <div className='cart-page__col cart-page__col_2'>
        <CartInfo itemsForOrder={itemsForOrder} />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      cartActions: bindActionCreators(cartActions, dispatch)
    };
  },

  mapStateToProps = ({applicationState, cartState}) => {
    return {
      applicationState,
      cartState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(Cart);
