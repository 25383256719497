import React, {useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../../../constants';
import './style.scss';
import ChevronIcon from '../../../Icons/Chevron';

const MobileMenuRubricsList = ({rubrics, ...props}) => {
  const
    handleChildrenSelected = (rubric) => () => props.onChildrenSelected(rubric),
    handleLinkClick = () => props.onLinkClick && props.onLinkClick(),
    renderItemContent = (rubric) => {
      return (
        <>
          <div className='rubrics-menu-list__item-img'>
            <div className='rubrics-menu-list__item-img-bg' />
            <img src={rubric.image.small} alt={rubric.image.alt || rubric.title} />
          </div>
          <span className='rubrics-menu-list__item-title'>{rubric.title}</span>
          <ChevronIcon direction='right' opacity='.2' />
        </>
      );
    };

  return (
    <ul className='burger-menu-options rubrics-menu-list'>
      {
        rubrics.map((rubric) => {
          return (
            <li className='burger-menu-option rubrics-menu-list__item' key={rubric.id}>
              {
                rubric.children.length ?
                  <button onClick={handleChildrenSelected(rubric)}>
                    {renderItemContent(rubric)}
                  </button>
                  :
                  <Link onClick={handleLinkClick} to={constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, rubric.joined_path)}>
                    {renderItemContent(rubric)}
                  </Link>
              }
            </li>
          )
        })
      }
    </ul>
  );
};

export const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(MobileMenuRubricsList);
