import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as cartsActions from '../actions/cartActionCreators';
import {notification} from 'antd';

const {API, ACTIONS} = constants;

export const fetchCartRequest = () => RequestTools.ajax({url: API.CART_PATH});
export const addToRequest = (params) => RequestTools.ajaxPost({method: 'PUT',url: API.CART_PATH, data: params});
export const setQtyRequest = (params) => RequestTools.ajaxPost({method: 'POST',url: API.CART_SET_QTY_PATH, data: params});
export const deleteRequest = (params) => RequestTools.ajaxPost({method: 'DELETE', url: API.CART_PATH, data: params});
export const changeRequest = (params) => RequestTools.ajaxPost({method: 'POST', url: API.CART_CHANGE_UNIT_PATH, data: params});
export const setForOrderRequest = (params) => RequestTools.ajaxPost({method: 'POST', url: API.SET_CART_POSITIONS_FOR_ORDER_PATH, data: params});
export const sendOrderRequest = (params) => RequestTools.ajaxPost({method: 'POST', url: API.CART_ORDER_PATH, data: params});

export function * fetchCart(action) {
  try {
    const
      response = yield call(fetchCartRequest);

    yield put(cartsActions.fetchCartSuccess(response.data));
  } catch (e) {
    yield put(cartsActions.fetchCartFailed());
  }
}

export function * addToCart(action) {
  try {
    const
      response = yield call(addToRequest, {offer_id: action.payload.offerId});

    yield put(cartsActions.addToCartSuccess(response.data));
    yield call(notification.success, {message: '', description: 'Товар успешно добавлен в корзину.'});
  } catch (e) {
    yield put(cartsActions.addToCartFailed());
    yield call(notification.error, {message: '', description: 'Товар не добавлен в корзину.'});
  }
}

export function * setQty(action) {
  try {
    const
      params = {
        offer_id: action.payload.offerId,
        mode: action.payload.mode,
        qty: action.payload.qty,
        unit_id: action.payload.unitId
      },
      response = yield call(setQtyRequest, params);

    yield put(cartsActions.setCartPositionQtySuccess(response.data));
  } catch (e) {
    yield put(cartsActions.setCartPositionQtyFailed());
  }
}

export function * deletePositions(action) {
  try {
    const
      params = {
        offer_ids: action.payload.offerIds,
        mode: action.payload.mode
      },
      response = yield call(deleteRequest, params);

    yield put(cartsActions.removeCartPositionsSuccess(response.data));
  } catch (e) {
    yield put(cartsActions.removeCartPositionsFailed());
  }
}

export function * changePosition(action) {
  try {
    const
      params = {
        offer_id: action.payload.offerId,
        unit_id: action.payload.changes.unit_id
      },
      response = yield call(changeRequest, params);

    yield put(cartsActions.changeCartPositionSuccess(response.data));
  } catch (e) {
    yield put(cartsActions.changeCartPositionFailed());
  }
}

export function * setPositionsForOrder(action) {
  try {
    const
      params = {
        cart_item_ids: action.payload.cart_item_ids
      },
      response = yield call(setForOrderRequest, params);

    yield put(cartsActions.setCartPositionsForOrderSuccess(response.data));
  } catch (e) {
    yield put(cartsActions.setCartPositionsForOrderFailed());
  }
}
export function * sendOrder(action) {
  try {
    const
      {applicationState} = yield select(),
      {orderParams} = action.payload,
      {last_name, first_name, patronymic, phone, email} = orderParams?.recipient || {},
      params = {
        user_name: [last_name, first_name, patronymic].join(' '),
        user_email: email,
        user_phone: phone,
        payment_type: orderParams.payment_type,
        pickup: orderParams.pickup,
        delivery_address: orderParams.address,
        city_id: applicationState.current_city.id,
        has_questions: orderParams.has_questions,
        comment: orderParams.comment,
        subscribe: orderParams.subscribe,
      },
      response = yield call(sendOrderRequest, params);

    yield put(cartsActions.sendOrderSuccess(response.data));
  } catch (e) {
    yield put(cartsActions.sendOrderFailed(e.response?.data?.errors));
  }
}

function * cartsSaga() {
  yield takeEvery(ACTIONS.FETCH_CART, fetchCart);
  yield takeEvery(ACTIONS.ADD_TO_CART, addToCart);
  yield takeEvery(ACTIONS.SET_CART_POSITION_QTY, setQty);
  yield takeEvery(ACTIONS.REMOVE_CART_POSITIONS, deletePositions);
  yield takeEvery(ACTIONS.CHANGE_CART_POSITION, changePosition);
  yield takeEvery(ACTIONS.SET_CART_POSITIONS_FOR_ORDER, setPositionsForOrder);
  yield takeEvery(ACTIONS.CART_SEND_ORDER, sendOrder);
}

export default cartsSaga;
