import React from 'react';
import './style.scss';
import './mobile.scss';

const ProductShowTraits = ({traits}) => {
  return (
    <div className='product-page-traits'>
      {
        traits.map((trait, idx) =>
          <div className='product-page-traits__item' key={idx}>
            <div className='product-page-traits__title'>{trait[0]}</div>
            <div className='product-page-traits__value'>{trait[1]}</div>
          </div>)
      }
    </div>
  );
}

export default ProductShowTraits;
