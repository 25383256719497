import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Drawer} from 'antd';
import CloseIcon from '../../Icons/Close';
import Button from '../../Button';
import * as productsActions from '../../../actions/productsActionCreators';
import * as cartActions from '../../../actions/cartActionCreators';
import BranchListItem from '../../BranchListItem';
import './style.scss';
import MobileSelectCity from '../../Header/Mobile/SelectCity';
import CustomSwitch from '../../Switch';
import {YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import {calculateCentroid} from '../../../tools/helpers';
import PlacemarkIcon from './placemark.svg';
const AvailabilityDialog = ({open, onClose, offer, applicationState, currentUnit, ...props}) => {
  const
    {current_city, current_branch} = applicationState,
    mapRef = useRef(null),
    [ymap, setYmap] = useState(null),
    [selectedCity, setSelectedCity] = useState(current_city),
    [mode, setMode] = useState('list'),
    stats = (offer.branchStats || []).reduce((result, item) => {
      const branch = applicationState.branches.find(branch => branch.id === item.branch_id);
      if (branch.city_id === selectedCity.id) {
        result.push({
          branch: branch,
          stats: item
        });
      }

      return result
    }, []),
    placemarks = stats.map(stat => {
      return {
        lat: stat.branch.lat,
        long: stat.branch.long,
      };
    }),

    handleModeChanged = () => {
      setMode(mode === 'list' ? 'map' : 'list');
    },
    handleAddToCart = () => {
      props.cartActions.addToCart(offer.id, 1, currentUnit?.id);
      onClose();
    },
    handleSelectCity = (branch, city) => {
      setSelectedCity(city);
    },
    handleMapLoad = (ymaps) => {
      setYmap(ymaps);
    };

  useEffect(() => {
    if (!current_city || !mapRef || !ymap) { return; }
    const map = mapRef.current;
    map.setCenter(calculateCentroid(placemarks.map(pl => [pl.lat, pl.long])));
  }, [selectedCity])

  useEffect(() => {
    if (open) {
      props.productsActions.fetchProductBranchStats(offer.product_id);
    }
  }, [open, offer.id])

  return (
    <Drawer
      className='select-drawer ant-drawer-top-radius'
      getContainer='body'
      height='auto'
      placement='top'
      onClose={onClose}
      title={<MobileSelectCity onSelect={handleSelectCity} selected={selectedCity} />}
      closeIcon={<CloseIcon />}
      open={open}
      footer={
        <Button primary onClick={handleAddToCart} className='w-full'>Добавить в корзину</Button>
      }
    >
      <YMaps query={{lang: "ru_RU", load: "package.full"}}>
        <div className='burger-menu-drawer__body'>
          <div className='branch-offer-availability'>
            <div className='branch-offer-availability-actions' onClick={handleModeChanged}>
              <CustomSwitch checked={mode === 'map'}></CustomSwitch>
              <span>На карте</span>
            </div>
            {
              mode === 'list' ?
                <div className='branch-offer-availability-list'>
                  {
                    stats.map(stat => {
                      return (
                        <div className='branch-offer-availability-list-item' key={stat.stats.id}>
                          <BranchListItem branch={stat.branch} />
                          <div className='branch-offer-availability-list-item__reminder'>{stat.stats.reminderStrInBaseUnits}</div>
                        </div>
                      )
                    })
                  }
                </div>
                :
                <div className='branch-offer-availability-map'>
                  <Map
                    width='100%'
                    onLoad={handleMapLoad}
                    instanceRef={mapRef}
                    defaultState={{ center: calculateCentroid(placemarks.map(pl => [pl.lat, pl.long])), zoom: 11 }}
                  >
                    {
                      placemarks.map(pl =>
                        <Placemark
                          key={pl.lat}
                          geometry={[pl.lat, pl.long]}
                          options={{
                            iconLayout: "default#image",
                            iconImageHref: PlacemarkIcon,
                            iconImageSize: [48, 47],
                          iconImageOffset: [-24, -24]
                        }} />)
                    }
                  </Map>
                </div>
            }
          </div>
        </div>
      </YMaps>
    </Drawer>
  );
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      productsActions: bindActionCreators(productsActions, dispatch),
      cartActions: bindActionCreators(cartActions, dispatch),
    };
  },

  mapStateToProps = ({applicationState, productsState}) => {
    return {
      applicationState,
      productsState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(AvailabilityDialog);
