import React from 'react';

const ClockIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.8376 0.93928C8.89196 0.93928 11.368 3.41531 11.368 6.46964C11.368 9.524 8.89196 12 5.8376 12C2.78327 12 0.307242 9.524 0.307242 6.46964C0.307242 3.41531 2.78327 0.93928 5.8376 0.93928ZM5.83766 3.39722C5.52253 3.39722 5.26281 3.63444 5.22731 3.94004L5.22318 4.0117V6.46122C5.22139 6.59462 5.26274 6.72848 5.34723 6.84005L5.4031 6.90414L6.69684 8.19788C6.93685 8.4379 7.32588 8.4379 7.56584 8.19788C7.78739 7.97638 7.80444 7.62786 7.61697 7.38677L7.56584 7.32887L6.45215 6.21518V4.0117C6.45215 3.67233 6.17704 3.39722 5.83766 3.39722ZM9.525 0.0826882C10.2938 0.527452 10.9746 1.10686 11.5353 1.7886C11.7509 2.05072 11.7132 2.43795 11.451 2.65352C11.189 2.86909 10.8017 2.83136 10.5862 2.56925C10.1183 2.0004 9.55038 1.51715 8.90959 1.14649C8.61581 0.976554 8.51546 0.600662 8.68537 0.306901C8.85533 0.0131348 9.23121 -0.0872474 9.525 0.0826882ZM2.98985 0.306901C3.15979 0.600662 3.05941 0.976554 2.76564 1.14649C2.12488 1.51715 1.55694 2.0004 1.08909 2.56925C0.873526 2.83136 0.486291 2.86909 0.224176 2.65352C-0.0379322 2.43795 -0.0756616 2.05072 0.139912 1.7886C0.700604 1.10686 1.38139 0.527452 2.15027 0.0826882C2.44403 -0.0872474 2.81992 0.0131409 2.98985 0.306901Z" fill="white"/>
    </svg>
  );
}

export default ClockIcon;
