import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';

export const fetchRootRubrics = () => ({
  type: ACTIONS.FETCH_ROOT_RUBRICS,
});
export const fetchRootRubricsSuccess = (rubrics) => ({
  type: ACTIONS.FETCH_ROOT_RUBRICS_SUCCESS,
  payload: {rubrics}
});
export const fetchRootRubricsFailed = () => ({
  type: ACTIONS.FETCH_ROOT_RUBRICS_FAILED
});

export const fetchRubricsTree = () => ({
  type: ACTIONS.FETCH_RUBRICS_TREE,
});
export const fetchRubricsTreeSuccess = (rubrics) => ({
  type: ACTIONS.FETCH_RUBRICS_TREE_SUCCESS,
  payload: {rubrics}
});
export const fetchRubricsTreeFailed = () => ({
  type: ACTIONS.FETCH_RUBRICS_TREE_FAILED
});

export const fetchRubric = (path) => ({
  type: ACTIONS.FETCH_RUBRIC,
  payload: {path}
});
export const fetchRubricSuccess = (rubric) => ({
  type: ACTIONS.FETCH_RUBRIC_SUCCESS,
  payload: {rubric}
});
export const fetchRubricFailed = () => ({
  type: ACTIONS.FETCH_RUBRIC_FAILED
});

export const resetRubric = () => ({
  type: ACTIONS.RESET_RUBRIC
});

export const fetchRubricBrands = () => ({
  type: ACTIONS.FETCH_RUBRIC_BRANDS
});
export const fetchRubricBrandsSuccess = (brands) => ({
  type: ACTIONS.FETCH_RUBRIC_BRANDS_SUCCESS,
  payload: {brands}
});
export const fetchRubricBrandsFailed = () => ({
  type: ACTIONS.FETCH_RUBRIC_BRANDS_FAILED
});

export const fetchRubricStocks = () => ({
  type: ACTIONS.FETCH_RUBRIC_STOCKS
});
export const fetchRubricStocksSuccess = (stocks) => ({
  type: ACTIONS.FETCH_RUBRIC_STOCKS_SUCCESS,
  payload: {stocks}
});
export const fetchRubricStocksFailed = () => ({
  type: ACTIONS.FETCH_RUBRIC_STOCKS_FAILED
});

export const fetchRubricBestOffers = () => ({
  type: ACTIONS.FETCH_RUBRIC_BEST_OFFERS
});
export const fetchRubricBestOffersSuccess = (offers) => ({
  type: ACTIONS.FETCH_RUBRIC_BEST_OFFERS_SUCCESS,
  payload: {offers}
});
export const fetchRubricBestOffersFailed = () => ({
  type: ACTIONS.FETCH_RUBRIC_BEST_OFFERS_FAILED
});
