import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';

const ContactsPage = (props) => {
  return (
    <Layout>
      <div className='container'>
        <h1>Контакты</h1>
        <Link to={constants.ROUTES.REGIONAL_HOME_PATH('perm')}>Home</Link>
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
  },

  mapStateToProps = ({rubricsState, railsContext}) => {
    return {
      rubricsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(ContactsPage));
