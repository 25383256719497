export default class CartItem {
  offer: any
  unit_id: number
  constructor(cart_item_attributes:any) {
    Object.keys(cart_item_attributes).forEach(key => this[key] = cart_item_attributes[key]);
  }

  get requestedUnit() {
    return this.offer.units.find(unit => unit.id === this.unit_id);
  }

  get orderingRate() {
    const
      orderingUnit = this.offer.units.find(unit => unit.base_line !== 1),
      baseUnit = this.offer.baseUnit;

    return [orderingUnit?.rate || 1, baseUnit?.rate || 1][this.unit_id === baseUnit.id ? 0 : 1];
  }

  get priceInUnits() {
    const rate = this.requestedUnit?.rate;
    return this.offer.price * rate;
  }

  get newPriceInUnits() {
    const rate = this.requestedUnit?.rate;
    return this.offer.new_price * rate;
  }

  get reminderStrInRequestedUnits() {
    return this.offer.reminderStrInUnits(this.unit_id);
  }
}
