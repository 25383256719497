import React, {useState, useRef} from 'react';
import {Input} from 'antd';
import _ from 'lodash';
import Highlighter from "react-highlight-words";
import SearchIcon from '../../Icons/Search';
import {bindActionCreators, compose} from 'redux';
import * as applicationActions from '../../../actions/applicationActionCreators';
import * as searchActions from '../../../actions/searchActionCreators';
import {connect} from 'react-redux';
import constants from '../../../constants';
import ImageBox from '../../ImgBox';
import ChevronIcon from '../../Icons/Chevron';
import {Link} from 'react-router-dom';

import './style.scss';
import CloseIcon from '../../Icons/Close';

let searchTimer = null;

const Search = (props) => {
  const
    [query, setQuery] = useState(''),
    [focus, setFocus] = useState(false),
    [localLoading, setLocalLoading] = useState(false),
    {loading, suggestions} = props.searchState,
    {offers, brands, rubrics, highlighter, search_phrase, empty} = suggestions,
    highlighters = _.isArray(highlighter) ? highlighter : [highlighter],
    city_slug = props.applicationState.current_city.slug,
    handleReset = () => {
      setQuery('');
      props.onClose && props.onClose();
      setLocalLoading(false);
      props.searchActions.resetSearchSuggestions()
    },

    handleKeyUp = (e) => {
      if (e.keyCode === 27) {
        handleReset();
        setLocalLoading(false);
        e.target.blur();
      }
    },

    handleInputChange = (e) => {
      setLocalLoading(true);
      const value = e.target.value;
      if (!query.length || query.length < 3) {
        props.searchActions.resetSearchSuggestions();
      }
      clearTimeout(searchTimer);

      setQuery(value);

      if (query.length >= 3) {
        searchTimer = setTimeout(() => {
          props.searchActions.fetchSearchSuggestions(value);
          setLocalLoading(false);
        }, 600)
      }
    };

  return (
    <>
      <div className='search-bar'>
        <button className='search-bar__search-button'>
          <SearchIcon color={query.length || focus ? '#B5BCD4' : '#fff'} opacity={query.length || focus ? '1' : '0.4'}/>
        </button>
        {
          query.length ?
            <button className='search-bar__close-button' onClick={handleReset}>
              <CloseIcon />
            </button>
            : null
        }
        <Input
          placeholder='Поиск по товарам'
          className={`${query.length || focus ? 'white-bg' : ``}`}
          onChange={handleInputChange}
          onKeyUp={handleKeyUp}
          onFocus={() => setFocus(true)}
          value={query}
        />
        {
          (!loading && !localLoading) && focus && query.length >= 3 ?
            <>
              <div className='search-bar__out-overlay' onClick={() => setFocus(false)} />
              <div className='search-bar__out'>
                {
                  empty ? <div className='search-bar-results__empty'>
                      По запросу «<b>{search_phrase}</b>» — ничего не найдено, попробуйте изменить запрос
                    </div>
                    :null
                }
                {
                  offers.length ?
                    <div className='search-bar-results'>
                      <div className='search-bar-results__title'>Товары</div>
                      <ul className='burger-menu-options search-bar-results__list'>
                        {
                          offers.map((offer) => (
                            <li className='burger-menu-option search-bar-results__list-item'>
                              <Link
                                onClick={handleReset}
                                to={constants.ROUTES.REGIONAL_PRODUCT_PATH(city_slug, offer.rubric_path, offer.id_with_slug)}
                              >
                                <div className='search-bar-results__list-item-img'>
                                  <ImageBox className='with-border' url={offer.image.small} width={48} height={48} alt={offer.image.alt || offer.name} />
                                </div>
                                <div className='search-bar-results__list-item-title'>
                                  <Highlighter
                                    highlightClassName="highlighted"
                                    searchWords={highlighters}
                                    autoEscape={true}
                                    caseSensitive={false}
                                    textToHighlight={offer.name}
                                  />
                                  <div className='search-bar-results__list-item-subtitle'>{offer.rubric?.title}</div>
                                </div>
                              </Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    : null
                }
                {
                  rubrics.length ?
                    <div className='search-bar-results'>
                      <div className='search-bar-results__title'>Категории</div>
                      <ul className='burger-menu-options search-bar-results__list'>
                        {
                          rubrics.map((rubric) => (
                            <li className='burger-menu-option search-bar-results__list-item'>
                              <Link
                                onClick={handleReset}
                                to={constants.ROUTES.REGIONAL_RUBRIC_PATH(city_slug, rubric.joined_path)}
                              >
                                <div className='search-bar-results__list-item-img'>
                                  <SearchIcon color='#000' height={24} width={24} />
                                </div>
                                <div className='search-bar-results__list-item-title'>
                                  <Highlighter
                                    highlightClassName="highlighted"
                                    searchWords={highlighters}
                                    autoEscape={true}
                                    caseSensitive={false}
                                    textToHighlight={rubric.title}
                                  />
                                  <div className='search-bar-results__list-item-subtitle'>Каталог</div>
                                </div>
                              </Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    : null
                }
                {
                  brands.length ?
                    <div className='search-bar-results'>
                      <div className='search-bar-results__title'>Бренды</div>
                      <ul className='burger-menu-options search-bar-results__list'>
                        {
                          brands.map((brand) => (
                            <li className='burger-menu-option search-bar-results__list-item'>
                              <Link to={constants.ROUTES.REGIONAL_BRAND_PATH(city_slug, brand.id_with_slug)} >
                                <div className='search-bar-results__list-item-img'>
                                  <SearchIcon color='#000' height={24} width={24} />
                                </div>
                                <div className='search-bar-results__list-item-title'>
                                  <Highlighter
                                    highlightClassName="highlighted"
                                    searchWords={highlighters}
                                    autoEscape={true}
                                    caseSensitive={false}
                                    textToHighlight={brand.title}
                                  />
                                </div>
                                <ChevronIcon direction='right' opacity='.2' />
                              </Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    : null
                }
              </div>
            </>
            :null
        }
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
    return {
      applicationActions: bindActionCreators(applicationActions, dispatch),
      searchActions: bindActionCreators(searchActions, dispatch),
    };
  },

  mapStateToProps = ({applicationState, railsContext, searchState}) => {
    return {
      applicationState,
      railsContext,
      searchState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(Search);
