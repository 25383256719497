import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import * as cartActions from '../../../actions/cartActionCreators';
import AnimatedNumber from "animated-number-react";
import {
  isFloatPrice,
  priceFormat,
  priceFormatInt,
} from '../../../tools/formatters';
import constants from '../../../constants';
import Button from '../../Button';
import './style.scss';
import './mobile.scss';

const CartInfo = (props) => {
  const
    {cartState, cartActions, orderScreen, orderParams} = props,
    handleSubmitOrder = () => {
      cartActions.sendOrder(orderParams);
    };
  return (
    <div className='cart-page-info'>
      <div className='cart-page-info__top'>
        <div className='cart-page-info__item cart-page-info__item_large'>
          {
            !props.applicationState.is_mobile ?
              <>
                <div className='cart-page-info__item-key'>Итого</div>
                <div className='cart-page-info__item-value'>
                  <AnimatedNumber
                    value={cartState.info.subtotal_without_discount_for_order}
                    duration={300}
                    formatValue={isFloatPrice(cartState.info.subtotal_without_discount_for_order) ? priceFormat : priceFormatInt}
                  />
                  <span>₽</span>
                </div>
              </>
              :
              <div className='cart-page-info__item-key'>{orderScreen ? 'Общая стоимость' : 'Ваша корзина'}</div>
          }
        </div>

        <div className='cart-page-info__item'>
          <div className='cart-page-info__item-key'>
            Товары
            {/*{productsCountFrom(cartState.info.qty_for_order)}({cartState.info.qty_for_order})*/}
          </div>
          <div className='cart-page-info__item-value'>
            {
              cartState.info.any_not_available_for_order ? 'от ' : ''
            }
            <AnimatedNumber
              value={cartState.info.subtotal_without_discount_for_order}
              duration={300}
              formatValue={isFloatPrice(cartState.info.subtotal_without_discount_for_order) ? priceFormat : priceFormatInt}
            />
            <span>₽</span>
          </div>
        </div>
        {
          cartState.info.total_discount_for_order > 0 ?
            <>
              <div className='cart-page-info__item cart-page-info__item_discount'>
                <div className='cart-page-info__item-key'>Скидка</div>
                <div className='cart-page-info__item-value'>
                  -
                  <AnimatedNumber
                    value={cartState.info.total_discount_for_order}
                    duration={300}
                    formatValue={isFloatPrice(cartState.info.total_discount_for_order) ? priceFormat : priceFormatInt}
                  />
                  <span>₽</span>
                </div>
              </div>
              <div className='cart-page-info__item'>
                <div className='cart-page-info__item-key'>Итого с учетом скидки:</div>
                <div className='cart-page-info__item-value'>
                  <AnimatedNumber
                    value={cartState.info.subtotal_for_order_num}
                    duration={300}
                    formatValue={isFloatPrice(cartState.info.subtotal_for_order_num) ? priceFormat : priceFormatInt}
                  />
                  <span>₽</span>
                </div>
              </div>
            </>
            : null
        }

      </div>
      <div className='cart-page-info__bottom'>
        {
          orderScreen ?
            <Button
              primary
              disabled={!props.itemsForOrder.length || props.disableAction}
              className='lg w-full'
              onClick={handleSubmitOrder}
            >
              Заказать
            </Button>
            :
            <Button
              primary
              disabled={!props.itemsForOrder.length}
              className='lg w-full'
              to={constants.ROUTES.ORDER_PATH}>
              Перейти к оформлению
            </Button>
        }
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      cartActions: bindActionCreators(cartActions, dispatch)
    };
  },

  mapStateToProps = ({applicationState, cartState}) => {
    return {
      applicationState,
      cartState
    };
  },

  withConnect = connect(
    mapStateToProps, mapDispatchToProps
  );

export default compose(withConnect)(CartInfo);
