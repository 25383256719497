import {pendingTask, begin, end} from 'react-redux-spinner';
import ACTIONS from '../constants/actions';

export const fetchArticles = (options) => ({
  type: ACTIONS.FETCH_ARTICLES,
  payload: {options}
});
export const fetchArticlesSuccess = (articles, paginator) => ({
  type: ACTIONS.FETCH_ARTICLES_SUCCESS,
  payload: {articles, paginator}
});
export const fetchArticlesFailed = () => ({
  type: ACTIONS.FETCH_ARTICLES_FAILED
});

export const  resetArticles = () => ({
  type: ACTIONS.RESET_ARTICLES
});

export const fetchArticle = (id) => ({
  type: ACTIONS.FETCH_ARTICLE,
  payload: {id}
});
export const fetchArticleSuccess = (article) => ({
  type: ACTIONS.FETCH_ARTICLE_SUCCESS,
  payload: {article}
});
export const fetchArticleFailed = () => ({
  type: ACTIONS.FETCH_ARTICLE_FAILED
});

export const  resetArticle = () => ({
  type: ACTIONS.RESET_ARTICLE
});

export const fetchEvents = (options) => ({
  type: ACTIONS.FETCH_EVENTS,
  payload: {options}
});
export const fetchEventsSuccess = (events, paginator) => ({
  type: ACTIONS.FETCH_EVENTS_SUCCESS,
  payload: {events, paginator}
});
export const fetchEventsFailed = () => ({
  type: ACTIONS.FETCH_EVENTS_FAILED
});
export const  resetEvents = () => ({
  type: ACTIONS.RESET_EVENTS
});

export const fetchEvent = (id) => ({
  type: ACTIONS.FETCH_EVENT,
  payload: {id}
});
export const fetchEventSuccess = (event) => ({
  type: ACTIONS.FETCH_EVENT_SUCCESS,
  payload: {event}
});
export const fetchEventFailed = () => ({
  type: ACTIONS.FETCH_EVENT_FAILED
});

export const  resetEvent = () => ({
  type: ACTIONS.RESET_EVENT
});

export const fetchStocks = () => ({
  type: ACTIONS.FETCH_STOCKS,
});
export const fetchStocksSuccess = (stocks) => ({
  type: ACTIONS.FETCH_STOCKS_SUCCESS,
  payload: {stocks}
});
export const fetchStocksFailed = () => ({
  type: ACTIONS.FETCH_STOCKS_FAILED
});
export const  resetStocks = () => ({
  type: ACTIONS.RESET_STOCKS
});
export const fetchStock = (id) => ({
  type: ACTIONS.FETCH_STOCK,
  payload: {id}
});
export const fetchStockSuccess = (stock) => ({
  type: ACTIONS.FETCH_STOCK_SUCCESS,
  payload: {stock}
});
export const fetchStockFailed = () => ({
  type: ACTIONS.FETCH_STOCK_FAILED
});

export const  resetStock = () => ({
  type: ACTIONS.RESET_STOCK
});

export const fetchStocksProducts = () => ({
  type: ACTIONS.FETCH_STOCKS_PRODUCTS
});
export const fetchStocksProductsSuccess = (offers) => ({
  type: ACTIONS.FETCH_STOCKS_PRODUCTS_SUCCESS,
  payload: {offers}
});
export const fetchStocksProductsFailed = () => ({
  type: ACTIONS.FETCH_STOCKS_PRODUCTS_FAILED
});

export const fetchStockProducts = () => ({
  type: ACTIONS.FETCH_STOCK_PRODUCTS
});
export const fetchStockProductsSuccess = (offers) => ({
  type: ACTIONS.FETCH_STOCK_PRODUCTS_SUCCESS,
  payload: {offers}
});
export const fetchStockProductsFailed = () => ({
  type: ACTIONS.FETCH_STOCK_PRODUCTS_FAILED
});
