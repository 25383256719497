import React from 'react';
import {Spinner} from 'react-redux-spinner';

import './antd.scss';
import './Layout.scss';
import './MobileLayout.scss';

import Header from '../components/Header';
import RubricsMenu from '../components/RubricsMenu';
import Footer from '../components/Footer';
import {connect} from 'react-redux';
import {compose} from 'redux';
import Breadcrumbs from '../components/Breadcrumbs';

const Layout = ({className = '', ...props}) => {
  return (
    <div className={`page-layout ${className}`}>
      <Spinner config={{}} />
      <div className='page-layout__header'>
        <Header />
        {props.applicationState.is_mobile? null : <RubricsMenu />}
      </div>
      {
        props.breadcrumbs ?
          <div className='page-layout__breadcrumbs'>
            <div className='container'>
              <Breadcrumbs breadcrumbs={props.breadcrumbs} skipRoot={props.skipRootBreadcrumb} />
            </div>
          </div>
          : null
      }
      <div className='page-layout__content'>
        {props.children}
      </div>
      {
        props.hideFooter ?
          null
          :
          <div className='page-layout__footer'>
            <Footer />
          </div>
      }
    </div>
  );
}
const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(Layout);
