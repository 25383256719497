import React, {useEffect, useState} from 'react';
import './style.scss';
import ImgBox from '../../ImgBox';

const ProductShowImages = ({mainImage, images}) => {
  const
    [image, setImage] = useState(mainImage),
    [currentMainImage, setCurrentMainImage] = useState(mainImage),

  handleImageChange = (img) => () => {
    setImage(img);
  }

  useEffect(() => {
    if (mainImage?.id == currentMainImage?.id) { return; }
    setImage(mainImage);
    setCurrentMainImage(mainImage);
  }, [mainImage?.id, currentMainImage?.id, setImage]);

  return (
    <div className='product-show-images'>
      <div className='product-show-images__preview-scroll'>
        {
          images.length ?
            <div className='product-show-images__preview'>
              {
                images.map((img) => (
                  <ImgBox
                    className={img.id === image.id ? 'active' : ''}
                    key={img.id}
                    url={img.small}
                    onClick={handleImageChange(img)}
                    width={80}
                    height={80}
                  />)
                )
              }
            </div>
            : null
        }
      </div>
      <div className='product-show-images__output'>
        <div className='product-show-images__output-bg' />
        {image ? <img src={image.image} /> : null}
      </div>
    </div>
  );
}

export default ProductShowImages;
