import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {dateFormat} from '../../../tools/formatters';
import _ from 'lodash'
import StarIcon from '../../Icons/Star';

import './style.scss';
import './mobile.scss';

const ReviewsList = ({reviews, applicationState, className = '', ...props}) => {
  const rev = props.limit ? reviews.slice(0, props.limit) : reviews;
  return (
    <div className={`reviews-list ${className}`}>
      {
        rev.map((review) => {
          return (
            <div key={review.id} className='reviews-list__item'>
              <div className='reviews-list__item-top'>
                <div className='reviews-list__item-top-left'>
                  <div className='reviews-list__item-author'>{review.author}</div>
                  <div className='reviews-list__item-date'>{dateFormat(review.created_at)}</div>
                </div>
                <div className='reviews-list__item-stars'>
                  {_.times(review.stars, (i) => <StarIcon key={i} />)}
                </div>
              </div>
              <div className='reviews-list__item-content'>
                {review.comment}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(ReviewsList);

