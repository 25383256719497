import React from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {textKeysReplacement} from '../../tools/helpers';

const SeoTags = ({seo_context = {}, ...props}) => {
    if (typeof window === 'undefined') { return null; }

    const
      {page_settings, default_seo_context, current_url_without_params, current_url, image, site_name} = props.applicationState,
      defaultSettings = page_settings.find(setting => setting.is_default),
      setting = props.controller_action ? (page_settings.find(setting => setting.controller_action_name === props.controller_action) || defaultSettings) : defaultSettings;

    if (!setting) { return null; }

    const
      {
        title,
        description,
        keywords
      } = setting,
      {href} = location,
      seoTitle = textKeysReplacement(title, {...default_seo_context, ...seo_context}),
      seoDescription = textKeysReplacement(description, {...default_seo_context, ...seo_context}),
      seoKeywords = textKeysReplacement(keywords, {...default_seo_context, ...seo_context});

    return (
      <Helmet>
        <title>{seoTitle}</title>
        <meta name='description' content={seoDescription} />
        <meta name='keywords' content={seoKeywords} />
        <link rel='canonical' href={current_url_without_params} />
        <meta property='og:title' content={seoTitle} />
        <meta property='og:description' content={seoDescription} />
        <meta property='og:url' content={current_url} />
        <meta property='og:image' content={image['_']} />
        <meta property='og:image:width' content={image['width']} />
        <meta property='og:image:height' content={image['height']} />
        <meta property='og:site_name' content={site_name} />
        <meta property='og:type' content="website" />
        <meta property='og:see_also' content={current_url} />
        <meta name='twitter:title' content={seoTitle} />
        <meta name='twitter:description' content={seoDescription} />
        <meta name='twitter:url' content={current_url} />
        <meta name='twitter:image' content={image['_']} />
        <meta name="twitter:site_name" content="1-sc.ru" />
        <meta name="twitter:type" content="website" />
        <meta name="twitter:url" content={current_url} />
        <meta name="twitter:see_also" content={site_name} />
        <meta name="twitter:image" content={image['_']} />
        <meta name="twitter:image:width" content={image['width']} />
        <meta name="twitter:image:height" content={image['height']} />
        <meta name="twitter:card" content="summary" />
        <meta name="theme-color" content="#deeaf1" />
      </Helmet>
    );
  },

  mapStateToProps = ({applicationState}) => {
    return {applicationState};
  },

  withConnect = connect(
    mapStateToProps,
    null
  );

export default compose(withConnect)(SeoTags);
