import React, {useState, useEffect, memo} from 'react';
import {InputNumber} from 'antd';

interface Props {
  max: number
  min?: number
  quantity: number
  disabled: boolean
  onChange: (val: number) => void
  className?: string
  style?: React.CSSProperties
}

let updateTimeout: ReturnType<typeof setTimeout>;

const QtyInput = (props: Props): JSX.Element => {
    const
      {onChange, max, min, quantity, disabled} = props,
      [qty, setQty] = useState(quantity),
      handleChange = (value) => {
        const val = Number(value);
        setQty(val);

        clearTimeout(updateTimeout);
        updateTimeout = setTimeout(() => {
          if (max && val > max) {
            setQty(max);
            onChange(max);
            return null;
          }

          if (val > 0 && quantity !== val) {
            onChange(val);
            return null;
          }

          if (!val || val < 0) {
            setQty(1);
            onChange(1);
            return null;
          }
        }, 1500);
      };

    useEffect(() => {
      if (quantity !== qty) {
        setQty(quantity);
      }
    }, [quantity]);

    return (
      <InputNumber
        className={props.className || ''}
        style={props.style || {}}
        controls={false}
        disabled={disabled}
        value={qty}
        min={min}
        max={max}
        onChange={handleChange}
      />
    );
  },

  areEqual = (prevProps: Props, nextProps: Props) => {
    return prevProps.quantity === nextProps.quantity;
  };

export default memo(QtyInput, areEqual);
