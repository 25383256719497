const actions = {
  SET_CURRENT_ROUTE: 'SET_CURRENT_ROUTE',
  RESET_REDIRECT: 'RESET_REDIRECT',
  SET_REDIRECT: 'SET_REDIRECT',

  SET_FINAL_LOGO: 'SET_FINAL_LOGO',

  FETCH_HOME_PAGE_CONTENT: 'FETCH_HOME_PAGE_CONTENT',
  FETCH_HOME_PAGE_CONTENT_SUCCESS: 'FETCH_HOME_PAGE_CONTENT_SUCCESS',
  FETCH_HOME_PAGE_CONTENT_FAILED: 'FETCH_HOME_PAGE_CONTENT_FAILED',

  FETCH_RUBRIC: 'FETCH_RUBRIC',
  FETCH_RUBRIC_SUCCESS: 'FETCH_RUBRIC_SUCCESS',
  FETCH_RUBRIC_FAILED: 'FETCH_RUBRIC_FAILED',
  RESET_RUBRIC: 'RESET_ROOT_RUBRIC',

  FETCH_RUBRIC_BRANDS: 'FETCH_RUBRIC_BRANDS',
  FETCH_RUBRIC_BRANDS_SUCCESS: 'FETCH_RUBRIC_BRANDS_SUCCESS',
  FETCH_RUBRIC_BRANDS_FAILED: 'FETCH_RUBRIC_BRANDS_FAILED',

  FETCH_RUBRIC_STOCKS: 'FETCH_RUBRIC_STOCKS',
  FETCH_RUBRIC_STOCKS_SUCCESS: 'FETCH_RUBRIC_STOCKS_SUCCESS',
  FETCH_RUBRIC_STOCKS_FAILED: 'FETCH_RUBRIC_STOCKS_FAILED',

  FETCH_RUBRIC_BEST_OFFERS: 'FETCH_RUBRIC_BEST_OFFERS',
  FETCH_RUBRIC_BEST_OFFERS_SUCCESS: 'FETCH_RUBRIC_BEST_OFFERS_SUCCESS',
  FETCH_RUBRIC_BEST_OFFERS_FAILED: 'FETCH_RUBRIC_BEST_OFFERS_FAILED',

  FETCH_ROOT_RUBRICS: 'FETCH_ROOT_RUBRICS',
  FETCH_ROOT_RUBRICS_SUCCESS: 'FETCH_ROOT_RUBRICS_SUCCESS',
  FETCH_ROOT_RUBRICS_FAILED: 'FETCH_ROOT_RUBRICS_FAILED',
  RESET_ROOT_RUBRICS: 'RESET_ROOT_RUBRICS',

  FETCH_RUBRICS_TREE: 'FETCH_RUBRICS_TREE',
  FETCH_RUBRICS_TREE_SUCCESS: 'FETCH_RUBRICS_TREE_SUCCESS',
  FETCH_RUBRICS_TREE_FAILED: 'FETCH_RUBRICS_TREE_FAILED',
  RESET_RUBRICS_TREE: 'RESET_RUBRICS_TREE',

  FETCH_CITIES: 'FETCH_CITIES',
  FETCH_CITIES_SUCCESS: 'FETCH_CITIES_SUCCESS',
  FETCH_CITIES_FAILED: 'FETCH_CITIES_FAILED',

  SET_CURREN_CITY: 'SET_CURREN_CITY',
  SET_CURREN_CITY_SUCCESS: 'SET_CURREN_CITY_SUCCESS',
  SET_CURREN_CITY_FAILED: 'SET_CURREN_CITY_FAILED',

  FETCH_PRODUCTS: 'FETCH_PRODUCTS',
  FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_FAILED: 'FETCH_PRODUCTS_FAILED',
  RESET_PRODUCTS: 'RESET_PRODUCTS',

  FETCH_ANALOGUE_PRODUCTS: 'FETCH_ANALOGUE_PRODUCTS',
  FETCH_ANALOGUE_PRODUCTS_SUCCESS: 'FETCH_ANALOGUE_PRODUCTS_SUCCESS',
  FETCH_ANALOGUE_PRODUCTS_FAILED: 'FETCH_ANALOGUE_PRODUCTS_FAILED',
  RESET_ANALOGUE_PRODUCTS: 'RESET_ANALOGUE_PRODUCTS',

  FETCH_INTERESTING_PRODUCTS: 'FETCH_INTERESTING_PRODUCTS',
  FETCH_INTERESTING_PRODUCTS_SUCCESS: 'FETCH_INTERESTING_PRODUCTS_SUCCESS',
  FETCH_INTERESTING_PRODUCTS_FAILED: 'FETCH_INTERESTING_PRODUCTS_FAILED',
  RESET_INTERESTING_PRODUCTS: 'RESET_INTERESTING_PRODUCTS',

  FETCH_PRODUCTS_COUNT: 'FETCH_PRODUCTS_COUNT',
  FETCH_PRODUCTS_COUNT_SUCCESS: 'FETCH_PRODUCTS_COUNT_SUCCESS',
  FETCH_PRODUCTS_COUNT_FAILED: 'FETCH_PRODUCTS_COUNT_FAILED',
  RESET_PRODUCTS_COUNT: 'RESET_PRODUCTS_COUNT',

  FETCH_PRODUCT_REVIEWS: 'FETCH_PRODUCT_REVIEWS',
  FETCH_PRODUCT_REVIEWS_SUCCESS: 'FETCH_PRODUCT_REVIEWS_SUCCESS',
  FETCH_PRODUCT_REVIEWS_FAILED: 'FETCH_PRODUCT_REVIEWS_FAILED',
  RESET_PRODUCT_REVIEWS: 'RESET_PRODUCT_REVIEWS',

  FETCH_PRODUCT: 'FETCH_PRODUCT',
  FETCH_PRODUCT_SUCCESS: 'FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_FAILED: 'FETCH_PRODUCT_FAILED',
  RESET_CURRENT_PRODUCT: 'RESET_CURRENT_PRODUCT',

  FETCH_PRODUCT_BRANCH_STATS: 'FETCH_PRODUCT_BRANCH_STATS',
  FETCH_PRODUCT_BRANCH_STATS_SUCCESS: 'FETCH_PRODUCT_BRANCH_STATS_SUCCESS',
  FETCH_PRODUCT_BRANCH_STATS_FAILED: 'FETCH_PRODUCT_BRANCH_STATS_FAILED',

  SET_PRODUCTS_PAGINATION: 'SET_PRODUCTS_PAGINATION',
  RESET_PRODUCTS_PAGINATION: 'RESET_PRODUCTS_PAGINATION',
  UPDATE_PRODUCTS_PAGINATION: 'UPDATE_PRODUCTS_PAGINATION',
  UPDATE_PRODUCTS_SORT: 'UPDATE_PRODUCTS_SORT',
  SET_PRODUCTS_FILTERS: 'SET_PRODUCTS_FILTERS',
  SET_PRODUCTS_FILTER: 'SET_PRODUCTS_FILTER',
  APPLY_PRODUCTS_FILTERS: 'APPLY_PRODUCTS_FILTERS',
  RESET_PRODUCTS_FILTERS: 'RESET_PRODUCTS_FILTERS',

  FETCH_PRODUCTS_FILTERS_CONTEXT: 'FETCH_PRODUCTS_FILTERS_CONTEXT',
  FETCH_PRODUCTS_FILTERS_CONTEXT_SUCCESS: 'FETCH_PRODUCTS_FILTERS_CONTEXT_SUCCESS',
  FETCH_PRODUCTS_FILTERS_CONTEXT_FAILED: 'FETCH_PRODUCTS_FILTERS_CONTEXT_FAILED',
  RESET_PRODUCTS_FILTERS_CONTEXT: 'RESET_PRODUCTS_FILTERS_CONTEXT',
  CHANGE_PRODUCTS_SORT: 'CHANGE_PRODUCTS_SORT',
  RESET_PRODUCTS_SORT: 'RESET_PRODUCTS_SORT',

  FETCH_SEARCH_QUERY_PRODUCTS: 'FETCH_SEARCH_QUERY_PRODUCTS',
  FETCH_SEARCH_QUERY_PRODUCTS_SUCCESS: 'FETCH_SEARCH_QUERY_PRODUCTS_SUCCESS',
  FETCH_SEARCH_QUERY_PRODUCTS_FAILED: 'FETCH_SEARCH_QUERY_PRODUCTS_FAILED',
  RESET_SEARCH_QUERY_PRODUCTS: 'RESET_SEARCH_QUERY_PRODUCTS',
  CHANGE_PRODUCTS_SEARCH_QUERY: 'CHANGE_PRODUCTS_SEARCH_QUERY',
  RESET_PRODUCTS_SEARCH_QUERY: 'RESET_PRODUCTS_SEARCH_QUERY',

  // Search
  FETCH_SEARCH_SUGGESTIONS: 'FETCH_SEARCH_SUGGESTIONS',
  FETCH_SEARCH_SUGGESTIONS_SUCCESS: 'FETCH_SEARCH_SUGGESTIONS_SUCCESS',
  FETCH_SEARCH_SUGGESTIONS_FAILED: 'FETCH_SEARCH_SUGGESTIONS_FAILED',
  RESET_SEARCH_SUGGESTIONS: 'RESET_SEARCH_SUGGESTIONS',

  // cart actions
  FETCH_CART: 'FETCH_CART',
  FETCH_CART_SUCCESS: 'FETCH_CART_SUCCESS',
  FETCH_CART_FAILED: 'FETCH_CART_FAILED',

  ADD_TO_CART: 'ADD_TO_CART',
  ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
  ADD_TO_CART_FAILED: 'ADD_TO_CART_FAILED',

  SET_CART_POSITION_QTY: 'SET_CART_POSITION_QTY',
  SET_CART_POSITION_QTY_SUCCESS: 'SET_CART_POSITION_QTY_SUCCESS',
  SET_CART_POSITION_QTY_FAILED: 'SET_CART_POSITION_QTY_FAILED',

  CHANGE_CART_POSITION: 'CHANGE_CART_POSITION',
  CHANGE_CART_POSITION_SUCCESS: 'CHANGE_CART_POSITION_SUCCESS',
  CHANGE_CART_POSITION_FAILED: 'CHANGE_CART_POSITION_FAILED',

  REMOVE_CART_POSITIONS: 'REMOVE_CART_POSITIONS',
  REMOVE_CART_POSITIONS_SUCCESS: 'REMOVE_CART_POSITIONS_SUCCESS',
  REMOVE_CART_POSITIONS_FAILED: 'REMOVE_CART_POSITIONS_FAILED',

  SET_CART_POSITIONS_FOR_ORDER: 'SET_CART_POSITIONS_FOR_ORDER',
  SET_CART_POSITIONS_FOR_ORDER_SUCCESS: 'SET_CART_POSITIONS_FOR_ORDER_SUCCESS',
  SET_CART_POSITIONS_FOR_ORDER_FAILED: 'SET_CART_POSITIONS_FOR_ORDER_FAILED',

  CART_SEND_ORDER: 'CART_SEND_ORDER',
  CART_SEND_ORDER_SUCCESS: 'CART_SEND_ORDER_SUCCESS',
  CART_SEND_ORDER_FAILED: 'CART_SEND_ORDER_FAILED',
  SET_ORDER_ERRORS: 'SET_ORDER_ERRORS',
  RESET_ORDER_ERRORS: 'RESET_ORDER_ERRORS',
  RESET_SUCCESS_ORDER: 'RESET_SUCCESS_ORDER',

  FETCH_POPULAR_BRANDS: 'FETCH_POPULAR_BRANDS',
  FETCH_POPULAR_BRANDS_SUCCESS: 'FETCH_POPULAR_BRANDS_SUCCESS',
  FETCH_POPULAR_BRANDS_FAILED: 'FETCH_POPULAR_BRANDS_FAILED',
  RESET_POPULAR_BRANDS: 'RESET_POPULAR_BRANDS',

  FETCH_BRANDS: 'FETCH_BRANDS',
  FETCH_BRANDS_SUCCESS: 'FETCH_BRANDS_SUCCESS',
  FETCH_BRANDS_FAILED: 'FETCH_BRANDS_FAILED',
  RESET_BRANDS: 'RESET_BRANDS',

  FETCH_BRAND: 'FETCH_BRAND',
  FETCH_BRAND_SUCCESS: 'FETCH_BRAND_SUCCESS',
  FETCH_BRAND_FAILED: 'FETCH_BRAND_FAILED',
  RESET_CURRENT_BRAND: 'RESET_CURRENT_BRAND',

  FETCH_BRAND_RUBRICS: 'FETCH_BRAND_RUBRICS',
  FETCH_BRAND_RUBRICS_SUCCESS: 'FETCH_BRAND_RUBRICS_SUCCESS',
  FETCH_BRAND_RUBRICS_FAILED: 'FETCH_BRAND_RUBRICS_FAILED',
  RESET_BRAND_RUBRICS: 'RESET_BRAND_RUBRICS',

  FETCH_BRAND_PRODUCTS: 'FETCH_BRAND_PRODUCTS',
  FETCH_BRAND_PRODUCTS_SUCCESS: 'FETCH_BRAND_PRODUCTS_SUCCESS',
  FETCH_BRAND_PRODUCTS_FAILED: 'FETCH_BRAND_PRODUCTS_FAILED',
  RESET_BRAND_PRODUCTS: 'RESET_BRAND_PRODUCTS',

  FETCH_ARTICLES: 'FETCH_ARTICLES',
  FETCH_ARTICLES_SUCCESS: 'FETCH_ARTICLES_SUCCESS',
  FETCH_ARTICLES_FAILED: 'FETCH_ARTICLES_FAILED',
  RESET_ARTICLES: 'RESET_ARTICLES',

  FETCH_ARTICLE: 'FETCH_ARTICLE',
  FETCH_ARTICLE_SUCCESS: 'FETCH_ARTICLE_SUCCESS',
  FETCH_ARTICLE_FAILED: 'FETCH_ARTICLE_FAILED',
  RESET_ARTICLE: 'RESET_ARTICLE',

  FETCH_EVENTS: 'FETCH_EVENTS',
  FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILED: 'FETCH_EVENTS_FAILED',
  RESET_EVENTS: 'RESET_EVENTS',

  FETCH_EVENT: 'FETCH_EVENT',
  FETCH_EVENT_SUCCESS: 'FETCH_EVENT_SUCCESS',
  FETCH_EVENT_FAILED: 'FETCH_EVENT_FAILED',
  RESET_EVENT: 'RESET_EVENT',

  FETCH_STOCKS: 'FETCH_STOCKS',
  FETCH_STOCKS_SUCCESS: 'FETCH_STOCKS_SUCCESS',
  FETCH_STOCKS_FAILED: 'FETCH_STOCKS_FAILED',
  RESET_STOCKS: 'RESET_STOCKS',

  FETCH_STOCK: 'FETCH_STOCK',
  FETCH_STOCK_SUCCESS: 'FETCH_STOCK_SUCCESS',
  FETCH_STOCK_FAILED: 'FETCH_STOCK_FAILED',
  RESET_STOCK: 'RESET_STOCK',
  FETCH_STOCKS_PRODUCTS: 'FETCH_STOCKS_PRODUCTS',
  FETCH_STOCKS_PRODUCTS_SUCCESS: 'FETCH_STOCKS_PRODUCTS_SUCCESS',
  FETCH_STOCKS_PRODUCTS_FAILED: 'FETCH_STOCKS_PRODUCTS_FAILED',
  RESET_STOCKS_PRODUCTS: 'RESET_STOCKS_PRODUCTS',

  FETCH_STOCK_PRODUCTS: 'FETCH_STOCK_PRODUCTS',
  FETCH_STOCK_PRODUCTS_SUCCESS: 'FETCH_STOCK_PRODUCTS_SUCCESS',
  FETCH_STOCK_PRODUCTS_FAILED: 'FETCH_STOCK_PRODUCTS_FAILED',
  RESET_STOCK_PRODUCTS: 'FETCH_STOCK_PRODUCTS',

  // User requests

  SEND_SUBSCRIBE_FORM: 'SEND_SUBSCRIBE_FORM',
  SEND_SUBSCRIBE_FORM_SUCCESS: 'SEND_SUBSCRIBE_FORM_SUCCESS',
  SEND_SUBSCRIBE_FORM_FAILED: 'SEND_SUBSCRIBE_FORM_FAILED',
  CHANGE_SUBSCRIBE_FORM: 'CHANGE_SUBSCRIBE_FORM',

  SEND_QUESTION_FORM: 'SEND_QUESTION_FORM',
  SEND_QUESTION_FORM_SUCCESS: 'SEND_QUESTION_FORM_SUCCESS',
  SEND_QUESTION_FORM_FAILED: 'SEND_QUESTION_FORM_FAILED',
  CHANGE_QUESTION_FORM: 'CHANGE_QUESTION_FORM',
};

export default actions;
