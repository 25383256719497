import React from 'react';
import './style.scss';
import _ from 'lodash';
const Paginator = ({page, per_page, total, pages, ...props}) => {
  const handlePageClick = (num) => (e) => {
    props.onChange(num, per_page);
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <div className='paginator__wrapper'>
      <div className='paginator__buttons'>
        {
          _.times(pages).map((num) => {
            const pageNum = num + 1;
            return pageNum === page ?
              (
                <span key={pageNum} className='paginator__button paginator__button_current'>{pageNum}</span>
              )
            : (
              <button
                key={pageNum}
                onClick={handlePageClick(pageNum)}
                className='paginator__button'
              >
                {pageNum}
              </button>
              )
          })
        }
      </div>
    </div>
  );

}
export default Paginator;
