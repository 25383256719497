import React, {useRef, useState} from 'react';
import Button from '../Button';
import {Drawer} from 'antd';
import CloseIcon from '../Icons/Close';
import {connect} from 'react-redux';
import {compose} from 'redux';

import './style.scss';
import './mobile.scss';
const TextSnippet = (props) => {
  const
    {is_mobile} = props.applicationState,
    textOutRef = useRef(null),
    [open, setOpen] = useState(false),
    [drawerOpen, setDrawerOpen] = useState(false),
    [style, setStyle] = useState({maxHeight: '78px'}),
    handleCloseDrawer = () => {
    setDrawerOpen(false)
    },
    handleToggle = () => {
      if (is_mobile) {
        setDrawerOpen(true);
        return
      }
      if (!open) {
        setOpen(true);
        setStyle({maxHeight: `${textOutRef.current.clientHeight || 200}px`});
      } else {
        setOpen(false);
        setStyle({maxHeight: '78px'});
      }

    };
  return (
    <div className='text-snippet'>
      <div className={`text-snippet__wrapper ${open ? 'active' : ''}`} style={style}>
        <div className={`text-snippet__out html-content`} ref={textOutRef} dangerouslySetInnerHTML={{__html: props.text}} />
      </div>
      <Button
        secondary
        onClick={handleToggle}
      >
        Читать далее
      </Button>
      <Drawer
        className='select-drawer text-drawer'
        getContainer='body'
        height='auto'
        placement='bottom'
        onClose={handleCloseDrawer}
        title={props.drawerTitle}
        closeIcon={<CloseIcon />}
        footer={<Button secondary onClick={handleCloseDrawer} className='w-full'>Закрыть</Button>}
        open={drawerOpen}
      >
        <div className='burger-menu-drawer__body'>
          <h2>{props.drawerBodyHeader}</h2>
          <div className={`html-content`}dangerouslySetInnerHTML={{__html: props.text}} />
        </div>
      </Drawer>
    </div>
  );
}

const mapStateToProps = ({applicationState, rubricsState}) => {
    return {
      applicationState,
      rubricsState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(TextSnippet);
