import {applyMiddleware, compose, createStore, combineReducers} from 'redux';

import _ from 'lodash';
import reducers, {initialStates} from '../reducers';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import RootSaga from '../sagas';
import Offer from '../decorators/offer';


export default (props, railsContext) => {
  const ps = props?.productsState;

  if (ps) {
    if (ps?.currentProduct) {
      ps.currentProduct = new Offer(ps.currentProduct);
    }
    if (ps?.collection?.length) {
      ps.collection = ps.collection.map(offer => new Offer(offer));
    }
    if (ps?.interestsProducts?.length) {
      ps.interestsProducts = ps.interestsProducts.map(offer => new Offer(offer));
    }
  }

  const initialState = {
      ..._.merge(JSON.parse(JSON.stringify(initialStates)), props),
      railsContext,
    },

    sagaMiddleware = createSagaMiddleware(),
    reduxMiddlewares = [];

  reduxMiddlewares.push(sagaMiddleware);

  if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
    reduxMiddlewares.push(logger);
  }

  const reducer = combineReducers({
      ...reducers
    }),

    store = createStore(reducer, initialState, compose(applyMiddleware(...reduxMiddlewares)));
  sagaMiddleware.run(RootSaga);
  return store;
};
