import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import './style.scss';
import './mobile.scss';

const BrandsPreviewList = ({mobileInline = true, brands, ...props}) => {
  return (
    <ul className={`brands-list ${mobileInline ? 'brands-list_mobile-inline' : ''} ${props.className ? props.className : ''}`}>
      {
        brands.map((brand) => {
          return (
            <li className='brands-list__item' key={brand.id} onClick={(e) => props.onClick && props.onClick(e, brand)}>
              <Link to={constants.ROUTES.REGIONAL_BRAND_PATH(props.applicationState.current_city.slug, brand.id_with_slug)}>
                <div className='brands-list__item-bg' />
                {
                  brand.logo ? <img src={brand.logo.small} alt={brand.logo.alt || brand.title}/> : brand.title
                }
              </Link>
            </li>
          )
        })
      }
    </ul>
  );
};

export const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(BrandsPreviewList);
