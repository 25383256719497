import React from 'react';

const GiftIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM11.875 12.7083C12.3352 12.7083 12.7083 12.3352 12.7083 11.875V9.16667C12.7083 8.70643 13.0814 8.33333 13.5417 8.33333H15.625C18.0412 8.33333 20 10.2921 20 12.7083C20 10.2921 21.9588 8.33333 24.375 8.33333H26.4583C26.9186 8.33333 27.2917 8.70643 27.2917 9.16667V11.875C27.2917 12.3352 27.6648 12.7083 28.125 12.7083H30.8333C31.2936 12.7083 31.6667 13.0814 31.6667 13.5417V16.25C31.6667 16.7102 31.2936 17.0833 30.8333 17.0833H9.16667C8.70643 17.0833 8.33333 16.7102 8.33333 16.25V13.5417C8.33333 13.0814 8.70643 12.7083 9.16667 12.7083H11.875ZM9.79167 20.8333C9.79167 20.3731 10.1648 20 10.625 20H17.7083C18.1686 20 18.5417 20.3731 18.5417 20.8333V29.375C18.5417 29.8352 18.1686 30.2083 17.7083 30.2083H10.625C10.1648 30.2083 9.79167 29.8352 9.79167 29.375V20.8333ZM29.375 20C29.8352 20 30.2083 20.3731 30.2083 20.8333V29.375C30.2083 29.8352 29.8352 30.2083 29.375 30.2083H22.2917C21.8314 30.2083 21.4583 29.8352 21.4583 29.375V20.8333C21.4583 20.3731 21.8314 20 22.2917 20H29.375Z" fill="#1536A1"/>
    </svg>
  );
}

export default GiftIcon;
