import React from 'react';
import {Link} from 'react-router-dom';
import constants from '../../../constants';
import QtyChange from '../QtyChange';
import {priceWithCurrency} from '../../../tools/formatters';
import CustomCheckbox from '../../Checkbox';
import ImgBox from '../../ImgBox';
import CloseIcon from '../../Icons/Close';

import './style.scss';
import './mobile.scss';

const CartPosition = ({position, type, city_slug, ...props}) => {
  const
    {offer} = position,
    {actions} = props,
    isAvailable = type === 'available',
    imgSize = props.is_mobile ? 88 : 150,
    handleQtyChange = (val) => {
      actions.setCartPositionQty(offer.id, val, position.unit_id, type);
    },
    handleForOrderChanged = (val) => {
      props.onSelectForOrder(position.id, val);
    },
    handlePositionUnitChange = (val) => {
      actions.changeCartPosition(offer.id, {unit_id: val})
    },
    handleRemovePosition = () => {
      actions.removeCartPositions([offer.id], type);
    },
    renderPrice = (value) => {
      return <div>
        {isAvailable ? '' : 'от'}
        {priceWithCurrency(value)}
        <span className='price-units'>/{position.requestedUnit?.name}</span>
      </div>;
    },
    renderPriceCol = () => {
      return !isAvailable ? (<div className='cart-position__unavailable-price'>Раскупили</div>) : (
        <div className='cart-position__price'>
          {
            offer.new_price ?
              <>
                {renderPrice(position.newPriceInUnits)}
                <div className='old-price decoration-line-through'>{renderPrice(position.priceInUnits)}</div>
              </>
              : renderPrice(position.priceInUnits)
          }
        </div>
      );
    };
  return (
    <div className={`cart-position cart-position_${type}`}>
      <div className='cart-position__col cart-position__col_checkbox'>
        <CustomCheckbox
          checked={props.selected}
          onChange={handleForOrderChanged}
        />
      </div>
      <div className='cart-position__col cart-position__col_img'>
        <Link to={constants.ROUTES.REGIONAL_PRODUCT_PATH(city_slug, offer.rubric_path, offer.id_with_slug)} className='cart-position__image'>
          <ImgBox url={offer.image.big_image_url} width={imgSize} height={imgSize} alt={offer.name} />
        </Link>
      </div>
      <div className='cart-position__col_right'>
        <div className='cart-position__col cart-position__col_description'>
          { props.is_mobile ? renderPriceCol() :null }
          <div className='cart-position__offer-name'>{offer.name}</div>
          <div className='cart-position__brand-name'>{offer.brand.title}</div>

          {/*<div className={`cart-position__reminder-state ${isAvailable ? 'color-success' : 'color-error'}`}>*/}
          {/*  {*/}
          {/*    isAvailable ? <>*/}
          {/*      в наличии {position.reminderStrInRequestedUnits}</> : 'под заказ'*/}
          {/*  }*/}
          {/*</div>*/}
        </div>
        {
          !props.is_mobile ?
            <div className='cart-position__col cart-position__col_qty'>
              <QtyChange
                type={type}
                record={position}
                handleChange={handleQtyChange}
              />
              {/*<Select*/}
              {/*  options={offer.unitSelectOptions}*/}
              {/*  onChange={handlePositionUnitChange}*/}
              {/*  value={position.unit_id}*/}
              {/*/>*/}
            </div>
            : null
        }
        <div className='cart-position__col cart-position__col_last'>
          { props.is_mobile ?
            <QtyChange
              type={type}
              record={position}
              handleChange={handleQtyChange}
            />
            : renderPriceCol()
          }
          <div className='cart-position__actions'>
            <button onClick={handleRemovePosition}><CloseIcon opacity='0.2'/></button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CartPosition;
