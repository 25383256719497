import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as applicationActions from '../actions/applicationActionCreators';

const {API, ACTIONS} = constants;

export const fetchCountiesRequest = () => RequestTools.ajax({url: API.CITIES_PATH});
export const fetchHomeContentRequest = () => RequestTools.ajax({url: API.HOME_CONTENT_PATH});
export const fetchHomeOffersRequest = () => RequestTools.ajax({url: API.HOME_BEST_SELLERS_PATH});
export const fetchHomeTopSlidesRequest = () => RequestTools.ajax({url: API.HOME_TOP_SLIDES});
export const fetchHomeTopMobileSlidesRequest = () => RequestTools.ajax({url: API.HOME_TOP_MOBILE_SLIDES});
export const fetchHomeBuildingsRequest = () => RequestTools.ajax({url: API.HOME_BUILDINGS});
export const fetchHomeBottomSlidesRequest = () => RequestTools.ajax({url: API.HOME_BOTTOM_SLIDES});
export const fetchHomeRubricsRequest = (params) => RequestTools.ajax({url: API.RUBRICS_HOME_PATH, params});
export const fetchStocksRequest = (params) => RequestTools.ajax({url: API.STOCKS_PATH, params});
export const fetchArticlesRequest = (params) => RequestTools.ajax({url: API.ARTICLES_PATH, params});

export function * fetchCities() {
  try {
    const response = yield call(fetchCountiesRequest);

    yield put(applicationActions.fetchCitiesSuccess(response.data));
  } catch (e) {
    yield put(applicationActions.fetchCitiesFailed());
  }
}

export function * fetchHomeContent() {
  const {applicationState} = yield select();
  try {
    const
      contentResponse = yield call(fetchHomeContentRequest),
      bestSellersResponse = yield call(fetchHomeOffersRequest),
      topSliderResponse = yield call(applicationState.is_mobile ? fetchHomeTopMobileSlidesRequest : fetchHomeTopSlidesRequest),
      // buildingsResponse = yield call(fetchHomeBuildingsRequest),
      bottomSliderResponse = yield call(fetchHomeBottomSlidesRequest),
      rubricsResponse = yield call(fetchHomeRubricsRequest),
      stocksResponse = yield call(fetchStocksRequest, {per_page: 4}),
      articlesResponse = yield call(fetchArticlesRequest, {per_page: 5});
      

    yield put(applicationActions.fetchHomePageContentSuccess({
      content: contentResponse.data,
      bestSellers: bestSellersResponse.data,
      topSlides: topSliderResponse.data,
      bottomSlides: bottomSliderResponse.data,
      buildings: [], // buildingsResponse.data,
      rubrics: rubricsResponse.data,
      articles: articlesResponse.data,
      stocks: stocksResponse.data,
    }));
  } catch (e) {
    yield put(applicationActions.fetchHomePageContentFailed());
  }
}

function * applicationSaga() {
  yield takeEvery(ACTIONS.FETCH_CITIES, fetchCities);
  yield takeEvery(ACTIONS.FETCH_HOME_PAGE_CONTENT, fetchHomeContent);
}

export default applicationSaga;
