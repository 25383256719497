import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as articlesActions from '../../actions/articlesActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useMount, useUnmount} from 'react-use';
import {extractBrandId} from '../../routes/routes';
import ArticleShow from '../../components/Articles/Show';
import ProductsList from '../../components/ProductsList';
import SeoTags from '../../routes/SeoTags';
import {textKeysReplacement} from '../../tools/helpers';

const StockPage = (props) => {
  const
    [currentPath, setCurrentPath] = useState(null),
    stockPath = props.match.params.id,
    currentStock = props.articlesState.currentStock,
    {is_mobile, current_city} = props.applicationState;

  useEffect(() => {
    if (stockPath === currentPath) return;
    const stockId = extractBrandId(stockPath);
    props.articlesActions.fetchStock(stockId);
    setCurrentPath(stockPath);
  }, [stockPath, props.articlesActions.fetchStock]);

  useUnmount(() => {
    props.articlesActions.resetStock();
  });

  return (
    <Layout
      className='stock-page'
      breadcrumbs={[
        {label: 'Акции', path: constants.ROUTES.REGIONAL_STOCKS_PATH(props.applicationState.current_city.slug)},
        {label: currentStock?.title}
      ]}
    >
      {
        currentStock ?
          <SeoTags
            controller_action='stocks#show'
            seo_context={{
              title: textKeysReplacement(currentStock.seo_title, props.applicationState.default_seo_context) || currentStock.title,
              description: textKeysReplacement(currentStock.seo_description, props.applicationState.default_seo_context),
              keywords: textKeysReplacement(currentStock.seo_keywords, props.applicationState.default_seo_context),
            }}
          />
          : null
      }

      <div className='container'>
        <ArticleShow article={currentStock} applicationState={props.applicationState} />
        {
          currentStock?.offers?.length ?
            <section className='page-content-section'>
              <h3>
                Товары, участвующие в акции
              </h3>
              <ProductsList products={currentStock?.offers} colsCount={6} listClass={ is_mobile ? `mobile-inline` : ''} />
            </section>
            : null
        }
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      articlesActions: bindActionCreators(articlesActions, dispatch)
    };
  },

  mapStateToProps = ({articlesState, railsContext, applicationState}) => {
    return {
      applicationState,
      articlesState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(StockPage));
