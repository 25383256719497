import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './style.scss';
const ImageBox = ({url = null, width, height, alt = 'image', bg = true, className = '', ...props}) => {
  return (
    <span className={`image-box ${className}`} style={{width, height}} {...props}>
      {bg ? <span className='image-box__bg' /> : null}
      {url ? <LazyLoadImage src={url} alt={alt} /> : null}
      {props.children ? <span className='image-box__text' style={{width, height}}>{props.children}</span> : null}
    </span>
  );
}

export default ImageBox;
