import React from 'react';
import {textKeysReplacement} from '../../../tools/helpers';

const ArticleShow = (props) => {
  return (
    <div className='article-content'>
      <h1>{props.article?.title}</h1>
      <div
        className='article-content__body html-content'
        dangerouslySetInnerHTML={{__html: textKeysReplacement(props.article?.body, props.applicationState.default_seo_context)}}
      />
    </div>
  );
}

export default ArticleShow;
