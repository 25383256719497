import React from 'react';
import './style.scss';

const ButtonCheckbox = (props) => {
  const handleClick = () => {
    props.onChange && props.onChange();
  };
  return (
    <button
      className={`button-checkbox ${props.checked ? 'button-checkbox_checked' : ''}`}
      onClick={handleClick}
    >
      {props.children}
    </button>
  );
};

export default ButtonCheckbox;
