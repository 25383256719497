import React, {useState} from 'react';

import './style.scss';
import './mobile.scss';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {Input} from 'antd';
import CloseIcon from '../../Icons/Close';
import InputPhone from '../../InputPhone';
let formTimeout = null;
const OrderUserForm = ({applicationState, data, onChange, ...props}) => {
  const
    [localVal, setLocalVal]:any = useState(data.recipient || {}),

    handleSubmit = (result) => {
      onChange && onChange(result);
    },

    handleChange = (attr, type = 'input') => (event) => {
      const newRecipient = {
        ...localVal,
        [attr]: type === 'boolean' ? event.target.checked : event.target.value
      }

      setLocalVal(newRecipient);

      clearTimeout(formTimeout);

      formTimeout = setTimeout(() => handleSubmit(newRecipient), 500);
    },
    handleContactChanged = (attr) => (value) => {
      const newRecipient = {
        ...localVal,
        [attr]: value
      }

      setLocalVal(newRecipient);

      clearTimeout(formTimeout);

      formTimeout = setTimeout(() => handleSubmit(newRecipient), 500);
    };

  return (
    <div className='form form_user'>
      <div className='form__row form__row_3-cols'>
        <div className='form__field'>
          <div className='form__field-label'>Фамилия</div>
          <div className='form__field-input'>
            <Input value={localVal.last_name} placeholder='Фамилия' onChange={handleChange('last_name')} suffix={<CloseIcon />} />
          </div>
        </div>
        <div className='form__field'>
          <div className='form__field-label'>Имя</div>
          <div className='form__field-input'>
            <Input value={localVal.first_name} placeholder='Фамилия' onChange={handleChange('first_name')} suffix={<CloseIcon />} />
          </div>
        </div>
        <div className='form__field'>
          <div className='form__field-label'>Отчество</div>
          <div className='form__field-input'>
            <Input value={localVal.patronymic} placeholder='Фамилия' onChange={handleChange('patronymic')} suffix={<CloseIcon />} />
          </div>
        </div>
      </div>
      <div className='form__row form__row_2-cols'>
        <div className='form__field'>
          <div className='form__field-label'>Телефон</div>
          <div className='form__field-input'>
            <InputPhone value={localVal.phone} placeholder='Телефон' onChange={handleContactChanged('phone')} />
          </div>
        </div>
        <div className='form__field'>
          <div className='form__field-label'>Почта</div>
          <div className='form__field-input'>
            <Input type='email' value={localVal.email} placeholder='Почта' onChange={handleChange('email')} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(OrderUserForm);

