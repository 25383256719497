import React from 'react';
import {Link} from 'react-router-dom';
import constants from '../../../constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LinesEllipsis from 'react-lines-ellipsis'

import './style.scss';
import './mobile.scss';
import Button from '../../Button';

const ProductsListPlateItem = ({product, city_slug, ...props}) => {
  const
    handleAddToCart = () => {
      props.onAddToCart(product.id);
    },
    discount = product.discountPercents;

  return (
    <div className='products-list-item'>
      <div className='products-list-item__top'>
        <Link to={constants.ROUTES.REGIONAL_PRODUCT_PATH(city_slug, product.rubric_path, product.id_with_slug)} className='products-list-item__image'>
          { product.image ?
            <LazyLoadImage src={product.image.big_image_url} alt={product.image.alt || product.name} effect="blur" />
            : null}
          <div className='products-list-item__image-bg' />
        </Link>

        <div className='products-list-item__labels'>
          {discount ? <div className='products-list-item__label products-list-item__label_discount'>-{product.discountPercents}%</div> : null}
          {product.stock ? <div className='products-list-item__label products-list-item__label_stock'>акция</div> : null}
          {product.hit ? <div className='products-list-item__label products-list-item__label_hit'>хит</div> : null}
          {product.latest ? <div className='products-list-item__label products-list-item__label_new'>NEW</div> : null}
        </div>

        {
          product.price ? (
            product.new_price ?
              <div className='products-list-item__price'>
                <span className='products-list-item__price-number'>
                  {product.new_price_in_format} ₽<span className='products-list-item__price-unit'>/{product.baseUnit?.name}</span>
                </span>
                <span className='products-list-item__old-price-number'>
                  <span className='decoration-line-through'>{product.price_in_format}</span> ₽
                </span>
              </div>
              : <>
                <div className='products-list-item__price'>
                  <span className='products-list-item__price-number'>
                    {product.price_in_format} ₽/<span className='products-list-item__price-unit'>{product.baseUnit?.name}</span>
                  </span>
                </div>
              </>
          ) : null
        }

        <div className='products-list-item__title' title={product.name}>
          <LinesEllipsis
            text={product.name}
            maxLine='2'
            ellipsis='...'
            trimRight
            basedOn='letters'
          />
        </div>
        {/*<div className='products-list-item__price'>*/}
        {/*  <div className='products-list-item__prices'>*/}
        {/*    {*/}
        {/*      product.unitsPrices.map((unitPrice, idx) => {*/}
        {/*        return (*/}
        {/*          <div key={idx} className='products-list-item__prices-item'>*/}
        {/*            {priceWithCurrency(unitPrice.price)}*/}
        {/*            <span> за {unitPrice.unit}</span>*/}
        {/*          </div>*/}
        {/*        )*/}
        {/*      })*/}
        {/*    }*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <div className='products-list-item__bottom'>
        <div className='products-list-item__reminder'>
          <span>В наличии</span>
          {product.reminderStrInBaseUnits}
        </div>
        <div className='products-list-item__actions'>

          {
            props.inCart ?
              <Button primary to={constants.ROUTES.CART} className='sm'>Добавлен</Button>
              : <Button onClick={handleAddToCart} primary className='sm'>В корзину</Button>
          }
        </div>
      </div>
    </div>
  );
}

export default ProductsListPlateItem;
