import React, {useState} from 'react';
import {Drawer} from 'antd';
import CloseIcon from '../../Icons/Close';
import Button from '../../Button';
import InputWithLabel from '../../InpuWithLabel';
import CustomCheckbox from '../../Checkbox';
import {Modal} from 'antd';

const AddressForm = ({open, onClose, onChange, address = {}, mobile = true}) => {
  const
    [localVal, setLocalVal]:any = useState(address),
    generateAddressString = () => {
      const result = [],
        {city, address, flat, entrance, floor, is_house} = localVal;

      city && result.push(city);
      address && result.push(address);

      if (!is_house && flat) {
        result.push(`кв. ${flat}`);
      }

      if (entrance) {
        result.push(`подъезд №${entrance}`);
      }

      if (floor) {
        result.push(`эт. ${floor}`);
      }

      return result.length ? result.join(', ') : null;
    },
    handleSubmit = () => {
      onChange(generateAddressString());
      onClose();
    },
    handleChange = (attr, type = 'input') => (event) => {
      const newAddress = {
        ...localVal,
        [attr]: type === 'boolean' ? event.target.checked : event.target.value
      }

      setLocalVal(newAddress);
    },
    renderForm = () => {
      return (
        <div className='burger-menu-drawer__body'>
          <div className='mobile-form mobile-form_address'>
            <div className='mobile-form__row'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <InputWithLabel value={localVal.city} label='Город' onChange={handleChange('city')} />
                </div>
              </div>
            </div>
            <div className='mobile-form__row'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <InputWithLabel value={localVal.address} label='Адрес' onChange={handleChange('address')} />
                </div>
              </div>
            </div>
            <div className='mobile-form__row mobile-form__row_two-cols'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <InputWithLabel disabled={localVal.is_house} value={localVal.flat} label='Квартира / офис' onChange={handleChange('flat')} />
                </div>
              </div>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <CustomCheckbox
                    onChange={handleChange('is_house', 'boolean')}
                    checked={localVal.is_house}
                  >
                    Частный дом
                  </CustomCheckbox>
                </div>
              </div>
            </div>
            <div className='mobile-form__row'>
              <div className='mobile-form__section-title'>Дополнительная информация</div>
            </div>
            <div className='mobile-form__row mobile-form__row_two-cols'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <InputWithLabel value={localVal.entrance} label='Подъезд' onChange={handleChange('entrance')} />
                </div>
              </div>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <InputWithLabel value={localVal.floor} label='Этаж' onChange={handleChange('floor')} />
                </div>
              </div>
            </div>
            <div className='mobile-form__row mb-4'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <CustomCheckbox
                    onChange={handleChange('save_address', 'boolean')}
                    checked={localVal.save_address}
                  >
                    Сохранить адрес
                  </CustomCheckbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
  return mobile ? (
    <Drawer
      className='select-drawer ant-drawer-bottom-radius'
      getContainer='body'
      height='auto'
      placement='bottom'
      onClose={onClose}
      title='Новый адрес доставки'
      closeIcon={<CloseIcon />}
      footer={<Button onClick={handleSubmit} className='w-full lg'>Сохранить и продолжить</Button>}
      open={open}
    >{renderForm()}</Drawer>
  ) : (
    <Modal
      open={open}
      onCancel={onClose}
      width={600}
      title='Новый адрес доставки'
      closeIcon={<CloseIcon height={20} width={20} />}
      footer={<Button onClick={handleSubmit} className='w-full lg'>Сохранить и продолжить</Button>}
    >{renderForm()}
    </Modal>
  );
}

export default AddressForm;
