import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import './style.scss';
const HeaderContacts = (props) => {
  const {current_city: {phone}} = props.applicationState;
  return (
    <div className='header-contacts'>
      {
        phone ? (
          <div className="phone">
            <span id="ct_company_number"></span>
            <span className="country-code">+7</span>
            <span className="state-code">({phone.code})</span>
            {phone.number}
          </div>
        )
          : (
            <div className="empty-phone">
              <span id="ct_company_number"/>
              <span className="country-code"/>
              <span className="state-code"/>
            </div>
        )
      }
      {/*<a href="#" id="js-call-order-dialog-open">*/}
      {/*  <div className="clearfix">*/}
      {/*    <p>Перезвонить мне</p>*/}
      {/*  </div>*/}
      {/*</a>*/}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
  },

  mapStateToProps = ({applicationState, railsContext}) => {
    return {
      applicationState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(HeaderContacts);
