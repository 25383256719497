import React from 'react';
import {Drawer} from 'antd';
import './style.scss';
import CloseIcon from '../../../Icons/Close';
import CommonWidget from '../../CommonWidget';
import CalcIcon from '../../../Icons/Calc';
import {connect} from 'react-redux';
import {compose} from 'redux';
import constants from '../../../../constants';
const MobileCalculatorsDialog = (props) => {
  const
    city = props.applicationState.current_city,
    citySlug = city.slug;

  return (
    <>
      <Drawer
        className='select-drawer ant-drawer-bottom-radius'
        getContainer='body'
        height='auto'
        placement='bottom'
        onClose={props.onClose}
        title='Калькуляторы'
        closeIcon={<CloseIcon />}
        open={props.open}
      >
        <div className='burger-menu-drawer__body'>
          <div className='product-order-widget__row product-order-widget__group no-bg mb-12'>
            {
              props.calculators.map((calc) => (
                  <CommonWidget
                    icon={<CalcIcon />}
                    title={calc.title}
                    to={constants.ROUTES.REGIONAL_CALCULATORS_PATH(citySlug, calc.slug)}
                  />
              ))
            }
          </div>
        </div>
      </Drawer>
    </>
  )
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(MobileCalculatorsDialog);
