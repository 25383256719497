import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import * as articlesActions from '../../actions/articlesActionCreators';
import {Link, withRouter} from 'react-router-dom';
import Layout from '../../layout/Layout';
import constants from '../../constants';
import {useMount, useUnmount} from 'react-use';
import {extractBrandId} from '../../routes/routes';
import ArticleShow from '../../components/Articles/Show';
import SeoTags from '../../routes/SeoTags';
import {textKeysReplacement} from '../../tools/helpers';

const ArticlePage = (props) => {
  const
    [currentPath, setCurrentPath] = useState(null),
    articlePath = props.match.params.id,
    currentArticle = props.articlesState.currentArticle;

  useEffect(() => {
    if (articlePath === currentPath) return;
    const articleId = extractBrandId(articlePath);
    props.articlesActions.fetchArticle(articleId);
    setCurrentPath(articlePath);
  }, [articlePath, props.articlesActions.fetchArticle]);

  useUnmount(() => {
    props.articlesActions.resetArticle();
  });

  return (
    <Layout
      className='article-page'
      breadcrumbs={[
        {label: 'Статьи', path: constants.ROUTES.REGIONAL_ARTICLES_PATH(props.applicationState.current_city.slug)},
        {label: currentArticle?.title}
      ]}
    >
      {
        currentArticle ?
          <SeoTags
            controller_action='articles#show'
            seo_context={{
              title: textKeysReplacement(currentArticle.seo_title, props.applicationState.default_seo_context) || currentArticle.title,
              description: textKeysReplacement(currentArticle.seo_description, props.applicationState.default_seo_context),
              keywords: textKeysReplacement(currentArticle.seo_keywords, props.applicationState.default_seo_context),
            }}
          />
          : null
      }
      <div className='container'>
        <section className='page-content-section'>
          <ArticleShow article={currentArticle} applicationState={props.applicationState} />
        </section>
      </div>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
      articlesActions: bindActionCreators(articlesActions, dispatch)
    };
  },

  mapStateToProps = ({articlesState, railsContext, applicationState}) => {
    return {
      applicationState,
      articlesState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default withRouter(compose(withConnect)(ArticlePage));
