import React from 'react';
import {Autoplay, Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './style.scss';
import './mobile.scss';
const Slider = ({slides, className=""}) => {
  if (!slides.length) {
    return null;
  }

  // if (slides.length == 1) {
  //   slides = [...slides, ...slides, ...slides];
  // } else if (slides.length < 3) {
  //   slides = [...slides, ...slides];
  // }

  return (
    <div className={`slider ${className.length ? `slider_${className}` : ''}`}>
      <Swiper
        modules={[Navigation, Autoplay]}
        navigation
        loop={false}
        slidesPerView={1}
        autoplay={{delay: 7000}}
      >
        {slides.map((slide) => {
          return (
            <SwiperSlide key={slide.id}>
              {slide.link ?
                <Link className='slide' to={slide.link}><img src={slide.image.big_image_url} /></Link>
                : <div className='slide'><img src={slide.image.big_image_url} /></div>
              }
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
    };
  },

  mapStateToProps = ({applicationState, railsContext}) => {
    return {
      applicationState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(Slider);
