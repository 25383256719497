import React from 'react';
import {Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import './style.scss';

const MobileImages = ({images, className=''}) => {
  if (!images.length) {
    return null;
  }

  return (
    <div className='mobile-product-images'>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true }}
        loop={false}
        slidesPerView={1}
      >
        {images.map((slide) => {
          return (
            <SwiperSlide key={slide.id}>
              <div className='slide'>
                <div className='slide__bg' />
                <img src={slide.big_image_url} alt='' />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
export default MobileImages;
