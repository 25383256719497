import React from 'react';
import DocIcon from '../Icons/Doc';
import './style.scss';

const DocumentsList = ({documents, ...props}) => {
  return (
    <div className='documents-list'>
      {
        documents.map((doc) => {
          return (
            <a className='documents-list__item' key={doc.id} href={doc.path} download={doc.download_title}>
              <span className='documents-list__item-icon'><DocIcon type={doc.format} ext={doc.extension} /></span>
              <span className='documents-list__item-right'>
                <span className='documents-list__item-title'>
                  {doc.title}
                </span>
                <span className='documents-list__item-size'>
                  {doc.file_size}Кб.
                </span>

              </span>

            </a>
          )
        })
      }
    </div>
  );
}

export default DocumentsList;
