import React from 'react';

import './style.scss';
import './mobile.scss';

import {connect} from 'react-redux';
import {compose} from 'redux';
import HeaderMobile from './Mobile';
import HeaderDesktop from './Desktop';

const Header = ({applicationState, ...props}) => {
  return (
    <div className='header'>
      <div className='container'>
        {
          applicationState.is_mobile ? <HeaderMobile /> : <HeaderDesktop />
        }
      </div>
    </div>
  );
};

const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(Header);

