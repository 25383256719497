import React from 'react';

const TrashIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.03535 6.69964C4.01647 6.31895 4.32004 5.99996 4.70119 5.99996L11.3001 5.99996C11.6813 5.99996 11.9848 6.31895 11.966 6.69964L11.6999 12.066C11.6647 12.7758 11.0789 13.3333 10.3682 13.3333L5.63314 13.3333C4.92243 13.3333 4.33664 12.7758 4.30145 12.066L4.03535 6.69964Z" fill="#1536A1"/>
      <path d="M10.4712 2.99996C10.4712 2.81586 10.322 2.66663 10.1379 2.66663L5.8634 2.66663C5.6793 2.66663 5.53006 2.81586 5.53006 2.99996V3.66663C5.53006 3.85072 5.38082 3.99996 5.19673 3.99996H3.66732C3.48322 3.99996 3.33398 4.1492 3.33398 4.33329V4.99996C3.33398 5.18405 3.48322 5.33329 3.66732 5.33329L12.334 5.33329C12.5181 5.33329 12.6673 5.18405 12.6673 4.99996V4.33329C12.6673 4.1492 12.5181 3.99996 12.334 3.99996L10.8046 3.99996C10.6205 3.99996 10.4712 3.85072 10.4712 3.66663V2.99996Z" fill="#1536A1"/>
    </svg>
  );
}

export default TrashIcon;
