import React from 'react';

const ChevronIcon = ({width = 16, height = 16, direction = 'down', color = '#000', opacity="1"}) => {
  const
    directions = {
      up: 'rotate(180deg)',
      down: 'rotate(0deg)',
      left: 'rotate(90deg)',
      right: 'rotate(270deg)'
    },
    style = {transform: directions[direction], opacity};
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.84656 11.4001C8.05775 11.629 8.41924 11.629 8.63043 11.4001L13.6916 5.91602C13.8801 5.71174 13.8801 5.3969 13.6916 5.19261L13.1446 4.59985C12.9334 4.37101 12.5719 4.37101 12.3607 4.59985L8.63043 8.64183C8.41924 8.87067 8.05775 8.87067 7.84656 8.64183L4.1163 4.59985C3.90511 4.37101 3.54362 4.37101 3.33243 4.59985L2.78538 5.19261C2.59685 5.3969 2.59685 5.71174 2.78538 5.91602L7.84656 11.4001Z" fill={color}/>
    </svg>
  );
};

export default ChevronIcon;
