import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import './style.scss';
import ImageBox from '../ImgBox';

const ChildrenRubricBricks = (props) => {
  const {currentRubric} = props.rubricsState;

  if (!currentRubric?.children?.length) {
    return null;
  }

  return (
    <ul className='children-rubrics-bricks'>
      {
        currentRubric.children.map((item) => (
          <li className='children-rubrics-bricks__item' key={item.id}>
            <Link to={constants.ROUTES.REGIONAL_RUBRIC_PATH(props.applicationState.current_city.slug, item.joined_path)}>
              <span className='children-rubrics-bricks__item-bg'/>
              <span className='children-rubrics-bricks__item-image'>
                {
                  item.image ? <ImageBox width={128} height={128} bg={false} url={item.image.small} alt={item.image.alt || item.title} /> : null
                }
              </span>
              <span className='children-rubrics-bricks__item-title'>{item.title}</span>
            </Link>
          </li>)
        )
      }
    </ul>
  );
};
const
  mapStateToProps = ({applicationState, rubricsState}) => {
    return {
      applicationState,
      rubricsState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(ChildrenRubricBricks);
