import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../../images/logo.svg';
import PreLogo from '../../../images/logo.png';
import Logo05 from '../../../images/logo_05.png';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import constants from '../../../constants';
import * as applicationActions from '../../../actions/applicationActionCreators';

const HeaderLogo = ({applicationState, ...props}) => {
  const [logo, setLogo] = useState(Logo05);

  useEffect(() => {
    if (logo === PreLogo || logo === Logo || applicationState.finalLogo === Logo) { return; }
    setTimeout(() => {
      setLogo(PreLogo);
    }, 1300);
  }, [logo, setLogo, applicationState.finalLogo]);

  useEffect(() => {
    if (logo === Logo || applicationState.finalLogo === Logo) { return; }
    setTimeout(() => {
      setLogo(Logo);
      props.applicationActions.setFinalLogo(Logo);
    }, 2400);
  }, [logo, setLogo, applicationState.finalLogo, props.applicationActions.setFinalLogo]);

  return (
    <Link className='header__logo' to={constants.ROUTES.REGIONAL_HOME_PATH(applicationState.current_city.slug)}>
      <img src={applicationState.finalLogo || logo} alt='Сатурн' />
    </Link>
  );
};

const mapDispatchToProps = (dispatch) => {
    return {
      applicationActions: bindActionCreators(applicationActions, dispatch),
    };
  },

  mapStateToProps = ({applicationState}) => {
      return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(HeaderLogo);

