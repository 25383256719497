import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Drawer, Input} from 'antd';
import './style.scss';
import CloseIcon from '../../../Icons/Close';
import Button from '../../../Button';
import TextArea from 'antd/es/input/TextArea';
import * as userRequests from '../../../../actions/userRequestsActionCreators';
const MobileQuestionDialog = (props) => {
  const
    [params, setParams] = useState({name: '', phone: '', comment: ''}),
    handleChange = (attr) => (e) => {
      setParams({...params, [attr]: e.target.value});
      props.userRequestsActions.changeQuestionForm({errors: null});
    },
    handleSubmit = () => {
      props.userRequestsActions.sendQuestionForm(params);
    },

    handleCancel = () => {
      props.onClose();
    };

  useEffect(() => {
    if (!props.userRequestsState.questionForm?.done) {
      return;
    }
    props.userRequestsActions.changeQuestionForm({done: false});
    setParams({name: '', phone: '', comment: ''});
    props.onClose();
  }, [
    setParams,
    props.onClose,
    props.userRequestsState.questionForm,
    props.userRequestsActions.changeQuestionForm
  ]);

  return (
    <>
      <Drawer
        className='select-drawer ant-drawer-bottom-radius'
        getContainer='body'
        height='auto'
        placement='bottom'
        onClose={props.onClose}
        title='Отправить вопрос'
        closeIcon={<CloseIcon />}
        footer={
          <div className='flex gap-12'>
            <Button primary className='md flex-1' onClick={handleSubmit}>Отправить</Button>
            <Button className='md clean' onClick={handleCancel}>Отменить</Button>
          </div>
        }
        open={props.open}
      >
        <div className='burger-menu-drawer__body'>
          <div className='user-form'>
            <div className='user-form__row'>
              <div className={`user-form__row-input ${props.userRequestsState.questionForm?.errors?.name ? 'error' : ''}`}>
                <Input value={params.name} onChange={handleChange('name')} placeholder='Ваше Имя' />
              </div>
            </div>
            <div className='user-form__row'>
              <div className={`user-form__row-input ${props.userRequestsState.questionForm?.errors?.phone ? 'error' : ''}`}>
                <Input value={params.phone} onChange={handleChange('phone')} placeholder='Контактный телефон' />
              </div>
            </div>
            <div className='user-form__row'>
              <div className={`user-form__row-input ${props.userRequestsState.questionForm?.errors?.comment ? 'error' : ''}`}>
                <TextArea value={params.comment} onChange={handleChange('comment')} placeholder='Подробно опишите суть вопроса' />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
    return {
      userRequestsActions: bindActionCreators(userRequests, dispatch),
    };
  },

  mapStateToProps = ({applicationState, userRequestsState}) => {
    return {
      applicationState,
      userRequestsState
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(MobileQuestionDialog);
