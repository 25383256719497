import React from 'react';
import ChildrenRubricList from '../../../components/MobileChildrenRubricsList';
import ChildrenRubricsBricks from '../../../components/ChildrenRubricsBricks';
import {bindActionCreators, compose} from 'redux';
import * as rubricsActions from '../../../actions/rubricsActionCreators';
import {connect} from 'react-redux';
import SeoTags from '../../../routes/SeoTags';
import {textKeysReplacement} from '../../../tools/helpers';

const RubricContent = ({rubric, ...props}) => {
  const {is_mobile} = props.applicationState;
  return (
    <>
      <SeoTags
        controller_action='products#index'
        seo_context={{
          title: textKeysReplacement(rubric.seo_title, props.applicationState.default_seo_context) || rubric.title,
          products_count: rubric.active_products_count || 0,
          description: textKeysReplacement(rubric.seo_description, props.applicationState.default_seo_context) || rubric?.description?.substring(0, 200),
          keywords: textKeysReplacement(rubric.seo_keywords, props.applicationState.default_seo_context),
        }}
      />

      {
        is_mobile ?
          <ChildrenRubricList rubrics={rubric.children} />
          : <ChildrenRubricsBricks rubrics={rubric.children} />
      }
    </>
  )
}

const
  mapDispatchToProps = (dispatch) => {
    return {
      rubricsActions: bindActionCreators(rubricsActions, dispatch)
    };
  },
  mapStateToProps = ({rubricsState, railsContext, applicationState}) => {
    return {
      applicationState,
      rubricsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(RubricContent);

