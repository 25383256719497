import React from 'react';
import './style.scss';
import {Link} from 'react-router-dom';
import constants from '../../../../constants';
import {connect} from 'react-redux';
import {compose} from 'redux';

import Brands from './Icons/brands.svg';
import Reviews from './Icons/reviews.svg';
import Calc from './Icons/calcs.svg';
import Addresses from './Icons/addresses.svg';
import Delivery from './Icons/delivery.svg';
import ChevronIcon from '../../../Icons/Chevron';

const MobileHeaderLinks = (props) => {
  const
    city = props.applicationState.current_city,
    citySlug = city.slug,

    links = [
      {label: 'Все бренды', path: constants.ROUTES.REGIONAL_BRANDS_PATH(citySlug), icon: Brands},
      {label: 'Отзывы', path: constants.ROUTES.REVIEWS_PATH(), icon: Reviews},
      {label: 'Калькуляторы', path: '/', icon: Calc},
      {label: 'Адреса', onClick: () => {}, icon: Addresses},
      {label: 'Доставка', path: constants.ROUTES.REGIONAL_PAYMENTS_PATH(citySlug), icon: Delivery},
    ];
  return (
    <ul className='burger-menu-options burger-menu-links'>
      {
        links.map((link, idx) => {
          return (
            <li key={idx} className='burger-menu-option'>
              <Link  to={link.path}>
                <img src={link.icon} alt={link.label}/>
                {link.label}
              </Link>
              <ChevronIcon direction='right' opacity='.2' />
            </li>
          );
        })
      }
    </ul>
  );
};
const mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(MobileHeaderLinks);
