import React from 'react';

const StarIcon = ({height= 16, width= 16}) => {
  return (
    <svg width={height} height={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.30623 4.79801L6.48105 4.48449C7.15657 3.27263 7.49439 2.66669 7.99934 2.66669C8.5043 2.66669 8.84212 3.27262 9.51764 4.48449L9.69246 4.79802C9.88436 5.14238 9.98036 5.31456 10.13 5.42818C10.2797 5.54179 10.4661 5.58396 10.8389 5.6683L11.1782 5.74509C12.4901 6.04191 13.146 6.19032 13.3021 6.69217C13.4581 7.19393 13.011 7.71681 12.1167 8.76268L11.8852 9.03318C11.6311 9.33036 11.5041 9.47894 11.4469 9.66283C11.3897 9.84662 11.4089 10.0449 11.4473 10.4414L11.4823 10.8024C11.6176 12.1977 11.6851 12.8954 11.2766 13.2054C10.868 13.5156 10.254 13.2329 9.02569 12.6673L8.70793 12.521C8.35881 12.3603 8.1843 12.2799 7.99934 12.2799C7.81438 12.2799 7.63988 12.3603 7.29076 12.521L6.973 12.6673C5.74477 13.2329 5.13064 13.5156 4.72209 13.2054C4.31355 12.8954 4.38115 12.1977 4.51636 10.8024L4.55134 10.4414C4.58976 10.0449 4.60897 9.84662 4.55181 9.66283C4.49464 9.47894 4.36757 9.33036 4.11344 9.03318L3.88207 8.76268C2.98774 7.71681 2.54059 7.19393 2.69664 6.69217C2.85269 6.19032 3.50862 6.0419 4.82044 5.74509L5.15982 5.6683C5.53261 5.58396 5.71899 5.54179 5.86868 5.42818C6.01833 5.31456 6.11433 5.14238 6.30623 4.79801Z" fill="url(#paint0_linear_2136_16289)"/>
      <defs>
        <linearGradient id="paint0_linear_2136_16289" x1="13.3327" y1="8.00002" x2="2.66602" y2="8.00002" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9900"/>
          <stop offset="1" stopColor="#FF4B55"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default StarIcon;
