import React from 'react';

const LocationLargeIcon = ({outlined = false, ...props}) => {
  return outlined ? (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2735_13384)">
          <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM30.4167 18.7748C30.4167 13.0081 25.8462 8.33333 20.2083 8.33333C14.5704 8.33333 10 13.0081 10 18.7748C10 22.3498 11.9049 25.2574 13.8789 27.3216C14.7159 28.1969 15.5932 28.9503 16.4013 29.5713C17.2093 30.1924 18.339 30.9401 19.0575 31.3553C19.776 31.7705 20.6406 31.7705 21.3592 31.3553C21.3592 31.3553 23.2073 30.1924 24.0154 29.5713C24.8235 28.9503 25.7008 28.1969 26.5378 27.3216C28.5118 25.2574 30.4167 22.3498 30.4167 18.7748ZM17.4861 18.7748C17.4861 17.237 18.7048 15.9904 20.2083 15.9904C21.7118 15.9904 22.9306 17.237 22.9306 18.7748C22.9306 20.3126 21.7118 21.5591 20.2083 21.5591C18.7048 21.5591 17.4861 20.3126 17.4861 18.7748Z" fill="url(#paint0_linear_2735_13384)"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_2735_13384" x1="40" y1="20" x2="1.49943e-08" y2="20" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9900"/>
            <stop offset="1" stopColor="#FF4B55"/>
          </linearGradient>
          <clipPath id="clip0_2735_13384">
            <rect width="40" height="40" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.0007 6.66675C26.4439 6.66675 31.6673 12.0094 31.6673 18.5998C31.6673 22.6855 29.4903 26.0085 27.2343 28.3676C26.2777 29.3679 25.2751 30.229 24.3516 30.9388C23.4281 31.6485 21.3159 32.9776 21.3159 32.9776C20.4947 33.452 19.5066 33.452 18.6854 32.9776C17.8643 32.5031 16.5732 31.6485 15.6497 30.9388C14.7262 30.229 13.7236 29.3679 12.767 28.3676C10.511 26.0085 8.33398 22.6855 8.33398 18.5998C8.33398 12.0094 13.5573 6.66675 20.0007 6.66675ZM20.0007 15.4177C18.2824 15.4177 16.8895 16.8424 16.8895 18.5998C16.8895 20.3573 18.2824 21.782 20.0007 21.782C21.7189 21.782 23.1118 20.3573 23.1118 18.5998C23.1118 16.8424 21.7189 15.4177 20.0007 15.4177Z" fill="url(#paint0_linear_2138_16448)"/>
        <defs>
          <linearGradient id="paint0_linear_2138_16448" x1="31.6673" y1="20.0001" x2="8.33398" y2="20.0001" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9900"/>
            <stop offset="1" stopColor="#FF4B55"/>
          </linearGradient>
        </defs>
      </svg>
  );
}

export default LocationLargeIcon;
