import routes from './routes';
import actions from './actions';
import api from './api';
import common from './common';
import formats from './formats';

const constants = {
  ROUTES: routes,
  ACTIONS: actions,
  API: api,
  COMMON: common,
  FORMATS: formats
};

export default constants;
