import React, {useState} from 'react';
import ChevronIcon from '../../Icons/Chevron';
import './style.scss';
import './mobile.scss';

const FooterBox = ({title, items, useToggle = false, className = '', ...props}) => {
  const
    [open, setOpen] = useState(false),
    handleToggle = () => {
      if (!useToggle) { return; }
      setOpen(!open);
    };

  return (
    <div className={`footer__box ${className}`}>
      <div className='footer__box-title' onClick={handleToggle}>
        {title}
        {
          useToggle ?
            <ChevronIcon direction='down' color='#fff' />
            :null
        }
      </div>
      {(useToggle && open) || !useToggle ? items.map((el, idx) => <div key={idx} className='footer__box-item'>{el}</div>) : null}
    </div>
  );
}

export default FooterBox;
