import ACTIONS from '../constants/actions';
import Offer from '../decorators/offer';

export const initialState = {
  redirect: null,
  currentRoute: null,
  cities: [],
  homeSlidesTop: [],
  homeRubrics: [],
  homeSlidesBottom: [],
  homeIconCards: [],
  homeText: null,
  homeOffers: [],
  homeBuildings: [],
  homeArticles: [],
  homeStocks: [],
  finalLogo: null,
};

const applicationReducer = function(state = initialState, action) {
  const actionHandlers = {
    [ACTIONS.SET_REDIRECT]() {
      return {
        ...state,
        redirect: action.payload
      };
    },

    [ACTIONS.SET_CURRENT_ROUTE]() {
      return {
        ...state,
        currentRoute: action.payload.route
      };
    },

    [ACTIONS.RESET_REDIRECT]() {
      return {
        ...state,
        redirect: null
      };
    },

    [ACTIONS.FETCH_CITIES_SUCCESS]() {
      return {
        ...state,
        cities: action.payload.cities
      };
    },
    [ACTIONS.SET_FINAL_LOGO]() {
      return {
        ...state,
        finalLogo: action.payload.logo
      };
    },
    [ACTIONS.FETCH_HOME_PAGE_CONTENT_SUCCESS]() {
      return {
        ...state,
        homeSlidesTop: action.payload.content.topSlides,
        homeSlidesBottom: action.payload.content.bottomSlides,
        homeIconCards: action.payload.content.content.icon_cards,
        homeText: action.payload.content.content.snippet,
        homeOffers: action.payload.content.bestSellers.map(p => (new Offer(p))),
        homeRubrics: action.payload.content.rubrics,
        homeArticles: action.payload.content.articles,
        homeStocks: action.payload.content.stocks,
      };
    },
  };

  if (action.type in actionHandlers) {
    return actionHandlers[action.type]();
  }

  return state;
};

export default applicationReducer;
