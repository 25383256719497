import {call, put, takeEvery, select} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as searchActions from '../actions/searchActionCreators';
import {extractPaginationParams} from '../tools/request';

const {API, ACTIONS} = constants;

export const fetchSearchSuggestionsRequest = (params) => RequestTools.ajax({url: API.SEARCH_SUGGESTIONS_PATH, params: params});

export function * fetchSearchSuggestions(action) {
  try {
    const response = yield call(fetchSearchSuggestionsRequest, {query: action.payload.query});

    yield put(searchActions.fetchSearchSuggestionsSuccess(response.data));
  } catch (e) {
    yield put(searchActions.fetchSearchSuggestionsFailed());
  }
}

function * searchSaga() {
  yield takeEvery(ACTIONS.FETCH_SEARCH_SUGGESTIONS, fetchSearchSuggestions);
}

export default searchSaga;
