import React from 'react';
import './style.scss';

const DocIcon = ({type, ...props}) => {
  const types = {
    pdf: {color: '#EB5757',text: 'pdf'},
    png: {color: '#db004b',text: 'png'},
    xlsx: {color: '#00AB00',text: 'xls'},
    xls: {color: '#00AB00',text: 'xls'},
    docx: {color: '#0060AB',text: 'doc'},
    doc: {color: '#0060AB',text: 'doc'},
    jpge: {color: '#007FD4',text: 'jpg'},
    jpg: {color: '#007FD4',text: 'jpg'},
    jpeg: {color: '#007FD4',text: 'jpg'},
    default: {color: '#007f85',text: props.ext}
},
  currentType = types[type] || types.default;

  return (
    <div className='doc-icon'>
      <div className='doc-icon__text'>{currentType.text}</div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 4.5C5 3.67157 5.67157 3 6.5 3H12H14L20 9V12V19.5C20 20.3284 19.3284 21 18.5 21H6.5C5.67157 21 5 20.3284 5 19.5V4.5Z" fill={currentType.color}/>
        <path d="M14 3L20 9H15.5C14.6716 9 14 8.32843 14 7.5V3Z" fill="white" fillOpacity="0.5"/>
      </svg>
    </div>
  );
}

export default DocIcon;
