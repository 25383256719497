import React from 'react';
import PhoneIcon from '../Icons/Location';
import './style.scss';

const BranchListItem = ({branch, ...props}) => {
  const phone = branch.phone_code ? `+7 (${branch.phone_code}) ${branch.phone_number}` : branch.phone_number;
  return (
    <div className='branch-list-item'>
      <div className='branch-list-item__left'>
        <div className='branch-list-item__title'>{branch.address}</div>
        <div className='branch-list-item__schedule'>{branch.schedule.map(item => [item.key, item.value].join(': ')).join('; ')}</div>
        <div className='branch-list-item__tags'>
          {
            branch.office ?
              <span>Офис</span>
              : null
          }
          {
            branch.depot ?
              <span>Склад</span>
              : null
          }
        </div>
      </div>
      <div className='branch-list-item__right'>
        <a href={`tel:${phone}`}>
          <PhoneIcon colored={true} />
        </a>
      </div>
    </div>
  );
}

export default BranchListItem;
