import React, {useState} from 'react';
import {Drawer, Dropdown, Input, Select} from 'antd';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import './style.scss';
import ChevronIcon from '../../../Icons/Chevron';
import CloseIcon from '../../../Icons/Close';
import SearchIcon from '../../../Icons/Search';
import * as applicationActions from '../../../../actions/applicationActionCreators';
import MobileSearchString from '../SearchString';

const MobileSelectCity = (props) => {
  const
    [query, setQuery] = useState(''),
    [open, setOpen] = useState(false),
      handleClose = () => {
        setOpen(false);
      },
    cities = query ? props.applicationState.cities.filter(city => city.name.toLowerCase().includes(query.toLowerCase())) : props.applicationState.cities,
    currentCity = props.selected || props.applicationState.current_city,
    handleSelectCity = (branchId) => () => {
      if (props.onSelect) {
        const branch = props.applicationState.branches.find(branch => branch.id === branchId);
        props.onSelect(branch, props.applicationState.cities.find(city => city.id === branch.city_id));
        setOpen(false);
      } else {
        props.applicationActions.setCurrentCity(branchId)
      }
    },

    handleInputChange = (e) => {
      setQuery(e.target.value);
    };

  return (
    <div className='select-city-mobile'>
      <button className='select-city-mobile__selector' onClick={() => setOpen(true)}>
        {currentCity.name}<ChevronIcon />
      </button>
      <Drawer
        className='select-drawer'
        getContainer='body'
        height='100%'
        placement='bottom'
        onClose={handleClose}
        title="Ваш регион"
        closeIcon={<CloseIcon />}
        open={open}
      >
        <MobileSearchString
          query={query}
          placeholder='Поиск по региону'
          onChange={handleInputChange}
          onClear={() => setQuery('')}
        />
        <div className='burger-menu-drawer__body'>
          <ul className='burger-menu-options select-city-mobile__list'>
            {
              cities.map((city) => (
                <li key={city.id} className={`burger-menu-option ${city.id === currentCity.id ? 'burger-menu-option__active' : ''}`} onClick={handleSelectCity(city.main_branch.id)}>
                  <span>{city.name}</span>
                  <span><ChevronIcon direction='right' opacity='.2'/></span>
                </li>
              ))
            }
          </ul>
        </div>
      </Drawer>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
    return {
      applicationActions: bindActionCreators(applicationActions, dispatch),
    };
  },

  mapStateToProps = ({applicationState, railsContext}) => {
    return {
      applicationState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(MobileSelectCity);
