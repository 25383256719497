import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link} from 'react-router-dom';
import constants from '../../constants';
import ClockIcon from '../Icons/Clock';

import './style.scss';
import './mobile.scss';
const StocksPreviewList = ({allUrl = null, stocks, ...props}) => {
  return (
    <ul className='stocks-list'>
      {
        stocks.map((stock) => {
          return (
            <li
              className='stocks-list__item'
              style={{backgroundImage: `url(${stock.icon?.big_image_url})`}}
              key={stock.id}
            >
              <Link to={constants.ROUTES.REGIONAL_STOCK_PATH(props.applicationState.current_city.slug, stock.id)}>
                <span className='stocks-list__item-content'>
                  <span className='stocks-list__item-title'>{stock.title}</span>
                  <span className='stocks-list__item-date'>
                    <ClockIcon />
                    До {stock.end_date_str}
                  </span>
                </span>
              </Link>
            </li>
          )
        })
      }
    </ul>
  );
};

export const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(StocksPreviewList);
