import React from 'react';

const ShareIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.03505 12.1787C8.84788 12.1787 8.74707 12.3992 8.86913 12.5416L10.6425 14.6106C10.8002 14.7946 10.7794 15.072 10.5961 15.2302L9.26884 16.3763C9.08558 16.5346 8.80922 16.5137 8.65156 16.3298L4.1059 11.0263C3.95653 10.852 3.96626 10.5916 4.12821 10.4291L9.40896 5.12868C9.5799 4.95711 9.85704 4.95711 10.028 5.12868L11.266 6.37134C11.437 6.54291 11.437 6.82109 11.266 6.99267L9.09916 9.1676C8.96129 9.30598 9.05894 9.5426 9.25392 9.5426L14.5939 9.5426C17.4815 9.5426 20 12.1527 20 15.2541V18.5607C20 18.8033 19.804 19 19.5623 19H17.8114C17.5697 19 17.3737 18.8033 17.3737 18.5607V15.2541C17.3737 13.5026 15.9277 12.1787 14.5939 12.1787L9.03505 12.1787Z" fill="#1536A1"/>
    </svg>
  );
}

export default ShareIcon;
