import React, {useRef, useState} from 'react';
import _ from 'lodash';
import './style.scss';

const PriceRangeFilter = (props) => {
  const
    [from, to] = props.value,
    [inputFocus, setInputFocus] = useState({from: false, to: false}),

    setOutValue = (val, inputName) => {
      return `${_.isNumber(val) ? val || 0 : 0}${inputFocus[inputName] ? '' : ' ₽'}`;
    },

    extractValue = (event) => {
      return Number(event.target.value);
    }
  return (
    <div className='price-range-filter'>
      <div className='price-range-filter__col'>
        <label className='price-range-filter__input-wrapper'>
          <span className='price-range-filter__input-label'>от</span>
          <input
            className='price-range-filter__input'
            onFocus={() => setInputFocus({...inputFocus, from: true})}
            onBlur={() => setInputFocus({...inputFocus, from: false})}
            onChange={(e) => props.onChange([extractValue(e), to])}
            id='js-price-filter-from'
            value={setOutValue(from, 'from')}
          />
        </label>
      </div>
      <div className='price-range-filter__col'>
        <label className='price-range-filter__input-wrapper'>
          <span className='price-range-filter__input-label'>до</span>
          <input
            className='price-range-filter__input'
            onFocus={() => setInputFocus({...inputFocus, to: true})}
            onBlur={() => setInputFocus({...inputFocus, to: false})}
            onChange={(e) => props.onChange([from, extractValue(e)])}
            value={setOutValue(to, 'to')}
          />
        </label>
      </div>
    </div>
  );
}

export default PriceRangeFilter;
