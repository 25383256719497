import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import constants from '../../constants';
import {Link} from 'react-router-dom';

import './style.scss'
import './mobile.scss'
const ArticlesJournal = ({articles, ...props}) => {
  return (
    <ul className='articles-journal__list'>
      {
        articles.map((article, idx) => {
          return (
            <li
              key={article.id}
              className={`articles-journal__list-item ${idx < 2 ? 'articles-journal__list-item_big' : ''}`}
              style={{backgroundImage: `url(${article.icon?.big_image_url})`}}
            >
              <Link to={constants.ROUTES.REGIONAL_ARTICLE_PATH(props.applicationState.current_city.slug, article.id)}>
                <span className='articles-journal__list-item-content'>
                  <span className='articles-journal__list-item-description'>{article.title}</span>
                  <span className='articles-journal__list-item-subtitle'>
                    {/*<DotIcon />*/}
                    {article.created_at_str}
                  </span>
                </span>
              </Link>
            </li>
          );
        })
      }
    </ul>
  );
}

export const
  mapStateToProps = ({applicationState}) => {
    return {
      applicationState
    };
  },

  withConnect = connect(
    mapStateToProps
  );

export default compose(withConnect)(ArticlesJournal);
