import React from 'react';

const DeliveryIcon = ({outlined = false, ...props}) => {
  return outlined ? (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2735_16362)">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM25.8333 12.2917C25.8333 11.026 24.7887 10 23.5 10H10.6667C9.378 10 8.33333 11.026 8.33333 12.2917V24.6207C8.33333 25.8864 9.378 26.9124 10.6667 26.9124C10.6667 28.8109 12.2337 30.3499 14.1667 30.3499C16.0997 30.3499 17.6667 28.8109 17.6667 26.9124H21.1667C21.1667 28.8109 22.7336 30.3499 24.6667 30.3499C26.5997 30.3499 28.1667 28.8109 28.1667 26.9124H29.3333C30.622 26.9124 31.6667 25.8864 31.6667 24.6207V18.7874C31.6667 18.2669 31.4863 17.7621 31.1553 17.3558L29.4279 15.2351C28.9852 14.6915 28.3147 14.375 27.606 14.375H25.8333V12.2917ZM13 26.9124C13 26.2795 13.5223 25.7665 14.1667 25.7665C14.811 25.7665 15.3333 26.2795 15.3333 26.9124C15.3333 27.5452 14.811 28.0582 14.1667 28.0582C13.5223 28.0582 13 27.5452 13 26.9124ZM23.5 26.9124C23.5 26.2795 24.0223 25.7665 24.6667 25.7665C25.311 25.7665 25.8333 26.2795 25.8333 26.9124C25.8333 27.5452 25.311 28.0582 24.6667 28.0582C24.0223 28.0582 23.5 27.5452 23.5 26.9124ZM25.8333 16.6667H27.606L29.3333 18.7874V22.329H25.8333V16.6667Z" fill="url(#paint0_linear_2735_16362)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_2735_16362" x1="40" y1="20" x2="1.49943e-08" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9900"/>
          <stop offset="1" stopColor="#FF4B55"/>
        </linearGradient>
        <clipPath id="clip0_2735_16362">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    ) : (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.9993 8.33338C25.4721 8.33338 26.666 9.50597 26.666 10.9524V13.3334H28.6919C29.5019 13.3334 30.2681 13.695 30.7741 14.3163L32.7483 16.74C33.1266 17.2043 33.3327 17.7813 33.3327 18.3761V25.0428C33.3327 26.4893 32.1388 27.6618 30.666 27.6618H29.3327C29.3327 29.8316 27.5419 31.5904 25.3327 31.5904C23.1235 31.5904 21.3327 29.8316 21.3327 27.6618L17.3327 27.6618C17.3327 29.8316 15.5418 31.5904 13.3327 31.5904C11.1235 31.5904 9.33268 29.8316 9.33268 27.6618C7.85992 27.6618 6.66602 26.4893 6.66602 25.0428L6.66602 10.9524C6.66602 9.50597 7.85992 8.33338 9.33268 8.33338L23.9993 8.33338ZM13.3327 26.3523C12.5963 26.3523 11.9993 26.9386 11.9993 27.6618C11.9993 28.3851 12.5963 28.9713 13.3327 28.9713C14.0691 28.9713 14.666 28.3851 14.666 27.6618C14.666 26.9386 14.0691 26.3523 13.3327 26.3523ZM25.3327 26.3523C24.5963 26.3523 23.9993 26.9386 23.9993 27.6618C23.9993 28.3851 24.5963 28.9713 25.3327 28.9713C26.0691 28.9713 26.666 28.3851 26.666 27.6618C26.666 26.9386 26.0691 26.3523 25.3327 26.3523ZM28.6919 15.9524H26.666L26.666 22.4237H30.666L30.666 18.3761L28.6919 15.9524Z" fill="url(#paint0_linear_2138_16458)"/>
        <defs>
          <linearGradient id="paint0_linear_2138_16458" x1="33.3327" y1="19.9619" x2="6.66602" y2="19.9619" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF9900"/>
            <stop offset="1" stopColor="#FF4B55"/>
          </linearGradient>
        </defs>
      </svg>
  );
}

export default DeliveryIcon;
