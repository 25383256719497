import React, {useState} from 'react';
import {Drawer, Input} from 'antd';
import CloseIcon from '../../../Icons/Close';
import Button from '../../../Button';
import InputWithLabel from '../../../InpuWithLabel';
import CustomCheckbox from '../../../Checkbox';
import InputPhone from '../../../InputPhone';
import InputEmail from '../../../InputEmail';

const MobileRecipientForm = ({open, onClose, onChange, recipient = {}}) => {
  const
    [localVal, setLocalVal]:any = useState(recipient),
    handleSubmit = () => {
      onChange(localVal);
      onClose();
    },
    handleChange = (attr, type = 'input') => (event) => {
      const newAddress = {
        ...localVal,
        [attr]: type === 'boolean' ? event.target.checked : event.target.value
      }

      setLocalVal(newAddress);
    },
    handleContactChanged = (attr) => (value) => {
      const newRecipient = {
        ...localVal,
        [attr]: value
      }

      setLocalVal(newRecipient);
    };
  return (
    <>
      <Drawer
        className='select-drawer ant-drawer-bottom-radius'
        getContainer='body'
        height='auto'
        placement='bottom'
        onClose={onClose}
        title='Введите данные'
        closeIcon={<CloseIcon />}
        footer={<Button onClick={handleSubmit} className='w-full lg'>Сохранить и продолжить</Button>}
        open={open}
      >
        <div className='burger-menu-drawer__body'>
          <div className='mobile-form mobile-form_address'>
            <div className='mobile-form__row'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <InputPhone value={localVal.phone} placeholder='Телефон' onChange={handleContactChanged('phone')} />
                </div>
              </div>
            </div>
            <div className='mobile-form__row'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <Input value={localVal.last_name} placeholder='Фамилия' onChange={handleChange('last_name')} />
                </div>
              </div>
            </div>
            <div className='mobile-form__row'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <Input value={localVal.first_name} placeholder='Имя' onChange={handleChange('first_name')} />
                </div>
              </div>
            </div>
            <div className='mobile-form__row'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <Input value={localVal.patronymic} placeholder='Отчество' onChange={handleChange('patronymic')} />
                </div>
              </div>
            </div>
            <div className='mobile-form__row'>
              <div className='mobile-form__field'>
                <div className='mobile-form__field-input'>
                  <Input type='email' value={localVal.email} placeholder='Почта' onChange={handleChange('email')} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>

  );
}

export default MobileRecipientForm;
