import React from 'react';
import './style.scss';

const ProductPlateItemSkeleton = (props) => {
  return (
    <div className='products-list-item products-list-item_skeleton'>
      <div className='products-list-item__top'>
        <div className='products-list-item__image'>
          <div className='products-list-item__image-bg' />
        </div>
        <div className='products-list-item__price'>
          <span className='products-list-item__price-number' />
        </div>

        <div className='products-list-item__title'>
          <div className='products-list-item__title-line' />
          <div className='products-list-item__title-line' />
        </div>
      </div>
      <div className='products-list-item__bottom'>
        <div className='products-list-item__reminder'>
          <span></span>
        </div>
        <div className='products-list-item__actions'>
        </div>
      </div>
    </div>
  );
}

export default ProductPlateItemSkeleton;
