import {call, put, takeEvery, select, delay} from 'redux-saga/effects';

import constants from '../constants';
import * as RequestTools from '../tools/request';
import * as rubricsActions from '../actions/rubricsActionCreators';

const {API, ACTIONS} = constants;

export const fetchRubricsTreeRequest = () => RequestTools.ajax({url: API.RUBRICS_TREE_PATH});
export const fetchRootRubricsRequest = () => RequestTools.ajax({url: API.ROOT_RUBRICS_PATH});
export const fetchRubricRequest = (path) => RequestTools.ajax({url: API.RUBRICS_PATH(path)});
export const fetchRubricBrandsRequest = (params) => RequestTools.ajax({url: API.BRANDS_FOR_RUBRIC_PATH, params});
export const fetchRubricBestOffersRequest = (params) => RequestTools.ajax({url: API.RUBRIC_BEST_OFFERS_PATH, params});
export const fetchRubricStockOffersRequest = (params) => RequestTools.ajax({url: API.RUBRIC_STOCK_OFFERS_PATH, params});

export function * fetchRubricsTree() {
  yield delay(1000)
  try {
    const response = yield call(fetchRubricsTreeRequest);

    yield put(rubricsActions.fetchRubricsTreeSuccess(response.data));
  } catch (e) {
    yield put(rubricsActions.fetchRubricsTreeFailed());
  }
}

export function * fetchRootRubrics() {
  try {
    const response = yield call(fetchRootRubricsRequest);

    yield put(rubricsActions.fetchRootRubricsSuccess(response.data));
  } catch (e) {
    yield put(rubricsActions.fetchRootRubricsFailed());
  }
}

export function * fetchRubric(action) {
  try {
    const
      {rubricsState} = yield select(),
      cachedRubric = rubricsState.currentRubricsCache[action.payload.path];
    
    const
      response = cachedRubric ? {data: cachedRubric} : yield call(fetchRubricRequest, action.payload.path);

    yield put(rubricsActions.fetchRubricSuccess(response.data));
  
    yield put(rubricsActions.fetchRubricBrands());
    yield put(rubricsActions.fetchRubricBestOffers());
    yield put(rubricsActions.fetchRubricStocks());
  } catch (e) {
    yield put(rubricsActions.fetchRubricFailed());
  }
}

export function * fetchBrands(action) {
  try {
    const
      {rubricsState} = yield select();
    
    const
      response = yield call(fetchRubricBrandsRequest, {rubric_id: rubricsState.currentRubric.id});

    yield put(rubricsActions.fetchRubricBrandsSuccess(response.data));
  } catch (e) {
    yield put(rubricsActions.fetchRubricBrandsFailed());
  }
}
export function * fetchBestOffers(action) {
  try {
    const
      {rubricsState} = yield select();
    
    const
      response = yield call(fetchRubricBestOffersRequest, {rubric_id: rubricsState.currentRubric.id});

    yield put(rubricsActions.fetchRubricBestOffersSuccess(response.data));
  } catch (e) {
    yield put(rubricsActions.fetchRubricBestOffersFailed());
  }
}

export function * fetchStockOffers(action) {
  try {
    const
      {rubricsState} = yield select();
    
    const
      response = yield call(fetchRubricStockOffersRequest, {rubric_id: rubricsState.currentRubric.id});

    yield put(rubricsActions.fetchRubricStocksSuccess(response.data));
  } catch (e) {
    yield put(rubricsActions.fetchRubricStocksFailed());
  }
}

function * rubricsSaga() {
  yield takeEvery(ACTIONS.FETCH_RUBRICS_TREE, fetchRubricsTree);
  yield takeEvery(ACTIONS.FETCH_ROOT_RUBRICS, fetchRootRubrics);
  yield takeEvery(ACTIONS.FETCH_RUBRIC, fetchRubric);
  yield takeEvery(ACTIONS.FETCH_RUBRIC_BRANDS, fetchBrands);
  yield takeEvery(ACTIONS.FETCH_RUBRIC_BEST_OFFERS, fetchBestOffers);
  yield takeEvery(ACTIONS.FETCH_RUBRIC_STOCKS, fetchStockOffers);
}

export default rubricsSaga;
