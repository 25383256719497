import React, {useEffect, useState} from 'react';
import {bindActionCreators, compose} from 'redux';
import * as productsActions from '../../actions/productsActionCreators';
import {connect} from 'react-redux';
import CheckboxFilter from './CheckboxFilter';
import './style.scss';
import {Slider, Input, InputNumber, Checkbox} from 'antd';
import {toggleInArray} from '../../../app/tools/helpers';
import CheckboxToggle from './CheckboxToggle';
import PriceRangeFilter from './PriceRange';
import CustomSwitch from '../Switch';
import CustomCheckbox from '../Checkbox';

let priceChangeTimeout = null;
const ProductsFilters = ({productsState, productsActions, ...props}) => {
  const
    [initialPriceRange, setInitialPriceRange] = useState([]),
    [priceRange, setPriceRange]: any = useState([0, 0]),
    {filters, filtersContext} = productsState,
    {setProductsFilter, resetProductsFilter} = productsActions,
    handlePriceChanged = (v) => {
      const result = [...v];
      setPriceRange(v);
      clearTimeout(priceChangeTimeout);

      priceChangeTimeout = setTimeout(() => {
        setProductsFilter('price', result);
      }, 600)
    },
    handleCheckboxChanged = (filterName) => (filterValue) => {
      if (filterName === 'reminder') {
        filterValue = filterValue ? 1 : null;
      }

      setProductsFilter(filterName, typeof filterValue === 'object' && !filterValue?.length ? null : filterValue);
    },
    handleTraitFilterChanged = (id) => (filterValues) => {
      const traitFilters = {...(filters.traits || {})};

      if (filterValues.length) {
        traitFilters[id] = filterValues;
      } else {
        delete traitFilters[id]
      }

      setProductsFilter('traits', !Object.keys(traitFilters).length ? null : traitFilters);
    };

  useEffect(() => {
    const
      minPrice = Number(filtersContext.min_cost),
      maxPrice = Number(filtersContext.max_cost);
    if (initialPriceRange[0] === minPrice && initialPriceRange[1] === maxPrice) { return; }

    setInitialPriceRange([minPrice, maxPrice]);
    setPriceRange([minPrice, maxPrice]);
  },
    [filtersContext.min_cost, filtersContext.max_cost, setPriceRange, setInitialPriceRange]);

  return (
    <div className='products-filters__wrapper'>
      <div className='products-filter'>
        <div className='products-filter__title'>Цена</div>
        <div className='products-filter__values'>
          <div className='products-filter__values-row'>
            <PriceRangeFilter
              onChange={handlePriceChanged}
              value={priceRange}
              min={initialPriceRange[0]}
              max={initialPriceRange[1]}
            />
          </div>
        </div>
        <div className='products-filter__values'>
          <div className='products-filter__values-row'>
            <CustomCheckbox
              checked={filters.without_price}
              onChange={() => handleCheckboxChanged('without_price')(!filters.without_price)}
            >
              Без цен
            </CustomCheckbox>
          </div>
          {/*<div className='products-range-filter'>*/}
          {/*  <div className='products-range-filter__top'>*/}
          {/*    <Slider*/}
          {/*      range*/}
          {/*      tooltip={{open: false}}*/}
          {/*      onChange={handlePriceChanged}*/}
          {/*      min={initialPriceRange[0]}*/}
          {/*      max={initialPriceRange[1]}*/}
          {/*      value={priceRange}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className='products-range-filter__bottom'>*/}
          {/*    <InputNumber*/}
          {/*      value={priceRange[0]}*/}
          {/*      onChange={(e) => handlePriceChanged([e, priceRange[1]])}*/}
          {/*    />*/}
          {/*    <InputNumber*/}
          {/*      value={priceRange[1]}*/}
          {/*      onChange={(e) => handlePriceChanged([priceRange[0], e])}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <Checkbox*/}
          {/*    checked={filters.without_price}*/}
          {/*    onChange={() => handleCheckboxChanged('without_price')(!filters.without_price)}*/}
          {/*  >*/}
          {/*    Без цен*/}
          {/*  </Checkbox>*/}
          {/*</div>*/}
        </div>
      </div>

      {/*<CheckboxToggle*/}
      {/*  title='Наличие'*/}
      {/*  filter='reminder'*/}
      {/*  onTitle='В наличии'*/}
      {/*  offTitle='Под заказ'*/}
      {/*  onChange={handleCheckboxChanged('reminder')}*/}
      {/*  value={filters.reminder}*/}
      {/*/>*/}
      {
        filtersContext.purposes?.length ?
          <CheckboxFilter
            title='Назначение'
            items={filtersContext.purposes}
            filterValues={filters.purposes || []}
            onChange={handleCheckboxChanged('purposes')}
          />
        : null
      }
      {
        filtersContext.scope_filters?.length ?
          <CheckboxFilter
            title='Статус'
            items={filtersContext.scope_filters}
            filterValues={filters.scope_filters || []}
            onChange={handleCheckboxChanged('scope_filters')}
          />
        : null
      }
      {
        filtersContext.brands?.length ?
          <CheckboxFilter
            title='Бренды'
            items={filtersContext.brands}
            filterValues={filters.brands || []}
            onChange={handleCheckboxChanged('brands')}
          />
        : null
      }
      <div className='products-filter'>
        <div className='products-filter__title'>Наличие</div>
        <div className='products-filter__values'>
          <label className='products-filter__values-row'>
              <CustomSwitch onClick={handleCheckboxChanged('reminder')} checked={filters.reminder}></CustomSwitch>
              <span>Только товары в наличии</span>
          </label>
        </div>
      </div>
      {
        filtersContext.traits?.length ?
          filtersContext.traits.map(trait => {
            return trait.type === 'input' ? <div>!! Сделать фитьры с вводом !!</div> : (
              <CheckboxFilter
                key={trait.id}
                title={trait.title}
                items={trait.values}
                filterValues={filters.traits ? filters.traits[trait.id] : []}
                onChange={handleTraitFilterChanged(trait.id)}
              />
            );
          })
        : null
      }
    </div>
  )
};

const mapDispatchToProps = (dispatch) => {
    return {
      productsActions: bindActionCreators(productsActions, dispatch)
    };
  },

  mapStateToProps = ({productsState, railsContext, applicationState}) => {
    return {
      applicationState,
      productsState,
      railsContext
    };
  },

  withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
  );

export default compose(withConnect)(ProductsFilters);
