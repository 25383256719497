import React from 'react';
import HeartIcon from '../../Icons/Heart';

import './style.scss';

const FavouritesWidget = (props) => {
  return (
    <button className='favourite-button' {...props}>
      <HeartIcon />
      <span className='favourite-button__title'>В избранное</span>
    </button>
  );
}

export default FavouritesWidget;
