import {all} from 'redux-saga/effects';
import application from './application';
import rubricsSaga from './rubrics';
import productsSaga from './products';
import cartsSaga from './cart';
import brandsSaga from './brands';
import citiesSaga from './cities';
import searchSaga from './search';
import userRequestsSaga from './userRequests';
import articlesSaga from './articles';
export default function * rootSaga() {
  yield all([
    application(),
    rubricsSaga(),
    productsSaga(),
    cartsSaga(),
    brandsSaga(),
    citiesSaga(),
    searchSaga(),
    userRequestsSaga(),
    articlesSaga()
  ]);
}
